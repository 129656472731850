import { Route } from 'vue-router'

export const getRouteQueryString = (route: Route): string => {
  const queries = route.query ?? {}
  return Object.entries(queries).map(([key, value]) => {
    return `${key}=${value}`
  }).join('&')
}

export const createRouteQueryString = (
  route: Route,
  params?: Record<string, string>
): string => {
  const queries = { ...route.query, ...params } ?? { ...params }
  return Object.entries(queries).map(([key, value]) => {
    return `${key}=${value}`
  }).join('&')
}
