
import { defineComponent, reactive, toRefs, watch, computed } from '@vue/composition-api'
import { Editor } from '@tiptap/vue-2'

const HEADING_SIZES = [
  '32px',
  '24px',
  '18.72px',
  '16px',
  '13.28px',
  '12px'
]

export default defineComponent({
  props: {
    editor: { type: Object as () => Editor, required: true }
  },
  setup (props, { emit }) {
    const state: {
      selectedHeading: number
      selectedFontStyle: string
    } = reactive({
      selectedHeading: 0,
      selectedFontStyle: computed(() => {
        let headingLevel = 0
        for (let i = 1; i < 6; i++) {
          if (props.editor.isActive('heading', { level: i })) {
            headingLevel = i
            break
          }
        }
        return (headingLevel) ? `Heading ${headingLevel}` : 'Normal'
      })
    })

    watch(
      () => state.selectedHeading,
      () => {
        emit('selectHeading', state.selectedHeading)
      }
    )

    return { ...toRefs(state), HEADING_SIZES }
  }

})
