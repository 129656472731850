import { SavedPdfTextProps } from '@/store'

type CreatePdfTextCopyHandlerArgs = {
  getCopiedPdfText: () => SavedPdfTextProps,
  onCopy?: (copiedText: string) => void
}

export const createPdfTextCopyHandler = ({
  getCopiedPdfText,
  onCopy
}: CreatePdfTextCopyHandlerArgs): (e: KeyboardEvent) => void => {
  let ctrlDown = false
  const ctrlKey = 17
  const cmdKeyRight = 91
  const cmdKeyLeft = 93
  const vKey = 86

  return (keyboardEvent: KeyboardEvent) => {
    if (keyboardEvent.ctrlKey || keyboardEvent.keyCode === ctrlKey ||
      keyboardEvent.keyCode === cmdKeyLeft || keyboardEvent.keyCode === cmdKeyRight) {
      ctrlDown = true
    }

    if (ctrlDown && keyboardEvent.keyCode === vKey) {
      const savedPdfTextProps = getCopiedPdfText()
      if (savedPdfTextProps === null) return
      keyboardEvent.preventDefault()
      let copiedText = savedPdfTextProps.text.slice()
      const reconstructedText = []
      const { text, textNodes } = savedPdfTextProps

      if (!textNodes) {
        onCopy?.(copiedText)
        return
      }
      try {
        const nodeText = textNodes[0].innerText
        const words = nodeText.split(' ')
        for (let wi = 0; wi < words.length; wi++) {
          const phrase = words.slice(wi).join(' ')
          if (phrase && text.includes(phrase)) {
            reconstructedText.push(phrase)
            break
          }
        }
        if (textNodes.length > 1) {
          for (let line = 1; line < textNodes.length - 1; line++) {
            reconstructedText.push(textNodes[line].innerText)
          }
          const lastTextNode = textNodes[textNodes.length - 1]
          const nodeText = lastTextNode.innerText
          const words = nodeText.split(' ')
          for (let wi = 0; wi <= words.length; wi++) {
            const invPhrase = words.slice(0, words.length - wi + 1).join(' ')
            if (invPhrase && text.includes(invPhrase.replace(/\W$/, ''))) {
              reconstructedText.push(invPhrase)
              break
            }
          }
        }

        copiedText = copiedText.replace(reconstructedText.join(''), ' ')
        copiedText = copiedText.replace(/\s\s+/g, ' ')
        const [head, tail] = copiedText.split(' ')

        copiedText = reconstructedText.join(' ').replace(/- /g, '')
        if (head) {
          copiedText = `${head} ${copiedText}`
        }
        if (tail) {
          copiedText = `${copiedText} ${tail}`
        }
      } catch (e) {
        console.warn('Failed to reconstruct the correct text from PDF:', e)
        copiedText = savedPdfTextProps.text
      }

      onCopy?.(copiedText)
    }
  }
}
