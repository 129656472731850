<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PdfImporter from '@/components/PdfImporter'
import PaperInfoEditor from '@/components/PaperInfoEditor'
// import Editor from '@/components/Editor'
import { bibTexToJson } from '@/utils/bibtex'
import { PapersetDB } from '@/Firebase'
import { httpsCallable } from 'firebase/functions'

export default {
  name: 'PaperImport',
  components: {
    PdfImporter,
    PaperInfoEditor
    // Editor
  },
  props: {
    paperset: { type: Object, required: true }
  },
  data () {
    return {
      mode: 0,
      validUrl: true,
      fetchRemotePdfFile: null,
      newPaper: {
        title: null,
        authors: null,
        venue: null,
        keywords: []
      },
      pdfUrl: '',
      urlRules: [
        v => !!v || 'Require valid URL',
        v => /^http(s)*:\/\/.+/.test(v) || 'URL must start with http or https'
      ],
      isFetching: false,
      isLoadingFiles: false,
      showFetchErrorMsg: false,
      uploadErrorMsg: null
    }
  },
  created () {
    this.fetchRemotePdfFile = httpsCallable('downloadPdfFile')
  },
  async mounted () {
    this.$refs.dropZone.addEventListener('dragover', e => {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      this.$refs.dropZone.style.backgroundColor = '#ccc'
    })

    this.$refs.dropZone.addEventListener('dragleave', e => {
      e.stopPropagation()
      e.preventDefault()
      this.$refs.dropZone.style.backgroundColor = '#fff'
    })

    this.$refs.dropZone.addEventListener('drop', e => {
      e.stopPropagation()
      e.preventDefault()
      this.uploadErrorMsg = false
      this.isLoadingFiles = true
      this.$refs.PdfImporter.loadFiles(e.dataTransfer.files)
      this.$refs.dropZone.style.backgroundColor = '#fff'
    })
  },
  methods: {
    selectFiles () {
      this.uploadErrorMsg = false
      this.$refs.PdfImporter.selectFiles()
    },
    addPaper (paper, uploadTask) {
      console.log('adding paper', uploadTask)
      this.isLoadingFiles = false
      this.$emit('close')
      if (uploadTask) {
        paper.uploadTask = {
          status: 'uploading',
          progress: 0
        }
        uploadTask.on('state_changed', snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          paper.uploadTask.progress = progress.toFixed(0)
        })

        uploadTask.then(() => {
          paper.uploadTask.status = 'uploaded'
        })

        uploadTask.catch(error => {
          paper.uploadTask.status = 'error'
          paper.uploadTask.detail = error.toString()
        })
      }
      this.$emit('addPaper', paper)
      this.pdfUrl = ''
    },
    manuallyAddPaper (paper) {
      this.$emit('close')
      paper.hasPDF = false
      PapersetDB.addPaper(this.paperset.id, paper).then(() => {
        this.$emit('addPaper', paper)
      })
    },
    async parseBibTex () {
      this.$emit('close')
      const text = this.$refs.BibTexEditor.editor.getValue()
      const papers = bibTexToJson(text)
      if (Array.isArray(papers)) {
        papers.forEach(p => { p.hasPDF = false })
      }
      await PapersetDB.Papers.bulkAdd(this.paperset.id, papers)
      this.$emit('addPaper', papers)
      this.$refs.BibTexEditor.editor.setValue('')
    },
    async getPdfFileFromUrl () {
      if (this.$refs.urlForm.validate()) {
        this.isFetching = true
        try {
          await this.$refs.PdfImporter.processPdfFile(this.pdfUrl)
        } catch (error) {
          try {
            console.log('fetching remote URL')
            const pdfFile = await this.fetchRemotePdfFile({ url: this.pdfUrl })
            if (pdfFile.data.error) {
              this.showFetchErrorMsg = true
              this.handleUploadError('Failed to fetch PDF file. Please upload PDF file manually')
            }
            this.$refs.PdfImporter.processPdfFile(pdfFile.data, true)
          } catch (error) {
            console.log(error)
            this.showFetchErrorMsg = true
          }
        }
        this.isFetching = false
      }
    },
    handleUploadError (errorMsg) {
      this.isLoadingFiles = false
      this.uploadErrorMsg = errorMsg
    }
  }
}
</script>

<template>
  <v-card
    flat
    align="top"
    class="text-center"
  >
    <v-toolbar
      flat
      class="ma-0"
    >
      <v-toolbar-title>Add / Import Papers</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <PdfImporter
      ref="PdfImporter"
      :paperset="paperset"
      @done="addPaper"
      @load="isLoadingFiles=true"
      @error="handleUploadError"
    />
    <v-tabs
      v-model="mode"
      background-color="#fafafa"
      centered
    >
      <v-tab class="mx-2">
        <v-icon class="mr-2">
          mdi-file-pdf
        </v-icon>PDF Files
      </v-tab>
      <!-- <v-tab class="mx-2"><v-icon class="mr-2">mdi-link</v-icon>From URL</v-tab> -->
      <!-- <v-tab class="mx-2"><v-icon class="mr-2">mdi-code-braces</v-icon> BibTex</v-tab> -->
      <v-tab class="mx-2">
        <v-icon class="mr-2">
          mdi-playlist-edit
        </v-icon> Manual Input
      </v-tab>
    </v-tabs>
    <v-card-text
      :class="{hidden: mode!=0}"
      class="pa-5 mt-8"
      style="min-height: 500px;"
    >
      <v-alert
        v-if="uploadErrorMsg"
        type="error"
        class="my-5"
      >
        {{ uploadErrorMsg }}
      </v-alert>
      <div
        v-if="isLoadingFiles"
        class="px-10"
      >
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="10"
        />
        <h5>Loading Files</h5>
      </div>
      <div v-show="!isLoadingFiles">
        <v-btn
          color="primary"
          @click="selectFiles"
        >
          <v-icon>mdi-file-upload</v-icon> Select files from computer
        </v-btn>
        <h3 class="my-5">
          OR
        </h3>
        <div
          ref="dropZone"
          class="dropZone"
        >
          <h3 style="color: #888">
            Drag PDF files here
          </h3>
        </div>
        <h3 class="my-5">
          OR
        </h3>
        <v-form
          ref="urlForm"
          v-model="validUrl"
          lazy-validation
        >
          <v-row
            class="px-6"
            justify="center"
          >
            <v-alert
              v-if="showFetchErrorMsg"
              type="error"
              class="my-5"
            >
              Failed to get PDF file from the provided URL. Please upload the PDF file manually.
              <br>
              URL: {{ pdfUrl }}
            </v-alert>
            <v-col
              cols="10"
              class="pa-0"
            >
              <v-text-field
                v-model="pdfUrl"
                label="URL"
                outlined
                dense
                required
                :rules="urlRules"
                @keyup.enter="getPdfFileFromUrl"
              />
            </v-col>
            <v-col
              cols="6"
              class="pa-0"
            >
              <div v-if="isFetching || isLoadingFiles">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="10"
                />
                <h5>Fetching PDF file</h5>
              </div>
              <v-btn
                v-else
                color="primary"
                @click="getPdfFileFromUrl"
              >
                <v-icon class="mx-1">
                  mdi-link
                </v-icon>
                Fetch PDF file
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
    <!-- <v-card-text v-if="mode==1" class="pa-5">
      <Editor
        ref="BibTexEditor"
        style="border: 1px solid #aaa;"
      />
      <v-btn color="primary" class="ma-3" @click="parseBibTex">
        Start Import
      </v-btn>
    </v-card-text> -->
    <v-card-text :class="{hidden: mode!=1}">
      <PaperInfoEditor
        class="ma-2"
        :paper-to-edit="newPaper"
        :on-edit-done="manuallyAddPaper"
        :outlined="true"
        :button-name="'Add Paper'"
      />
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-tab {
  text-transform: none;
}

.dropZone {
  margin: auto;
  min-height: 180px;
  max-width: 280px;
  border: 3px dashed #AAA;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
