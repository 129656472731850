import type { DocumentData, FieldValue, Firestore } from 'firebase/firestore'
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  deleteField,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore'

import { PapersetId, PapersetNote, PapersetNoteId } from '@/models'
import { getUser } from './auth'

export const PapersetNoteDB = {
  get firestore (): Firestore { return getFirestore() },

  async add (papersetNote: Partial<PapersetNote>): Promise<PapersetNoteId | null> {
    const user = getUser()
    if (!user || !papersetNote.papersetId) return null
    const createdTime = serverTimestamp()
    const newNoteDoc = await addDoc(
      collection(this.firestore, 'Papersets', papersetNote.papersetId, 'Notes'),
      {
        ...papersetNote,
        author: user,
        createdTime,
        updatedTime: createdTime
      }
    )

    return newNoteDoc.id
  },

  async update (
    papersetId: PapersetId,
    noteId: PapersetNoteId,
    props: Record<string, Partial<unknown>>
  ): Promise<void> {
    return updateDoc(
      doc(this.firestore, 'Papersets', papersetId, 'Notes', noteId),
      props
    )
  },

  async get (
    papersetId: PapersetId,
    noteId: PapersetNoteId
  ): Promise<PapersetNote | undefined> {
    const user = getUser()
    if (user) {
      const noteDoc = await getDoc(
        doc(this.firestore, 'Papersets', papersetId, 'Notes', noteId)
      )

      if (noteDoc.exists()) {
        const userNote = noteDoc.data() as PapersetNote
        userNote.id = noteDoc.id
        return userNote
      }
    }
    return undefined
  },

  async delete (
    papersetId: PapersetId,
    noteId: PapersetNoteId
  ): Promise<void> {
    const user = getUser()
    if (user) {
      return deleteDoc(
        doc(this.firestore, 'Papersets', papersetId, 'Notes', noteId)
      )
    }
  }
}

export const addPapersetNote = async (
  note: Partial<PapersetNote>
): Promise<Partial<PapersetNote>|null> => {
  const user = getUser()
  if (user === null) return null
  const noteId = await PapersetNoteDB.add(note)

  const props: Record<string, Partial<unknown>> = {}
  if (noteId) {
    props[`notes.${noteId}`] = {
      id: noteId,
      title: note.title,
      author: user,
      updatedTime: serverTimestamp()
    }

    if (note.papersetId) {
      updateDoc(
        doc(getFirestore(), `Papersets/${note.papersetId}`),
        props
      )
    }
  }
  return { ...props[`notes.${noteId}`] }
}

export const updatePapersetNote = async ({
  papersetId,
  noteId,
  noteProps
}: {
  papersetId: PapersetId,
  noteId: PapersetNoteId,
  noteProps: Partial<PapersetNote>
}): Promise<void> => {
  const user = getUser()
  const props: Record<string, Partial<unknown>> = {
    [`notes.${noteId}.updatedTime`]: serverTimestamp()
  }

  if (noteId !== undefined && user !== null) {
    for (const [key, value] of Object.entries(noteProps)) {
      if (value) {
        props[`notes.${noteId}.${key}`] = value
      }
    }

    PapersetNoteDB.update(
      papersetId,
      noteId,
      noteProps as Record<string, Partial<unknown>>
    )

    updateDoc(
      doc(getFirestore(), `Papersets/${papersetId}`),
      props
    )
  }
}

export const deletePapersetNote = async (
  papersetId: PapersetId,
  noteId: PapersetNoteId
): Promise<void> => {
  const user = getUser()
  const props: Record<string, FieldValue> = {}
  if (noteId !== undefined && user !== null) {
    props[`notes.${noteId}`] = deleteField()
    PapersetNoteDB.delete(papersetId, noteId)

    updateDoc(
      doc(getFirestore(), `Papersets/${papersetId}`),
      props
    )
  }
}

export const watchNoteContent = ({
  papersetId,
  noteId,
  onChanged
}:{
  papersetId: PapersetId,
  noteId: PapersetNoteId
  onChanged: ({ title, content }: {title: string, content: string}) => void
}): ReturnType<typeof onSnapshot> => {
  return onSnapshot(doc(getFirestore(), 'Papersets', papersetId, 'Notes', noteId), (doc) => {
    if (!doc.metadata.hasPendingWrites) {
      const note = doc.data() as PapersetNote
      if (note) {
        const { title, content } = note
        onChanged({ title, content })
      }
    }
  })
}
