
import { defineComponent, reactive, toRefs, computed } from '@vue/composition-api'
import { ResourceCategories } from '@/assets/ResourceCategories'
import { PaperResource } from '@/models'

export default defineComponent({
  name: 'PaperResources',
  props: {
    resources: {
      type: Array as () => PaperResource[],
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      newResource: {
        url: null,
        category: 'video'
      },
      resourceLabel: null,
      resourceUrl: null,
      resourceLinks: computed(() => {
        return props.resources.slice()
      }),
      resourceCategories: computed(() => {
        return Object.keys(ResourceCategories)
          .filter(r => props.resources.map(r => r.category).indexOf(r) === -1)
      })
    })

    const methods = {
      addResource () {
        if (state.newResource.url !== null) {
          emit('change', state.newResource)
          state.newResource.url = null
        }
      },
      removeResource (resource: {category: string; url: string | null}) {
        resource.url = null
        emit('change', resource)
      }
    }

    return { ...toRefs(state), ResourceCategories, ...methods }
  }
})
