
import { computed, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import { PapersetDB } from '@/Firebase'
import { Paperset, Collaborator, UserInfo, Permission, PapersetId } from '@/models'

type State = {
  valid: boolean
  email: string
  permOptions: Permission[]
  permission: Permission
  ownerEmail: string
  collaborators: (Partial<UserInfo> & Collaborator)[]
  papersetId: PapersetId
}

export default defineComponent({
  name: 'Collaborators',
  props: {
    paperset: { type: Object as () => Paperset, required: true },
    canEdit: Boolean
  },

  setup (props, { refs }) {
    const getCollaborators = () => {
      const paperset = props.paperset
      let owner: Partial<UserInfo> | undefined
      if (paperset.peopleInfo !== undefined) {
        owner = paperset.peopleInfo.find(c => c.email === paperset.owner)
      }
      const editors = paperset.editors || []
      const readers = paperset.readers || []
      const collaborators = editors.map(editorEmail => {
        const editor = { email: editorEmail, permission: Permission.CanEdit }
        const editorInfo = paperset.peopleInfo?.find(c => c.email === editorEmail)
        if (editorInfo !== undefined) {
          Object.assign(editor, editorInfo)
        }
        return editor
      }).concat(readers.map(readerEmail => {
        const reader = { email: readerEmail, permission: Permission.ViewOnly }
        const readerInfo = paperset.peopleInfo?.find(c => c.email === readerEmail)
        if (readerInfo !== undefined) {
          Object.assign(reader, readerInfo)
        }
        return reader
      }))
      if (owner !== undefined) {
        return [
            Object.assign({
              permission: Permission.CanEdit,
              email: owner.email
            }, owner) as Collaborator & Partial<UserInfo>
        ].concat(collaborators)
      }

      return collaborators
    }

    const state: State = reactive({
      valid: false,
      email: '',
      permOptions: [Permission.CanEdit, Permission.ViewOnly],
      permission: Permission.CanEdit,
      collaborators: getCollaborators(),
      ownerEmail: computed(() => props.paperset.owner),
      papersetId: computed(() => props.paperset.id)
    })

    const formRules = {
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      permissionRules: [(v: string) => !!v || 'require permission setting']
    }

    const methods = {
      addCollaborator () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const form: any = refs.form
        const valid = form.validate() &&
          state.collaborators.map(collaborator => collaborator.email)
            .indexOf(state.email) === -1
        if (valid) {
          const collaborator: Collaborator & Partial<UserInfo> = {
            email: state.email,
            permission: state.permission
          }
          form.reset()
          state.permission = Permission.CanEdit
          state.collaborators.push(collaborator)
          PapersetDB.addCollaborator(props.paperset, collaborator)
        }
      },
      removeCollaborator (collaborator: Collaborator) {
        state.collaborators = state.collaborators.filter(
          c => c.email !== collaborator.email
        )
        PapersetDB.removeCollaborator(props.paperset, collaborator)
      },
      updateCollaboratorPermission (collaborator: Collaborator) {
        PapersetDB.removeCollaborator(props.paperset, collaborator)
        PapersetDB.addCollaborator(props.paperset, collaborator)
      }
    }

    watch(
      () => state.papersetId,
      () => {
        state.collaborators = getCollaborators()
      }
    )

    return {
      ...toRefs(state),
      ...methods,
      ...formRules
    }
  }
})
