
import { defineComponent, reactive, computed, toRefs, watch } from '@vue/composition-api'
import draggable from 'vuedraggable'

import PapersetCreator from '@/components/PapersetCreator.vue'
import { Paperset, UserProfile } from '@/models'
import { UserProfileDB } from '@/Firebase'

export default defineComponent({
  name: 'Sidebar',
  components: { PapersetCreator, draggable },
  props: {
    temporary: Boolean,
    drawerOpen: Boolean,
    toggle: Boolean,
    papersets: { type: Array as () => Paperset[], required: true },
    userProfile: { type: Object as () => UserProfile, required: true },
    linkPrefix: { type: String, default: 's' }
  },
  setup (props, { emit }) {
    const DEFAULT_VISIBLE_ITEMS = 20

    const state = reactive({
      drawer: props.drawerOpen || props.toggle,
      isDragging: false,
      isPapersetCreatorModalOpen: false,
      isShowingAll: {
        myPapersets: false,
        sharedPapersets: false
      },

      myPapersetTotal: computed(() => {
        return props.papersets.filter(c => c.owner === props.userProfile.email).length
      }),

      sharedPapersetTotal: computed(() => {
        return props.papersets.filter(c => c.owner !== props.userProfile.email).length
      }),

      invitations: computed(() => {
        return props.userProfile.invitations ?? []
      }),

      dragOptions: computed(() => {
        return {
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        }
      })
    })

    const methods = {
      getMyPapersets () {
        const papersets = props.papersets.filter(
          c => c.owner === props.userProfile.email
        )
        if (state.isShowingAll.myPapersets) {
          return papersets
        } else {
          return papersets.slice(0, DEFAULT_VISIBLE_ITEMS)
        }
      },

      getSharedPapersets () {
        const papersets = props.papersets.filter(
          c => c.owner !== props.userProfile.email
        )
        if (state.isShowingAll.sharedPapersets) {
          return papersets
        } else {
          return papersets.slice(0, DEFAULT_VISIBLE_ITEMS)
        }
      },

      onNewPaperset (newPaperset: Partial<Paperset>) {
        state.isPapersetCreatorModalOpen = false
        emit('onNewPaperset', newPaperset)
      }
    }

    const data = reactive({
      myPapersets: methods.getMyPapersets(),

      sharedPapersets: methods.getSharedPapersets()
    })

    const onDragEnd = (papersets: Paperset[]) => {
      state.isDragging = false
      const updates: Record<string, Partial<unknown>> = {}
      papersets.forEach((paperset, index) => {
        updates[`papersets.${paperset.id}.order`] = index
      })
      UserProfileDB.update(updates)
    }

    watch(
      () => props.toggle,
      (toggle) => {
        state.drawer = toggle
      }
    )

    watch(
      () => props.toggle,
      (toggle) => {
        state.drawer = toggle
        if (!state.drawer) {
          emit('close')
        }
      }
    )

    watch(
      () => props.papersets,
      () => {
        data.myPapersets = methods.getMyPapersets()
      }
    )

    watch(
      () => props.papersets,
      () => {
        data.sharedPapersets = methods.getSharedPapersets()
      }
    )

    return {
      ...toRefs(state), ...toRefs(data), ...methods, DEFAULT_VISIBLE_ITEMS, onDragEnd
    }
  }
})
