
import { defineComponent, reactive, toRefs, ref } from '@vue/composition-api'
import { showAuthorNames } from '@/utils/formatPaperInfo'

import { Paper } from '@/models/Paper'

export default defineComponent({
  props: {
    paperToEdit: { type: Object as () => Paper, required: true },
    outlined: Boolean,
    buttonName: { type: String, default: '' }
  },
  setup (props, { emit }) {
    const state = reactive({
      valid: true,
      paper: Object.assign(
        { authorNames: showAuthorNames(props.paperToEdit.authors) },
        props.paperToEdit
      )
    })
    const PaperInfoEditForm = ref<HTMLFormElement>()

    const FormInputRules = {
      titleRules: [
        (v: string) => !!v || 'Paper title is required'
      ],
      yearRules: [
        (v: string) => !!v || 'year or date is required'
      ],
      authorRules: [
        (v: string) => !!v || 'At least one author name is required'
      ]
    }

    const methods = {
      validate () {
        if (PaperInfoEditForm.value !== undefined && PaperInfoEditForm.value.validate()) {
          const paperProps: Record<string, unknown> = Object.assign({}, state.paper)
          if (paperProps.authorNames) {
            paperProps.authors = (paperProps.authorNames as string)
              .split(',')
              .map(name => {
                const nameParts = name.split(' ')
                return {
                  family: nameParts.pop()?.trim(),
                  given: nameParts.join(' ').trim()
                }
              })
            delete paperProps.authorNames
          }
          emit('save', paperProps)
          PaperInfoEditForm.value.resetValidation()
        }
      }
    }

    return { ...toRefs(state), PaperInfoEditForm, ...FormInputRules, ...methods }
  },
  data: () => ({
    valid: true,
    titleRules: [
      (v: string) => !!v || 'Paper title is required'
    ],
    yearRules: [
      (v: string) => !!v || 'year or date is required'
    ],
    authorRules: [
      (v: string) => !!v || 'At least one author name is required'
    ]
  })
})
