<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import UserMenu from '@/components/UserMenu'
import { UserProfileDB, PapersetDB, getPdfFileUrl } from '@/Firebase'
import MarkerList from '@/components/MarkerList.vue'
import PdfViewer from '@/components/PdfViewer'
import { showAuthorNames } from '@/utils/formatPaperInfo.ts'
import { getReferenceItems } from '@/utils/PdfParser'
import tippy, { sticky } from 'tippy.js'
import { cachePaper } from '@/IndexedDB'
import { createRouteQueryString } from '@/utils/route'

export default {
  name: 'Reader',
  components: {
    UserMenu,
    PdfViewer,
    MarkerList
    // PaperRecord
  },
  mixins: [{ methods: { showAuthorNames } }],
  props: {
    isFullSize: Boolean,
    paperInfo: { type: Object, required: true },
    paperset: { type: Object, required: true },
    showUserMenu: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    height: { type: Number, default: 800 },
    width: { type: Number, default: 400 },
    selectedHighlightId: { type: String, default: undefined }
  },
  data: () => ({
    paper: undefined,
    loadingPaper: true,
    navigationMenu: false,
    hasPermission: true,
    mouseCursor: 0,
    pdfDocument: null,
    pageNumber: 1,
    paperUrl: null,
    pdfPages: null,
    dialog: false,
    navLeft: false,
    allowLocalFile: false,

    markerColors: [
      '#f1c40f', '#1abc9c', '#ee0000',
      '#27ae60', '#9b59b6', '#d35400',
      '#f368e0', '#7f8c8d', '#3498db'
    ],

    highlights: null,
    annotations: [],
    keywords: [],
    highlightTexts: [],
    matchWords: [],
    commentPos: {},
    userComments: [],
    commentIcons: {},

    papersetMarkers: null,
    selectedMarker: null,
    pdfViewHeight: null,
    selectedHighlightColor: null,
    selectedHighlightItems: null,
    selectedHighlight: {},
    onHighlightRenderDone: null,
    initialHighlightId: null,
    commentText: '',
    showCommentInput: false,
    editCommentId: null,
    displayCommentPanel: false,
    paperNotes: '',
    selectedReference: undefined,
    pdfReferences: [],
    errorMessage: '',
    showUrlCopyNotification: false
  }),
  computed: {
    papersetId () {
      return this.paperset.id
    },
    paperId () {
      return this.paper.id
    },
    pageTotal () {
      return this.pdfDocument ? this.pdfDocument.numPages : 1
    },
    colMarkerColors () {
      return Array.isArray(this.papersetMarkers)
        ? this.papersetMarkers.map(m => m.color)
        : []
    },
    canRead () {
      return this.canEdit || this.paperset?.readers?.indexOf(this.user.email) !== -1
    },
    selectedComments () {
      return this.userComments
        .filter(c => c.highlightId === this.selectedHighlight.id)
        .map(comment => {
          return {
            color: this.papersetMarkers[this.selectedHighlight.colorId].color,
            ...comment
          }
        })
    },
    papersInSet () {
      const papers = Object.values(this.paperset.papers)
      for (const paper of papers) {
        paper.normalizedTitle = paper.title?.toLowerCase().replace(/\s+/g, ' ').trim()
      }
      return papers
    },

    labels () {
      return Object.values(
        this.paperset.paperLabels
      ).map((label) => {
        return label
      }).filter(label => !label.isDeleted)
    }
  },
  watch: {
    selectedHighlightId () {
      this.commentText = ''
      this.editCommentId = null
      this.showCommentInput = false
      if (this.pdfPages !== null) {
        const selectedHighlight = this.highlights.find(
          h => h.id === this.selectedHighlightId
        )
        this.scrollToHighlight(selectedHighlight)
      }
    },
    displayCommentPanel () {
      this.$refs.highlightComment.style.display = this.displayCommentPanel ? 'block' : 'none'
    },
    selectedComments () {
      this.displayCommentPanel = true
    },
    paperInfo () {
      this.loadingPaper = true
      this.errorMessage = ''
      this.selectedHighlight = {}
      if (this.hasPermission) {
        this.getPaper()
      }
    },
    height () {
      this.pdfViewHeight = this.height - this.$refs.AppBar.$el.clientHeight
    }
    // async width () {
    //   this.$refs.PdfViewer.fitWidth()
    // }
  },
  created () {
    this.getPaper()
    if (Array.isArray(this.paperset.markers)) {
      this.papersetMarkers = this.paperset.markers
      this.selectedMarker = this.papersetMarkers[0]
    }
  },
  async mounted () {
    this.pdfViewHeight = this.height - this.$refs.AppBar.$el.clientHeight
  },
  methods: {
    async getPaper () {
      this.paper = await PapersetDB.Papers.get(this.paperset.id, this.paperInfo.id)
      const recordedPaper = Object.assign({
        papersetId: this.paper?.papersetId ?? this.paperset.id
      }, this.paper)
      document.title = 'PaperSet | ' + this.paper.title
      UserProfileDB.addToHistory(recordedPaper)
      this.keywords = this.paper.keywords
      if (typeof this.paper.pdfUrl === 'string') {
        this.paperUrl = this.paper.pdfUrl
      } else {
        try {
          const url = await getPdfFileUrl(recordedPaper.papersetId, this.paper.id)
          PapersetDB.updatePaper(this.papersetId, this.paperId, { pdfUrl: url })
          this.paperUrl = url
        } catch (error) {
          this.errorMessage = 'Something went wrong: failed to load PDF file.'
          console.error(error)
        }
      }
      this.getAnnotations()
    },

    async getAnnotations () {
      let annotations = {}
      if (this.paperId in this.paperset.annotations) {
        annotations = this.paperset.annotations[this.paperId]
      }
      if (this.paper.hasMoreAnnotations) {
        const extraAnnotations = await PapersetDB.getPaperAnnotations(
          this.papersetId,
          this.paperId
        )
        if (extraAnnotations !== {} || extraAnnotations !== null) {
          Object.assign(this.annotations, extraAnnotations)
        }
      }
      this.annotations = Object.keys(annotations).map(annId => {
        return {
          id: annId,
          ...this.paperset.annotations[this.paperId][annId]
        }
      })
      this.highlights = this.annotations.filter(ann => ann.type === 'highlight')
        .sort((a, b) => b.pinned - a.pinned)

      this.userComments = this.annotations.filter(ann => ann.type === 'comment')
        .map(comment => {
          const highlight = this.highlights.find(h => h.id === comment.highlightId)
          return {
            color: this.papersetMarkers[highlight.colorId].color,
            ...comment
          }
        })
    },

    async onPdfLoaded (pdfDoc) {
      const pdfData = await pdfDoc.getData()
      cachePaper({
        paperId: this.paper.id,
        fileSnapshot: new Blob([pdfData.buffer])
      })
      this.pdfDocument = pdfDoc
      this.loadingPaper = false
      this.navLeft = true
      this.pdfPages = await this.getPdfPages()
      this.outline = await pdfDoc.getOutline()
      const getPdfReferencesPromises = []

      // this.createThumbnails()
      this.highlightTexts = this.highlights.map(ann => {
        return {
          id: ann.id,
          text: ann.text.replace(/<br \/>/g, ''),
          bgColor: (ann.colorId > -1 && this.papersetMarkers[ann.colorId])
            ? this.papersetMarkers[ann.colorId].color
            : ann.color,
          pageIndex: ann.pageIndex
        }
      })

      const pdfViewer = this.$refs.PdfViewer
      if (this.selectedHighlightId) {
        this.selectedHighlight = this.highlights.find(
          h => h.id === this.selectedHighlightId
        )
      }

      this.pdfPages.forEach((page) => {
        getPdfReferencesPromises.push(page.getAnnotations())
      })
      const pdfReferencesByPage = await Promise.all(getPdfReferencesPromises)
      const pdfReferences = []
      const dests = new Set()
      for (const refs of pdfReferencesByPage) {
        for (const ref of refs) {
          // FIXME: fix if ref.dest is an object (not string)
          // TODO: check pdf.js docs and codes
          if (ref.dest?.startsWith('cite.') && !dests.has(ref.dest)) {
            pdfReferences.push(ref)
            dests.add(ref.dest)
          }
        }
      }
      const allDestinations = await pdfDoc.getDestinations()

      for (const ref of pdfReferences) {
        const dest = allDestinations[ref.dest]
        if (dest) {
        // https://github.com/mozilla/pdf.js/blob/284d2590546b90729df9beee13631dd0f69feeaa/web/pdf_link_service.js#L175
        // https://github.com/mozilla/pdf.js/blob/284d2590546b90729df9beee13631dd0f69feeaa/web/pdf_link_service.js#L114
          const pageIndex = await pdfDoc.getPageIndex(dest[0])
          ref.pageIndex = pageIndex
          ref.left = dest[2]
          ref.top = dest[3]
        }
      }
      pdfReferences.sort((a, b) => {
        if (a.pageIndex === b.pageIndex) {
          if (a.left === b.left) {
            // need to reverse due to the canvas orentiation: 0 is bottom
            return b.top - a.top
          }
          return a.left - b.left
        }
        return a.pageIndex - b.pageIndex
      })

      if (!this.paper?.references || this.paper.references.length < 1) {
        const textItems = []
        for (const page of this.pdfPages) {
          const text = await page.getTextContent()
          textItems.push(text.items)
        }
        const paperRefItems = getReferenceItems(textItems)
        let itemIndex = 0
        let refIndex = 0
        const refStrings = []
        let curRefString = ''

        while (itemIndex < paperRefItems.length) {
          if (refIndex >= pdfReferences.length) {
            curRefString += paperRefItems[itemIndex].str
          } else if (
            Math.abs(
              paperRefItems[itemIndex].transform[4] - pdfReferences[refIndex].left
            ) < 15 &&
            Math.abs(
              paperRefItems[itemIndex].transform[5] - pdfReferences[refIndex].top
            ) < 15
          ) {
            refIndex += 1
            refStrings.push(curRefString)
            curRefString = paperRefItems[itemIndex].str
          } else {
            curRefString += paperRefItems[itemIndex].str
          }
          itemIndex += 1
        }
        this.paper.references = refStrings.slice(1).map((text) => {
          return { text, index: null }
        })
      }

      this.pdfReferences = pdfReferences

      pdfViewer.pdfViewer.pagesPromise.then(async () => {
        let selectedHighlight = {}
        if (this.selectedHighlight?.id) {
          selectedHighlight = Object.assign({}, this.selectedHighlight)
          this.pageNumber = selectedHighlight.pageIndex + 1 || 0
        }
        for (let i = 0; i < this.pageTotal; ++i) {
          const pageView = await pdfViewer.pdfViewer.getPageView(i)
          if (pageView && pageView.textLayer) {
            if (selectedHighlight.id && selectedHighlight.pageIndex === i) {
              this.scrollToHighlight(selectedHighlight)
              // this.displayCommentPanel = false
            }
          }
        }
      })

      // this.onHighlightRenderDone = () => {
      this.userComments.forEach(ann => {
        const page = document.querySelectorAll('.page')[ann.pageIndex || 0]
        const icon = document.getElementById(ann.id)
        if (page && icon) {
          icon.style.top = ann.position.top * page.clientHeight + 'px'
          icon.style.left = ann.position.left * page.clientWidth + 'px'
          page.appendChild(icon)
        }
        this.commentIcons[ann.id] = icon
        // this.onHighlightRenderDone = null
      })
      this.$refs.UserCommentIcons.style.display = 'block'
      // }
    },

    updateCommentIcons () {
      for (let i = 0; i < this.pageTotal; i++) {
        const page = document.querySelectorAll('.page')[i]
        this.userComments.filter(c => c.pageIndex === i).forEach(comment => {
          if (this.commentIcons[comment.id]) {
            this.commentIcons[comment.id].style.top = comment.position.top * page.clientHeight + 'px'
            this.commentIcons[comment.id].style.left = comment.position.left * page.clientWidth + 'px'
            page.appendChild(this.commentIcons[comment.id])
          }
        })
      }
    },

    async getPdfPages () {
      const pages = []
      for (let i = 0; i < this.pageTotal; i++) {
        pages[i] = await this.pdfDocument.getPage(i + 1)
      }
      return pages
    },

    async addHighlight () {
      const highlight = this.$refs.PdfViewer.addHighlight()
      if (!highlight) {
        return
      }
      const newHighlight = {
        type: 'highlight',
        paperId: this.paperId,
        pageIndex: highlight.pageIndex,
        // text: highlight.text,
        // color: this.selectedMarker.color,
        pinned: false,
        colorId: this.colMarkerColors?.indexOf(this.selectedMarker.color)
      }
      const highlightTexts = []
      await this.$nextTick()
      const highlightElements = document.querySelectorAll('span[highlight-id="new-highlight"]')
      if (highlightElements.length > 0) {
        highlightElements.forEach(hlem => {
          highlightTexts.push(hlem.innerText)
        })
        newHighlight.text = highlightTexts.join('<br />')
      } else {
        newHighlight.text = highlight.text
      }
      // newHighlight.text
      const highlightId = await PapersetDB.addAnnotation(
        this.papersetId,
        this.paperId,
        newHighlight,
        this.paper.hasMoreAnnotations
      )

      newHighlight.id = highlightId
      this.highlights.push(newHighlight)
      highlightElements.forEach(elm => {
        elm.setAttribute('highlight-id', highlightId)
      })
      this.highlightTexts.push({
        id: highlightId,
        pageIndex: highlight.pageIndex,
        text: highlight.text,
        bgColor: this.selectedMarker.color
      })
      this.checkIfExtraAnnotationDoc()

      this.$refs.MarkerButton.style.display = 'none'
      this.$emit('addHighlight', { paperId: this.paperId, ...newHighlight })
    },

    addMarker (marker) {
      return PapersetDB.addArrayValue(this.papersetId, 'markers', marker)
    },

    updateHighlights (sentences) {
      if (!Array.isArray(sentences) || sentences.length === 0) {
        this.highlightTexts = []
      } else {
        this.highlightTexts = sentences.map(line => {
          return {
            bgColor: line.color || 'blue',
            text: line.line,
            topic: line.topic
          }
        })
        // .filter(s => this.keywords.indexOf(s.topic) !== -1 )
      }
    },

    highlightKeywords (keywords) {
      this.matchWords = keywords
    },

    onPageChange (page) {
      this.pageNumber = page
    },

    onPageRendered () {
      const links = document.querySelectorAll('.linkAnnotation > a')
      links.forEach(link => {
        link.onmouseenter = () => {
          this.$refs.ReferenceCard.style.display = 'block'
          const refDest = decodeURIComponent(link.getAttribute('href')).slice(1)
          const refIndex = this.pdfReferences.map(r => r.dest).indexOf(refDest)
          if (refIndex !== -1 && this.paper.references) {
            this.selectedReference = this.paper.references[refIndex]
            tippy(link, {
              content: this.$refs.ReferenceCard,
              appendTo: () => document.body,
              allowHTML: true,
              placement: 'bottom-start',
              sticky: true,
              showOnCreate: true,
              plugins: [sticky],
              interactive: true,
              theme: 'custom'
            })
          } else {
            // TODO: show error msg for failing to get the reference info from PDF
          }
        }
      })
    },

    scrollToDest (item) {
      this.$refs.PdfViewer.scrollToDest(item.dest)
    },

    backTopaperset () {
      this.$router.push('/ps/' + this.papersetId)
    },

    onHighlightSelect (htmlNode, highlightId, highlightItems) {
      if (highlightId && this.canEdit) {
        this.selectedHighlight = this.highlights.find(h => h.id === highlightId)
        this.selectHighlight(highlightItems, true)
      } else {
        if (htmlNode.nodeName !== 'I' && htmlNode.nodeName !== 'BUTTON') {
          this.$refs.highlightTextButton.style.display = 'none'
          this.$refs.highlightBoundingBox.style.display = 'none'
          this.displayCommentPanel = false
        }
      }
      this.$refs.MarkerButton.style.display = 'none'
    },

    removeHighlight () {
      this.$refs.highlightTextButton.style.display = 'none'
      this.selectedHighlightItems.forEach(ht => {
        ht.style.backgroundColor = 'transparent'
        ht.className = ''
      })
      this.deleteSelectedHighlight()
      this.$refs.highlightBoundingBox.style.display = 'none'
      this.displayCommentPanel = false
    },

    deleteComment (comment) {
      PapersetDB.deleteAnnotation(
        this.papersetId,
        this.paperId,
        comment.id,
        this.paper.hasMoreAnnotations
      )

      this.userComments = this.userComments.filter(c => c.id !== comment.id)
      if (this.selectedComments.length === 0) {
        this.displayCommentPanel = false
      }
    },

    editComment (comment) {
      PapersetDB.updateAnnotation(this.papersetId,
        this.paperId,
        comment,
        this.paper.hasMoreAnnotations
      )
      this.editCommentId = null
    },

    changeHighlightColor (markerColor) {
      const highlightItem = this.highlights.find(h => h.id === this.selectedHighlight.id)
      if (highlightItem) {
        highlightItem.colorId = this.colMarkerColors.indexOf(markerColor)
        PapersetDB.updateAnnotation(
          this.papersetId,
          this.paperId,
          highlightItem,
          this.paper.hasMoreAnnotations
        )
      }
      this.$refs.highlightTextButton.style.display = 'none'
      this.selectedHighlightItems.forEach(ht => {
        ht.style.backgroundColor = markerColor
      })
    },

    updateMarker (markers) {
      PapersetDB.update(this.papersetId, { markers })
    },

    async scrollToHighlight (highlight) {
      if (highlight === undefined) return
      if (Number.isInteger(highlight.pageIndex)) {
        this.pageNumber = highlight.pageIndex + 1
      }
      this.selectedHighlight = highlight
      // TODO: make sure the rendering of the page is complete before select the highlight
      await this.$nextTick()
      const elms = document.querySelectorAll('span[highlight-id="' + highlight.id + '"]')
      if (elms && elms[0]) {
        elms[0].scrollIntoView()
        this.selectHighlight(elms)
        const comment = this.userComments.find(c => c.highlightId === highlight.id)
        if (comment) {
          this.showCommentBox(comment)
        }
      }
    },

    pinHighlight (highlight) {
      highlight.pinned = !(highlight.pinned)
      PapersetDB.updateAnnotation(this.papersetId,
        this.paperId,
        highlight,
        this.paper.hasMoreAnnotations
      )
    },

    showCommentBox (comment) {
      this.selectedHighlight = this.highlights.find(h => h.id === comment.highlightId)
      this.displayCommentPanel = true
      const elms = document.querySelectorAll('span[highlight-id="' + comment.highlightId + '"]')
      if (elms) {
        this.selectHighlight(elms)
      }
    },

    async addComment () {
      const highlight = this.highlights.find(h => h.id === this.selectedHighlight.id)
      if (this.commentText !== '') {
        const newComment = {
          type: 'comment',
          highlightId: this.selectedHighlight.id,
          position: Object.assign({}, this.commentPos),
          text: this.commentText,
          pageIndex: highlight.pageIndex
        }
        const annId = await PapersetDB.addAnnotation(
          this.papersetId,
          this.paperId,
          newComment,
          this.paper.hasMoreAnnotations
        )
        newComment.id = annId
        newComment.user = this.user
        newComment.color = this.papersetMarkers[this.selectedHighlight.colorId].color

        this.userComments.push(newComment)
        await this.$nextTick()
        const page = document.querySelectorAll('.page')[newComment.pageIndex]
        const icon = document.getElementById(newComment.id)
        if (page && icon) {
          icon.style.top = newComment.position.top * page.clientHeight + 'px'
          icon.style.left = newComment.position.left * page.clientWidth + 'px'
          page.appendChild(icon)
          this.commentIcons[newComment.id] = icon
        }
        this.commentText = ''
        this.checkIfExtraAnnotationDoc()
      }
    },

    async checkIfExtraAnnotationDoc () {
      if (
        !this.paper.hasMoreAnnotations &&
        (this.highlights.length + this.userComments.length > 100)
      ) {
        const props = { hasMoreAnnotations: true }
        PapersetDB.updatePaper(this.papersetId, this.paper.id, props)
        props['annotations.' + this.paper.id] = {}
        // this.paper.hasMoreAnnotations = true
        PapersetDB.update(this.papersetId, props)
        const annotations = {}
        this.highlights.forEach(ann => {
          annotations[ann.id] = ann
        })
        this.userComments.forEach(ann => {
          annotations[ann.id] = ann
        })
        PapersetDB.importAnnotations(this.papersetId, this.paperId, annotations)
      }
    },

    getHighlightBound (highlightItems, padding = 2) {
      const bound = {
        left: Number.POSITIVE_INFINITY,
        right: Number.NEGATIVE_INFINITY,
        top: Number.POSITIVE_INFINITY,
        bottom: Number.NEGATIVE_INFINITY
      }
      highlightItems.forEach(item => {
        const box = item.getBoundingClientRect()
        if (box.left < bound.left) {
          bound.left = box.left
        }
        if (box.top < bound.top) {
          bound.top = box.top
        }
        if (box.right > bound.right) {
          bound.right = box.right
        }
        if (box.bottom > bound.bottom) {
          bound.bottom = box.bottom
        }
      })
      const offset = highlightItems[0].parentNode.parentNode.getBoundingClientRect()
      return {
        top: bound.top - offset.top - padding,
        left: bound.left - offset.left - padding * 2,
        width: bound.right - bound.left + padding * 4,
        height: bound.bottom - bound.top + padding * 2
      }
    },

    getPosLeftByPageSide (highlightItems, bound) {
      const left = bound.left + bound.width
      if (left > highlightItems[0].parentNode.parentNode.clientWidth / 2) {
        return left
      } else {
        return bound.left
      }
    },

    selectHighlight (highlightItems, showButtons = false) {
      if (!highlightItems) {
        return
      }
      this.selectedHighlightItems = highlightItems
      this.selectedHighlightColor = highlightItems[0].style.backgroundColor
      const padding = 2
      const parentNode = highlightItems[0].parentNode.parentNode
      const bound = this.getHighlightBound(highlightItems, padding)
      const hlBox = this.$refs.highlightBoundingBox
      hlBox.style.top = bound.top + 'px'
      hlBox.style.left = bound.left + 'px'
      hlBox.style.width = bound.width + 'px'
      hlBox.style.height = bound.height + 'px'
      hlBox.style.border = '3px solid ' + this.selectedHighlightColor
      hlBox.style.display = 'block'

      parentNode.appendChild(hlBox)
      const commentBox = this.$refs.highlightComment
      const commentBoxLeft = bound.left + bound.width
      if (commentBoxLeft - padding * 2 > parentNode.clientWidth / 2) {
        commentBox.style.left = commentBoxLeft + 'px'
        this.commentPos.left = (commentBoxLeft + 10) / parentNode.clientWidth
      } else {
        commentBox.style.left = (bound.left - 220) + 'px'
        this.commentPos.left = (bound.left - 20) / parentNode.clientWidth
      }
      this.commentPos.top = bound.top / parentNode.clientHeight
      commentBox.style.top = (bound.top) + 'px'
      parentNode.parentNode.appendChild(commentBox)
      this.displayCommentPanel = !!(this.selectedComments)
      if (showButtons) {
        this.$refs.highlightTextButton.style.top = (bound.top + bound.height) + 'px'
        this.$refs.highlightTextButton.style.left = (bound.left) + 'px'
        this.$refs.highlightTextButton.style.opacity = 1
        this.$refs.highlightTextButton.style.display = 'block'
        parentNode.parentNode.appendChild(this.$refs.highlightTextButton)
      }
    },

    hex2rgba (hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
      return `rgba(${r},${g},${b},${alpha})`
    },

    savePaperNotes () {
      // this.paper.notes = this.paperNotes
      PapersetDB.updatePaper(this.papersetId, this.paper.id, { notes: this.paperNotes })
    },

    setMarkerButton (selected) {
      if (!this.canEdit || this.mouseCursor === 0) {
        return
      }
      if (selected === null) {
        this.$refs.MarkerButton.style.display = 'none'
      } else {
        const pos = selected.pos
        this.$refs.MarkerButton.style.left = pos.x + 'px'
        this.$refs.MarkerButton.style.top = pos.y + 'px'
        this.$refs.MarkerButton.style.display = 'block'
        selected.container.appendChild(this.$refs.MarkerButton)
      }
    },

    deleteSelectedHighlight () {
      this.highlights = this.highlights.filter(
        h => h.id !== this.selectedHighlight.id
      )
      this.highlightTexts = this.highlightTexts.filter(
        h => h.id !== this.selectedHighlight.id
      )
      PapersetDB.deleteAnnotation(
        this.papersetId,
        this.paperId,
        this.selectedHighlight.id,
        this.paper.hasMoreAnnotations
      )
      const comments = this.userComments.filter(
        c => c.highlightId === this.selectedHighlight.id
      )
      comments.forEach(comment => {
        PapersetDB.deleteAnnotation(
          this.papersetId,
          this.paperId,
          comment.id,
          this.paper.hasMoreAnnotations
        )
      })
      this.userComments = this.userComments.filter(
        c => c.highlightId !== this.selectedHighlight.id
      )
    },

    genGoogleScholarLink (text) {
      const query = (text.length > 300) ? text.slice(0, 300) : text
      return 'https://scholar.google.com/scholar?hl=en&q=' + encodeURIComponent(query)
    },

    getPaperInSet (title) {
      return this.papersInSet.find(p => {
        return title.toLowerCase().replace(/\s+/g, ' ').includes(p.normalizedTitle)
      })
    },

    goToReferencePaper () {
      const toPath = '/s/' + this.paperset.id + '/papers?paperId=' +
        this.getPaperInSet(this.selectedReference.text).id + '&vpid=' + this.paper.id
      if (this.$route.path !== toPath) {
        this.$router.push(toPath)
      }
    },

    onCopyText (savedPdfText) {
      this.$store.dispatch('setSavedPdfText', {
        text: savedPdfText.text,
        textNodes: savedPdfText.textNodes,
        paperId: this.paperInfo.id,
        papersetId: this.paperset.id
      })
    },

    async copyHighlightURL () {
      const queryString = createRouteQueryString(
        this.$route,
        { vhid: this.selectedHighlight.id }
      )
      await navigator.clipboard.writeText(location.host + this.$route.path + `?${queryString}`)
      this.showUrlCopyNotification = true
    }
  }
}
</script>

<template>
  <div>
    <v-toolbar
      ref="AppBar"
      floating
      flat
      dense
      class="py-0"
      style="display: block;"
    >
      <v-spacer />
      <v-btn
        icon
        @click="$refs.PdfViewer.fitWidth()"
      >
        <v-icon>mdi-arrow-expand-horizontal</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$refs.PdfViewer.fitHeight()"
      >
        <v-icon>mdi-arrow-expand-vertical</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$refs.PdfViewer.zoomOut()"
      >
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$refs.PdfViewer.zoomIn()"
      >
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        :disabled="pageNumber<2"
        @click="pageNumber-=1"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn text>
        {{ pageNumber }} / {{ pageTotal }}
      </v-btn>
      <v-btn
        icon
        :disabled="pageNumber>=pageTotal"
        @click="pageNumber+=1"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn-toggle
        v-if="canEdit"
        v-model="mouseCursor"
        style="background-color: #eee;"
        dense
        mandatory
      >
        <v-btn
          text
          small
        >
          <v-icon small>
            mdi-cursor-default-outline
          </v-icon>
        </v-btn>
        <v-btn
          text
          small
          title="enable marker"
        >
          <v-row
            align="center"
            class="flex-column"
            justify="center"
          >
            <v-icon small>
              mdi-marker
            </v-icon>
            <v-sheet
              v-if="selectedMarker"
              tile
              height="3"
              width="36"
              color="black"
            />
          </v-row>
        </v-btn>
      </v-btn-toggle>
      <!-- <v-btn
            v-if="canEdit"
            text
            style="width: 80px!important"
            @click="addHighlight"
            title="select text and click here to highlight"
            class="ma-0 pl-0"
          >
            <v-row
              align="center"
              class="flex-column"
              justify="center"
            >
              <v-icon>mdi-marker</v-icon>
              <v-sheet
                v-if="selectedMarker"
                tile
                height="5"
                width="36"
                :color="selectedMarker.color"
              ></v-sheet>
            </v-row>
          </v-btn>
          <v-menu offset-y v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn icon class="px-0"
                v-on="on"
                width="25"
                small
                style="margin-left: -25px;"
              >
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-3" dense>
              <v-list-item
                v-for="(marker, index) in papersetMarkers"
                :key="index"
                class="pa-0 ma-0"
                @click="selectedMarker=marker"
              >
                <v-list-item-title class="body-1 text-left">
                  <v-icon :color="marker.color" class="ml-3 mr-2">mdi-square</v-icon>
                  {{ marker.label }}
                  </v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog=true">
                <v-list-item-title class="body-1">
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  Add new marker</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
      <v-spacer />
      <UserMenu v-if="showUserMenu" />
      <v-btn
        icon
        small
        :title="isFullSize ? 'Exit full screen' : 'Full screen'"
        @click="$emit('toggleFullSize', !isFullSize)"
      >
        <v-icon>{{ isFullSize ? 'mdi-fullscreen-exit' : 'mdi-fit-to-screen-outline' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      v-if="loadingPaper || !hasPermission"
      class="fill-height pa-0"
      fluid
    >
      <v-row
        v-if="hasPermission"
        align="center"
        justify="center"
        class="fill-height"
        :style="{height: height + 'px'}"
      >
        <v-col
          cols="12"
          sm="10"
          md="8"
        >
          <v-card
            flat
            class="text-center pa-3"
            :color="$vuetify.theme.themes.light.base"
          >
            <v-card-text v-if="errorMessage">
              <v-alert
                border="top"
                color="red lighten-2"
                dark
                class="my-5"
                v-text="errorMessage"
              />
            </v-card-text>
            <v-card-text v-else>
              <p class="subtitle-2">
                Loading PDF file
              </p>
              <v-progress-circular
                :size="30"
                :width="5"
                color="primary"
                indeterminate
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        align="center"
        justify="center"
      >
        <v-col cols="4">
          <v-alert
            prominent
            type="warning"
          >
            <h3>Permission Denied </h3>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <div
      ref="UserCommentIcons"
      class="hidden"
    >
      <v-btn
        v-for="userComment in userComments"
        :id="userComment.id"
        :key="userComment.id"
        icon
        small
        :color="userComment.color"
        :title="userComment.text"
        style="position: absolute;"
        @click="showCommentBox(userComment)"
      >
        <v-icon small>
          mdi-message-text
        </v-icon>
      </v-btn>
    </div>
    <div
      ref="ReferenceCard"
      style="display: none"
    >
      <v-card v-if="selectedReference">
        <!-- <PaperRecord
          v-if="getPaperInSet(selectedReference.text)"
          class="pa-0"
          style="font-size: 6px;"
          :paperset-id="paperset.id"
          :paper="getPaperInSet(selectedReference.text)"
          :labels="labels"
        /> -->
        <v-card-subtitle>
          <strong v-if="selectedReference.index">{{ selectedReference.index }}:</strong> {{ selectedReference.text }}
          <v-btn
            v-if="getPaperInSet(selectedReference.text)"
            rounded
            x-small
            style="color: steelblue; margin: 0 1em;"
            outlined
            @click="goToReferencePaper"
          >
            Go to Paper
          </v-btn>
          <a
            target="_blank"
            title="find this paper on Google Scholar"
            :href="genGoogleScholarLink(selectedReference.text)"
          >
            <img
              width="18"
              style="vertical-align: sub;"
              :src="require('@/assets/google-scholar.jpeg')"
            >
          </a>
        </v-card-subtitle>
      </v-card>
    </div>
    <v-container
      fluid
      class="pa-0 ma-0 fill-height"
      style="overflow: hidden;"
    >
      <div
        ref="highlightBoundingBox"
        class="selected-highlight-ui"
        style="opacity: 0.5;"
      />
      <div
        ref="highlightComment"
        class="selected-highlight-ui"
        style="width: 220px;"
      >
        <v-card
          v-if="displayCommentPanel"
          class="pa-0"
        >
          <v-card-text
            v-for="comment in selectedComments"
            :key="comment.id"
            class="text-left px-0 pt-0 pb-2"
            style="border-bottom: 1px solid #ccc;"
          >
            <v-toolbar
              dense
              flat
            >
              {{ comment.user.name }}
              <v-spacer />
              <v-menu
                v-if="user.uid === comment.user.uid"
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list class="text-left">
                  <v-list-item @click="editCommentId = comment.id">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteComment(comment)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <div v-if="comment.id === editCommentId">
              <v-textarea
                v-model="comment.text"
                class="px-2 py-0 ma-0"
                auto-grow
                outlined
                rows="1"
                hide-details
              />
              <div class="pa-1">
                <v-btn
                  x-small
                  outlined
                  class="ma-1"
                  color="primary"
                  @click="editComment(comment)"
                >
                  Save
                </v-btn>
                <v-btn
                  x-small
                  outlined
                  class="ma-1"
                  @click="editCommentId=null"
                >
                  Cancel
                </v-btn>
              </div>
            </div>
            <div
              v-else
              class="px-5"
              @click="showCommentInput=true"
            >
              {{ comment.text }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="showCommentInput"
            class="px-0"
          >
            <v-textarea
              ref="highlightCommentText"
              v-model="commentText"
              class="px-2 py-0 ma-0"
              auto-grow
              outlined
              rows="1"
              hide-details
            />
            <div class="pa-1">
              <v-btn
                x-small
                outlined
                class="ma-1"
                color="primary"
                :disabled="commentText === ''"
                @click="addComment"
              >
                Comment
              </v-btn>
              <v-btn
                x-small
                outlined
                class="ma-1"
                @click="showCommentInput=false"
              >
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div
        ref="highlightTextButton"
        :class="{hidden: !selectedHighlight}"
        class="selected-highlight-ui"
      >
        <div>
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-btn
                small
                color="#EEE"
                class="pr-0"
                v-on="on"
              >
                <v-icon
                  small
                  :color="selectedHighlightColor"
                >
                  mdi-square
                </v-icon>
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list
              class="pa-3"
              dense
            >
              <v-list-item
                v-for="(marker, index) in papersetMarkers"
                :key="index"
                class="pa-0 ma-0"
                @click="changeHighlightColor(marker.color)"
              >
                <v-list-item-title class="body-1 text-left">
                  <v-icon
                    :color="marker.color"
                    class="ml-3 mr-2"
                  >
                    mdi-square
                  </v-icon>
                  {{ marker.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            small
            color="#EEE"
            @click="showCommentInput=displayCommentPanel=true"
          >
            <v-icon small>
              mdi-message-text
            </v-icon>
          </v-btn> -->
          <v-btn
            small
            color="#EEE"
            @click="copyHighlightURL"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>

          <v-btn
            small
            color="#EEE"
            @click="removeHighlight"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="canEdit"
        ref="MarkerButton"
        style="display: none; position: absolute;"
      >
        <v-btn-toggle v-if="selectedMarker">
          <v-btn
            text
            small
            title="select text and click here to highlight"
            class="ma-0 pa-0"
            @click="addHighlight"
          >
            <v-row
              align="center"
              class="flex-column"
              justify="center"
            >
              <v-icon small>
                mdi-marker
              </v-icon>
              <v-sheet
                v-if="selectedMarker"
                tile
                height="5"
                width="32"
                :color="selectedMarker.color"
              />
            </v-row>
          </v-btn>
          <v-menu
            v-if="canEdit"
            offset-y
          >
            <template #activator="{ on }">
              <v-btn
                small
                class="px-0"
                v-on="on"
              >
                <v-icon
                  small
                  :color="selectedMarker.color"
                >
                  mdi-square
                </v-icon>
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list
              class="pa-3"
              dense
            >
              <v-list-item
                v-for="(marker, index) in papersetMarkers"
                :key="index"
                class="pa-0 ma-0"
                @click="selectedMarker=marker; addHighlight()"
              >
                <v-list-item-title class="body-1 text-left">
                  <v-icon
                    :color="marker.color"
                    class="ml-3 mr-2"
                  >
                    mdi-square
                  </v-icon>
                  {{ marker.label }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog=true">
                <v-list-item-title class="body-1">
                  <v-icon class="mr-2">
                    mdi-plus
                  </v-icon>
                  Add new marker
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </div>
      <PdfViewer
        ref="PdfViewer"
        style="background-color: #fff; position: relative; overflow: hidden"
        :style="{ height: pdfViewHeight + 'px' }"
        absolute
        :paper-id="paperInfo.id"
        :pdf-url="paperUrl"
        :highlight-texts="highlightTexts"
        :match-words="matchWords"
        :page-number="pageNumber"
        :on-page-change="onPageChange"
        :on-page-rendered="onPageRendered"
        :on-highlight-select="onHighlightSelect"
        :on-highlight-render-done="onHighlightRenderDone"
        :marker="selectedMarker"
        @pdfLoaded="onPdfLoaded"
        @resize="updateCommentIcons"
        @selectText="setMarkerButton"
        @copyText="onCopyText"
      />
    </v-container>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="320px"
    >
      <v-toolbar flat>
        <v-spacer />
        <v-btn
          icon
          @click="dialog=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        flat
        class="text-center pa-3"
      >
        <v-card-text>
          <MarkerList
            v-if="papersetMarkers"
            :can-edit="canEdit"
            :current-markers="papersetMarkers"
            :colors="markerColors"
            :on-marker-added="addMarker"
            :on-marker-changed="updateMarker"
            :prompt-new-marker="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showUrlCopyNotification"
      timeout="2000"
    >
      <span class="subtitle-1">Link copied</span>
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showUrlCopyNotification = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.hidden {
  display: none;
}

.v-tab {
  background-color: #eeeeee;
  width: 72px;
  text-transform: none;
  max-height: 72px!important;
  min-width: 20px!important;
  font-size: 0.7em;
  padding: 0;
}

.v-tab::before {
   content: none;
}

.v-tab-active {
  border-right: 2px solid steelblue;
}

.v-btn {
  text-transform: none;
}

.selected-highlight-ui {
  position: absolute;
  display: none;
  z-index: 9999;
  opacity: 1;
}

.v-text-field input {
  font-size: 9px;
}

.v-card {
  min-width: 50px;
}

</style>
