
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppFooter',
  components: {

  },
  data () {
    return {}
  }
})
