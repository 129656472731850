import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EmbedContent',
  props: {
    embedContent: { type: String, required: true }
  },

  render () {
    return (
      <v-card>
        <v-toolbar
          flat
          dense
        >
          <v-spacer />
          <v-btn
            icon
            small
            onclick= { () => { this.$emit('close') } }
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text domPropsInnerHTML={ this.embedContent } />
      </v-card>
    )
  }
})
