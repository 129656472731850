
import { defineComponent } from '@vue/composition-api'
import PapersetInfoInfoEditForm from '@/components/PapersetInfoEditForm.vue'

import { Paperset } from '@/models'

import { PapersetDB, UserProfileDB, uploadPapersetCoverImage, getPapersetCoverImageUrl } from '@/Firebase'

export default defineComponent({
  name: 'PapersetCreator',
  components: {
    PapersetInfoInfoEditForm
  },
  data () {
    return {
      valid: true,
      isCreatingPaperset: false
    }
  },
  methods: {
    async createPaperset (papersetInfo: Partial<Paperset>) {
      const newPapersetInfo = Object.assign({}, papersetInfo)
      let coverImage = null
      this.isCreatingPaperset = true
      if (newPapersetInfo.cover) {
        coverImage = newPapersetInfo.cover
        delete newPapersetInfo.cover
      }
      const newPaperset = await UserProfileDB.addPaperset(newPapersetInfo)
      if (newPaperset !== null && newPaperset.id && coverImage) {
        await uploadPapersetCoverImage(
          newPaperset.id,
          coverImage
        )
        const coverImageURL = await getPapersetCoverImageUrl(newPaperset.id)
        await PapersetDB.update(newPaperset.id, { cover: coverImageURL })
      }
      this.$emit('create', newPaperset)
      this.isCreatingPaperset = false
    }
  }
})
