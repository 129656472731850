
import { defineComponent, reactive, toRefs, computed } from '@vue/composition-api'
import { Annotation, UserInfo, HighlightMarker, Paper, PaperId } from '@/models'
import HighlightList from '@/components/HighlightList.vue'
import MarkerList from '@/components/MarkerList.vue'

export default defineComponent({
  name: 'HighlightPanel',
  components: { HighlightList, MarkerList },
  props: {
    currentUser: { type: Object as () => UserInfo, default: undefined },
    highlights: { type: Array as () => Annotation[], default: () => [] },
    paperInfo: { type: Object as () => Record<PaperId, Paper>, default: () => undefined },
    colors: { type: Array as () => string[], default: () => [] },
    selectedId: { type: Number, default: 0 },
    comments: { type: Array as () => Annotation[], default: () => [] },
    maxTextLength: { type: Number, default: 300 },
    markers: { type: Array as () => HighlightMarker[], default: () => [] },
    externalLink: Boolean,
    canEdit: Boolean,
    allowFilter: Boolean,
    allowDelete: Boolean
  },
  setup (props) {
    type State = {
      pinnedOnly: boolean
      matchText: string
      filterByText: boolean
      sortAttrs: { label: string, attr: string }[]
      sortAttr: string
      showAllHighlights: boolean
      filteredHighlights: Annotation[]
      myHighlights: Annotation[]
      selectedMarkerColorIds: number[],
      focusHighlightId?: string
    }

    const state: State = reactive({
      pinnedOnly: false,
      matchText: '',
      filterByText: false,
      sortAttrs: [
        { label: 'Time Added', attr: 'timestamp' },
        { label: 'Marker Color', attr: 'colorId' }
      ],
      sortAttr: 'timestamp',
      showAllHighlights: true,
      focusHighlightId: undefined,
      filteredHighlights: computed(() => {
        if (!Array.isArray(props.highlights)) {
          return []
        }
        let highlights = state.showAllHighlights
          ? props.highlights.slice()
          : state.myHighlights
        if (state.filterByText && state.matchText.length > 1) {
          highlights = highlights.filter(
            h => h.text?.toLowerCase().includes(state.matchText.toLowerCase())
          )
        }
        if (state.selectedMarkerColorIds.length) {
          highlights = highlights.filter(
            h => state.selectedMarkerColorIds.indexOf(h.colorId) !== -1
          )
        }
        // if (Array.isArray(props.comments)) {
        //   highlights.forEach(hl => {
        //     hl.comments = props.comments.filter(c => c.highlightId === hl.id)
        //   })
        // }
        // highlights.sort((a, b) => a[state.sortAttr] - b[state.sortAttr])
        return highlights.filter(highlight => !highlight.pinned)
      }),

      myHighlights: computed(() => {
        if (Array.isArray(props.highlights) && props.currentUser) {
          return props.highlights.filter(
            h => h.user && h.user.email === props.currentUser.email
          )
        }
        return []
      }),

      pinnedHighlights: computed(() => {
        return props.highlights.filter(p => p.pinned)
      }),
      selectedMarkerColorIds: []
    })
    return { ...toRefs(state) }
  },
  watch: {
    selectedColorIds () {
      this.filterByText = true
    }
  },
  methods: {
    searchHighlight () {
      if (this.matchText.length > 1) {
        this.filterByText = true
      }
    },
    filterHighlights (selectedColorIds: number[]) {
      this.selectedMarkerColorIds = selectedColorIds
    }
  }
})
