
import { defineComponent, watch, ref } from '@vue/composition-api'

import { Paperset } from '@/models'
import PdfParser from '@/utils/PdfParser.js'
import { parseRefStrings } from '@/utils/RefParser.js'
import { PapersetDB, uploadPdfFile, uploadPdfThumbnail, uploadPdfFileAsBase64Str, getPdfThumbnailUrl } from '@/Firebase'
import { UploadResult, UploadTask } from '@firebase/storage'

// import genArtHash from '@/utils/genArtHash'

export default defineComponent({
  name: 'PdfImporter',
  props: {
    paperset: { type: Object as () => Paperset, required: true },
    isSelectingFile: Boolean
  },
  setup (props, { emit }) {
    const InputFiles = ref<HTMLInputElement>()

    const methods = {
      selectFiles () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.log(InputFiles.value)
        if (InputFiles.value !== undefined) {
          InputFiles.value.click()
        }
      },
      getSelectedFiles ($event: Event) {
        const target = $event.target as HTMLInputElement
        if (target.files) {
          emit('load', target.files)
          return this.loadFiles(target.files)
        }
      },
      async loadFiles (files: FileList) {
        if (files.length === 0) return
        for (const file of files) {
          if (file.type.match(/.*pdf/) && file.size < 32 * 1024 * 1024) {
            this.processPdfFile(file)
          } else {
            emit('error', 'Error: Invalid PDF file format; ' + file.name + ' is not a PDF file.')
          }
        }
      },
      async processPdfFile (file: File | string, isBase64 = false) {
        let url
        let isRemoteUrl = false
        if (isBase64 && typeof file === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          url = Buffer.from(file, 'base64')
        } else if (typeof file === 'string') {
          url = file
          isRemoteUrl = true
        } else {
          url = URL.createObjectURL(file)
        }
        const pdfParser = new PdfParser(url, isBase64)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newPaper = await pdfParser.getInfo() as any
        newPaper.authors = newPaper.authors
          .map((authorName: string) => authorName.trim())
          .filter((authorName: string) => authorName !== '')
          .map((authorName: string) => {
            const name = authorName.split(' ')
            return {
              family: name.pop(),
              given: name
            }
          })
        const paperTextItems = await pdfParser.getPaperTextItems()
        const refTextItems = pdfParser.getReferenceItems(paperTextItems)
        const refStrings = parseRefStrings(refTextItems)

        if (Array.isArray(refStrings) && refStrings.length) {
          /* Remove author bibliography if it's in the last reference item from parsing */
          const lastRef = refStrings[refStrings.length - 1].text
          if (lastRef.length > 500) {
            const firstAuthorName = newPaper.authors[0].given.concat(newPaper.authors[0].family).join(' ')
            const checkIfBioInLastRef = lastRef.match(firstAuthorName)
            if (checkIfBioInLastRef) {
              refStrings[refStrings.length - 1].text = lastRef
                .slice(0, checkIfBioInLastRef.index)
                .trim()
            }
          }
        }
        newPaper.references = refStrings

        // let refItems = await parseRefInfo(refStrings)
        // if (refItems.length > 0) {
        //   let paperRefs = refItems
        //   let references = paperRefs.map((ref, refId) => {
        //     let refPaper = {}
        //     if (Array.isArray(ref.title)) {
        //       refPaper.title = ref.title[0]
        //       refPaper.authors = (!Array.isArray(ref.author)) ? [''] : ref.author
        //       if (Array.isArray(ref['container-title']) && ref['container-title'].length) {
        //         refPaper.venue = (ref['container-title']) ? ref['container-title'][0].replace(/-/g, '') : ''
        //       }
        //       refPaper.year = Array.isArray(ref.date) ? ref.date[0] : 'unknown'
        //       if (Array.isArray(ref.date)) refPaper.date = ref.date[0]
        //       if (ref.pages) refPaper.pages = (Array.isArray(ref.pages)) ? ref.pages[0] : ref.pages
        //       if (ref.publisher) refPaper.publisher = (Array.isArray(ref.publisher)) ? ref.publisher[0] : ref.publisher
        //       if (ref.volume) refPaper.volume = (Array.isArray(ref.volume)) ? ref.volume[0] : ref.volume
        //       if (ref.issue) refPaper.issue = (Array.isArray(ref.issue)) ? ref.issue[0] : ref.issue
        //       refPaper.origin = refs.raw[refId]
        //       refPaper.id = genArtHash(refPaper.authors[0].family, refPaper.year, refPaper.title)
        //       return refPaper
        //     }
        //   }).filter(ref => ref !== undefined && ref.id !== null)
        // papersets.References.add(
        //   this.paperset.id,
        //   references
        // )
        //   newPaper.references = references.map(r => r.id)
        // }

        /* Add paper to database and upload the pdf to cloud storage */

        newPaper.hasPDF = true

        if (isRemoteUrl) {
          newPaper.remotePdfUrl = url
        }
        newPaper.isRemoteUrl = isRemoteUrl
        try {
          newPaper.id = await PapersetDB.addPaper(
            props.paperset.id,
            Object.assign({}, newPaper)
          )
        } catch (error) {
          console.error(error)
          throw error
        }
        const thumb = await pdfParser.getPageThumbnail(1)
        const thumbDataURL = thumb.toDataURL()
        newPaper.thumb = thumbDataURL

        emit('parse', newPaper)
        let uploadTask: UploadTask | Promise<UploadResult>
        if (!isRemoteUrl) {
          if (typeof file === 'string') {
            uploadTask = uploadPdfFileAsBase64Str(props.paperset.id, newPaper.id, file)
          } else {
            uploadTask = uploadPdfFile(props.paperset.id, newPaper.id, file)
          }
        } else {
          const pdfFileData = await pdfParser.getFileData()
          uploadTask = uploadPdfFile(props.paperset.id, newPaper.id, pdfFileData)
        }
        const paperTexts = paperTextItems.map(
          pageItems => pageItems.map((text: {str: string}) => text.str).join('<br />')
        )
        PapersetDB.PaperTexts.set(
          props.paperset.id, newPaper.id, { content: paperTexts }
        )

        emit('done', newPaper, uploadTask)

        await uploadPdfThumbnail(
          props.paperset.id, newPaper.id, thumbDataURL
        )
        try {
          const thumbUrl = await getPdfThumbnailUrl(props.paperset.id, newPaper.id)
          PapersetDB.updatePaper(props.paperset.id, newPaper.id, { thumb: thumbUrl })
        } catch (error) {
          console.error(error)
          throw new Error('Cannot get thumbnail URL')
        }
      }
    }

    watch(
      () => props.isSelectingFile,
      () => {
        if (props.isSelectingFile) {
          methods.selectFiles()
        }
      }
    )

    return { ...methods, InputFiles }
  }
})
