
import { defineComponent, onBeforeUnmount, reactive, toRefs, onMounted, ref, watch } from '@vue/composition-api'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import debounce from 'lodash.debounce'
import html2canvas from 'html2canvas'
import { UserInfo } from '@/models'
import { updatePapersetNote, watchNoteContent } from '@/Firebase/PapersetNoteDB'
import EditorToolbar from './EditorToolbar.vue'
import { uploadNoteImage } from '@/Firebase'

type EditorMode = 'readonly' | 'editing'

export default defineComponent({
  components: {
    EditorContent,
    EditorToolbar
    // BubbleMenu
  },
  props: {
    noteId: { type: String, required: true },
    canEditTitle: { type: Boolean, default: false },
    // paperId: { type: String, required: true },
    papersetId: { type: String, required: true }
  },
  setup (props, { root, emit }) {
    const state: {
      editor?: Editor
      editorMode: EditorMode
      title: string
      content: string
      status: 'noChange' | 'saving...' | 'saved' | 'error'
      isEditing: boolean
      editorCollaborators: UserInfo[]
      selectedHeading: number
      confirmDelete: boolean
      initializedNote: boolean
      hasNewContentFromServer: boolean
      newNoteContentFromServer: string | null
      isClosingNote: boolean
    } = reactive({
      editor: undefined,
      editorMode: 'editing',
      status: 'noChange',
      title: '',
      content: '',
      isEditing: false,
      editorCollaborators: [],
      selectedHeading: 0,
      confirmDelete: false,
      initializedNote: false,
      hasNewContentFromServer: false,
      newNoteContentFromServer: null,
      isClosingNote: false
    })
    const { papersetId, noteId } = props
    const updateNoteWithDelay = debounce(async () => {
      if (state.editor && !state.hasNewContentFromServer) {
        updatePapersetNote({
          papersetId,
          noteId: props.noteId,
          noteProps: { content: state.editor.getHTML() }
        })
      }
      state.status = 'saved'
    }, 3000)

    const savePaperNoteTitle = debounce(async () => {
      if (state.editor) {
        emit('changeTitle', state.title)
      }
    }, 2000)

    const textEditorRef = ref<HTMLElement>()
    const noteEditorContentRef = ref<HTMLElement>()

    const unwatchNoteContent = watchNoteContent({
      papersetId,
      noteId,
      onChanged: ({ title, content }) => {
        if (state.initializedNote) {
          state.hasNewContentFromServer = true
          state.newNoteContentFromServer = content
          state.title = title
          state.editor?.setEditable(false)
          state.status = 'noChange'
        } else {
          state.initializedNote = true
          state.title = title
          state.content = content
          state.editor?.commands.setContent(state.content)
        }
      }
    })

    onMounted(async () => {
      state.editor = new Editor({
        editable: state.editorMode === 'editing',
        content: state.content,
        extensions: [
          StarterKit,
          Underline
        ],
        onUpdate () {
          state.status = 'saving...'
          updateNoteWithDelay()
        }
      })

      let ctrlDown = false
      const ctrlKey = 17
      const cmdKeyRight = 91
      const cmdKeyLeft = 93
      const vKey = 86

      textEditorRef.value?.addEventListener('keydown', (e) => {
        if (e.ctrlKey || e.keyCode === ctrlKey ||
          e.keyCode === cmdKeyLeft || e.keyCode === cmdKeyRight) {
          ctrlDown = true
        }

        if (ctrlDown && e.keyCode === vKey) {
          const savedPdfTextProps = root.$store.getters.getSavedPdfText()
          if (savedPdfTextProps === null) return
          e.preventDefault()
          let copiedText = savedPdfTextProps.text.slice()
          let reconstructedText = []
          const { text, textNodes } = savedPdfTextProps

          try {
            const nodeText = textNodes[0].innerText
            const words = nodeText.split(' ')
            for (let wi = 0; wi < words.length; wi++) {
              const phrase = words.slice(wi).join(' ')
              if (phrase && text.includes(phrase)) {
                reconstructedText.push(phrase)
                break
              }
            }
            if (textNodes.length > 1) {
              for (let line = 1; line < textNodes.length - 1; line++) {
                reconstructedText.push(textNodes[line].innerText)
              }
              const lastTextNode = textNodes[textNodes.length - 1]
              const nodeText = lastTextNode.innerText
              const words = nodeText.split(' ')
              for (let wi = 0; wi <= words.length; wi++) {
                const invPhrase = words.slice(0, words.length - wi + 1).join(' ')
                if (invPhrase && text.includes(invPhrase.replace(/\W$/, ''))) {
                  reconstructedText.push(invPhrase)
                  break
                }
              }
            }

            copiedText = copiedText.replace(reconstructedText.join(''), ' ')
            copiedText = copiedText.replace(/\s\s+/g, ' ')
            const [head, tail] = copiedText.split(' ')

            copiedText = reconstructedText.join(' ').replace(/- /g, '')
            if (head) {
              copiedText = `${head} ${copiedText}`
            }
            if (tail) {
              copiedText = `${copiedText} ${tail}`
            }
          } catch (e) {
            console.warn('Failed to reconstruct the correct text from PDF:', e)
            reconstructedText = savedPdfTextProps.text
          }

          state.editor?.commands.insertContent(`<blockquote>${copiedText}</blockquote><p></p>`)
          root.$store.dispatch('setSavedPdfText', null)
        }
      })
    })

    const updateNoteContentFromServer = () => {
      state.editor?.commands.setContent(state.newNoteContentFromServer)
      state.editor?.setEditable(true)
      state.hasNewContentFromServer = false
    }

    onBeforeUnmount(() => {
      state.editor?.destroy()
      unwatchNoteContent()
    })

    const saveNotePreviewImage = async () => {
      if (papersetId && state.status !== 'noChange') {
        if (noteEditorContentRef.value) {
          const editorContent = state.editor?.getHTML()
          if (!editorContent) return
          noteEditorContentRef.value.style.display = 'block'
          noteEditorContentRef.value.innerHTML = editorContent
          const noteCanvas = await html2canvas(noteEditorContentRef.value)
          const thumbnailCanvas = document.createElement('canvas')
          thumbnailCanvas.width = 640
          thumbnailCanvas.height = 200
          const thumbnailCtx = thumbnailCanvas.getContext('2d')
          thumbnailCtx?.drawImage(
            noteCanvas,
            0,
            0,
            Math.min(noteCanvas.width, 640),
            200,
            0,
            0,
            640,
            200
          )
          const thumbnailDataUrl = thumbnailCanvas.toDataURL('image/png')
          noteEditorContentRef.value.style.display = 'none'

          return uploadNoteImage({
            papersetId,
            noteId: props.noteId,
            imageDataUrl: thumbnailDataUrl,
            imageName: 'preview'
          })
        }
      }
    }

    const doneEditingNote = () => {
      state.isClosingNote = true
      state.editor?.setEditable(false)
      state.editor?.destroy()
      const uploadNotePreviewImage = saveNotePreviewImage()
      if (papersetId && state.status !== 'noChange') {
        emit('done', { id: props.noteId, title: state.title, uploadNotePreviewImage })
      } else {
        emit('done', { id: props.noteId, title: state.title })
      }
      state.status = 'noChange'
    }

    watch(
      () => state.title,
      savePaperNoteTitle
    )

    return {
      ...toRefs(state),
      doneEditingNote,
      textEditorRef,
      noteEditorContentRef,
      savePaperNoteTitle,
      updateNoteContentFromServer
    }
  }
})
