
import { defineComponent, reactive, computed, onBeforeMount, toRefs, ref } from '@vue/composition-api'
import { Paperset } from '@/models'

type PapersetInfoState = {
  valid: boolean
  modifiedInfo: Partial<Paperset>
  coverImage?: string
  fileReader: FileReader
  imagePreview: boolean
  info: Partial<Paperset>
}

enum Mode {
  READ = 'Read',
  EDIT = 'Edit'
}

export default defineComponent({
  name: 'PapersetInfo',
  props: {
    paperset: { type: Object as () => Paperset, default: () => undefined },
    actionName: { type: String, default: '' },
    initMode: { type: String as () => Mode, default: Mode.READ }
  },
  setup (props, { emit }) {
    const state: PapersetInfoState = reactive({
      valid: true,
      // imageRules: [
      //   value => value === null || value.size < 5000000 || 'image size should be less than 5 MB!',
      // ],
      modifiedInfo: {},
      coverImage: undefined,
      fileReader: new FileReader(),
      imagePreview: false,
      info: computed({
        get (): Partial<Paperset> {
          if (props.paperset) {
            return {
              name: props.paperset.name,
              description: props.paperset.description,
              public: props.paperset.public,
              cover: props.paperset.cover
            }
          }
          return { name: '', description: '', public: false }
        },
        set (val: Partial<Paperset>): void {
          Object.assign(state.modifiedInfo, val)
        }
      }),
      mode: props.initMode
    })

    const rules = {
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length <= 36 || 'Name must be less than 36 characters'
      ],
      descriptionRules: [(v: string) => v.length <= 256 || 'Name must be less than 256 characters']
    }

    const ImageCanvas = ref<HTMLCanvasElement|undefined>(undefined)
    const form = ref<HTMLFormElement|undefined>(undefined)
    const methods = {
      callAction () {
        if (form.value?.validate()) {
          const papersetInfo = Object.assign({}, state.info)
          if (state.coverImage) {
            papersetInfo.cover = state.coverImage
          }
          emit('action', papersetInfo)
        }
      },
      loadImageFile (file: Blob) {
        state.fileReader.readAsDataURL(file)
      },
      drawImageOnCanvas () {
        // console.log(fileData)
        const image = new Image()
        image.onload = () => {
          if (ImageCanvas?.value !== undefined) {
            ImageCanvas.value.height = 128
            ImageCanvas.value.width = image.width / image.height * 128
            const ctx = ImageCanvas.value.getContext('2d')
            if (ctx !== null) {
              ctx.drawImage(
                image, 0, 0, image.width, image.height, 0, 0, ImageCanvas.value.width, 128
              )
              state.coverImage = ImageCanvas.value.toDataURL('image/png')
            }
          }
        }
        image.src = state.fileReader.result as string
      }
    }

    onBeforeMount(() => {
      state.fileReader.onload = methods.drawImageOnCanvas
    })
    return {
      ...toRefs(state),
      ...rules,
      ...methods,
      form,
      ImageCanvas
    }
  }
})
