
import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'

import {
  PapersetId,
  PaperLabel,
  PaperLabelType,
  PaperLabelTypes,
  generateRandomId,
  PaperLabelValue
} from '@/models'

import { PapersetDB } from '@/Firebase'

const getDefaultLabelValue = () => {
  return {
    id: '',
    text: '',
    description: ''
  } as PaperLabelValue
}

const getDefaulLabelProps = () => {
  return {
    id: '',
    name: '',
    type: PaperLabelType.SingleTag,
    value: [getDefaultLabelValue()]
  }
}

export default defineComponent({
  name: 'LabelForm',
  props: {
    label: { type: Object as () => PaperLabel, required: true },
    papersetId: { type: String as () => PapersetId, required: true },
    canEdit: Boolean
  },

  setup (props, { emit }) {
    const state = reactive({
      _label: (props.label.id) ? props.label : getDefaulLabelProps()
    })

    const methods = {
      saveLabel () {
        // new label does not have id
        if (!state._label.id) {
          state._label.id = generateRandomId()
        }

        state._label.value?.forEach(value => {
          if (!value.id) {
            value.id = generateRandomId()
          }
          if (!value.text && state._label.name) {
            value.text = state._label.name
          }
          if (
            state._label.type === PaperLabelType.SingleTag &&
            value.text !== state._label.name
          ) {
            value.text = state._label.name
          }
        })

        const paperLabels: Record<string, PaperLabel> = {}
        paperLabels[`paperLabels.${state._label.id}`] = state._label as PaperLabel
        PapersetDB.update(props.papersetId, paperLabels)
        emit('save', state._label)
      },

      addLabelValue () {
        const newLabelValue = getDefaultLabelValue()
        newLabelValue.id = generateRandomId()
        state._label.value?.push(newLabelValue)
      }
    }

    watch(
      () => props.label,
      () => {
        state._label = (props.label.id) ? props.label : getDefaulLabelProps()
      }
    )

    return { ...toRefs(state), ...methods, PaperLabelType, PaperLabelTypes }
  }
})
