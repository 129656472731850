/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: Convert to Typescript
import * as pdfjsLib from 'pdfjs-dist/build/pdf.js'
import { normalize } from '@/utils/Sentences'
// import RefParser from './RefParser'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.js'
// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
// eslint-disable-next-line import/no-webpack-loader-syntax
import PdfjsWorker from 'worker-loader!pdfjs-dist/build/pdf.worker.js'
pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker()
/**
 * Get all references
 */
export function getReferenceItems (paperTextItems) {
  const pageTotal = paperTextItems.length
  let refStart = false
  const refTexts = []
  // search paper from last page to
  for (let i = pageTotal - 1; i >= 0; i--) {
    const texts = paperTextItems[i]
    let partialRefText = texts
    for (const [index, item] of texts.entries()) {
      if (
        item.str.toLowerCase() === 'eferences' ||
        item.str.toLowerCase() === 'references' ||
        item.str.match(/^\d+.*references$/i)
      ) {
        partialRefText = texts.slice(index + 1)
        refStart = true
        break
      }
    }
    refTexts.push(partialRefText)
    if (refStart) {
      break
    }
  }
  let refText = []
  refTexts.reverse().forEach((pageText) => {
    refText = refText.concat(pageText)
  })
  return refText
  // return this.refParser.useAnystyle(refText.map(r => r.str))
}

export function parseKeywords (keywordStr) {
  const delimiters = []
  const matchPattern = keywordStr.match(/(:|;|,|—|<br \/>)/g)
  if (!matchPattern) {
    return []
  }

  matchPattern.forEach((c) => {
    const dx = delimiters.map((d) => d.char).indexOf(c)
    if (dx === -1) {
      delimiters.push({ char: c, count: 1 })
    } else {
      delimiters[dx].count++
    }
  })
  const delimiter = delimiters.sort((a, b) => b.count - a.count)[0].char
  const keywords = keywordStr
    .split(delimiter)
    .map((k) => k.trim().toLowerCase())
    .map((ks) => (ks.length > 64 ? ks.slice(0, 64) : ks))
    .filter((k) => k.length > 2)
    .map((k) =>
      normalize(
        k
          .replace(/([^\w\d,\- ])/g, '')
          .split(' ')
          .filter((w) => w.trim().length > 1)
          .join(' ')
      )
    )

  const numKeywords = keywords.length
  let lastKeyword = keywords[numKeywords - 1]
  if (lastKeyword.length > 60) {
    lastKeyword = lastKeyword.slice(0, 60)
  }
  keywords[numKeywords - 1] = lastKeyword.replace(/\d$/g, '')
  return keywords
}

export function parseAbstractAndKeywords (pageItems) {
  let abstract = []
  let keywords = []
  let abstractStart = false
  let keywordStart = false
  for (let i = 0; i < pageItems.length; i++) {
    const item = pageItems[i]
    if (item.str.match(/ntroduction$/i)) {
      break
    }
    if (keywordStart && item.str.length > 2) {
      keywords.push(item.str)
    }
    if (
      item.str.match(/k\s*ey\s*words:?/i) ||
      item.str.match(/^Index\s*Terms$/i)
    ) {
      abstractStart = false
      keywordStart = true
    }
    if (abstractStart) {
      abstract.push(item.str)
    }
    if (item.str.match(/a\s*bstract:?/i)) {
      abstractStart = true
    }
  }
  abstract = abstract
    .join(' ')
    .replace(/\s+/g, ' ')
    .replace(/([^\w\d,\-'. ])/g, '')
  let keywordStr = keywords.join('')
  if (keywordStr.length > 256) {
    const findStop = keywordStr.match(/\d+/)
    if (findStop && findStop.index) {
      keywordStr = keywordStr.slice(0, findStop.index)
    } else {
      keywordStr = keywordStr.slice(0, 256)
    }
  }
  keywords = parseKeywords(keywordStr)
  return { abstract, keywords }
}

export function parseTitleAndAuthors (pageItems) {
  const MIN_TITLE_LENGTH = 10
  const MIN_NAME_LENGTH = 5
  const MAX_ROWS_PARSE = 30
  const topRows = pageItems
    .slice(0, MAX_ROWS_PARSE)
    .filter((item) => item.str.length > MIN_TITLE_LENGTH)
  const largestText = Math.max(...topRows.map((item) => item.height))
  const titleTextItems = topRows.filter((item) => item.height === largestText)
  const title = titleTextItems.map((item) => item.str).join(' ')
  const titleRowIndex = pageItems
    .slice(0, MAX_ROWS_PARSE)
    .map((item) => item.height)
    .indexOf(largestText)

  let authorRowIndex = titleRowIndex + titleTextItems.length
  let authors = pageItems[authorRowIndex].str
  while (authors.length < MIN_NAME_LENGTH) {
    authorRowIndex++
    authors = pageItems[authorRowIndex].str
  }
  authors = authors.replace(' and ', ',').replace(/,\s*,/, ',').split(',')
  return { title, authors }
}

/**
 * Create a PDFLoader to load and parse info from a scholar paper in a PDF file
 */
export default class PdfParser {
  constructor (pdfUrl, isBase64 = false) {
    this.pdf = null
    this.pdfUrl = pdfUrl
    // this.refParser = new RefParser()
    this.pages = []
    this.isBase64 = isBase64
    this.getReferenceItems = getReferenceItems
  }

  async load () {
    if (this.pdf === null) {
      if (this.isBase64) {
        this.pdf = await pdfjsLib.getDocument({ data: this.pdfUrl }).promise
      } else {
        this.pdf = await pdfjsLib.getDocument({ url: this.pdfUrl }).promise
      }
    }
    return this.pdf
  }

  /**
   * Get paper info from PDF metadata. If no info was provided
   * in metadata, parse pdf content to get the info.
   */
  async getInfo () {
    return this.load()
      .then((pdf) => {
        return pdf.getMetadata()
      })
      .then((md) => {
        const info = md.info
        const year = info.CreationDate ? info.CreationDate.slice(2, 6) : 'unknown'
        const authors = info.Author
          ? info.Author.replace(' and ', ',').split(',')
          : false
        const title = info.Title && info.Title.length > 10 ? info.Title : false
        if (authors && title) {
          return this.parseInfo({ year, authors, title })
        } else {
          return this.parseInfo({ year })
        }
      })
  }

  getPage (pageNumber) {
    return new Promise((resolve, reject) => {
      if (this.pages[pageNumber]) {
        resolve(this.pages[pageNumber])
      } else {
        this.load()
          .then((pdf) => {
            pdf.getPage(pageNumber).then((page) => {
              this.pages[pageNumber] = page
              resolve(page)
            })
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  /**
   * parse PDF content to get paper info (title, author, etc.,)
   */
  async parseInfo (info) {
    const pdf = await this.load()
    const pdfText = await pdf.getPage(1)
    const content = await pdfText.getTextContent()
    const items = content.items
    let paperInfo = {}
    if (info.title && info.authors) {
      paperInfo = Object.assign(paperInfo, info)
      // paperInfo.authors = info.authors.map(paperAuthor => {
      //   let author = paperAuthor
      //   if (typeof author === 'string') {
      //     let names = paperAuthor.split(' ')
      //     author = {given: names.slice(0, -1), family: names.slice(-1)[0]}
      //   }
      //   return {
      //     given: Array.isArray(author.given) ? author.given.join(' ') : author.given,
      //     family: author.family
      //   }
      // })
    } else {
      paperInfo = parseTitleAndAuthors(items)
    }
    paperInfo.year = info.year
    paperInfo = Object.assign(paperInfo, parseAbstractAndKeywords(items))
    return paperInfo
  }

  async getPaperTextItems () {
    const pdfDocument = await this.load()
    const pageTotal = pdfDocument.numPages
    const texts = new Array(pageTotal)
    for (let i = 0; i < pageTotal; i++) {
      const page = await pdfDocument.getPage(i + 1)
      const text = await page.getTextContent()
      texts[i] = text.items
    }
    return texts
  }

  /**
   * Remove or replace phrases that prevent references to be parsed correctly
   * @param {Array of pdfText Objects} texts
   */
  sanitizeUrl (urlString) {
    return urlString
      .replace(/\[.+\]/, '')
      .replace(/'/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace('- ', '')
      .replace('&', '')
  }

  sanitize (texts) {
    return texts.map((item) => item.str.replace(/\[(O|o)nline.*\]/g, 'online'))
  }

  async getPageThumbnail (pageNumber = 1) {
    const pdf = await this.load()
    const page = await pdf.getPage(pageNumber)
    const vp = page.getViewport({ scale: 1 })
    const canvas = document.createElement('canvas')
    canvas.width = 80
    canvas.height = (vp.height / vp.width) * canvas.width
    const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height)
    return page
      .render({
        canvasContext: canvas.getContext('2d'),
        viewport: page.getViewport({ scale })
      })
      .promise.then(() => {
        return canvas
      })
  }

  async getFileData () {
    const pdf = await this.load()
    return pdf.getData()
  }
}
