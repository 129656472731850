
import { defineComponent, reactive, computed, onBeforeMount, toRefs, watch, onMounted } from '@vue/composition-api'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import AppBar from '@/components/AppBar'
import Sidebar from '@/components/Sidebar.vue'
import PaperList from '@/components/PaperList.vue'
import HighlightPanel from '@/components/HighlightPanel.vue'
import MarkerList from '@/components/MarkerList.vue'
import Collaborators from '@/components/Collaborators.vue'
import PapersetInfoEditForm from '@/components/PapersetInfoEditForm.vue'
import PaperCopier from '@/components/PaperCopier.vue'
// import NoteEditor from '@/components/NoteEditor'
// import NotePad from '@/InterNote/NotePad.vue'
import LabelList from '@/components/LabelList.vue'
import NoteList from '@/components/Notes/NoteList.vue'
import { getUserAppSetting, saveUserAppSetting, updateUserAppSetting } from '@/IndexedDB'
import { UserProfileDB, PapersetDB } from '@/Firebase'
import AppFooter from '@/components/AppFooter.vue'

import Viewer from '@/components/Viewer.vue'
import ViewerToggleButton from '@/components/ViewerToggleButton.vue'
import debounce from 'lodash.debounce'

import {
  UserProfile,
  PapersetId,
  Paperset,
  Annotation,
  HighlightMarker,
  Paper,
  // NoteMention,
  PaperLabel,
  PaperLabelId,
  Collaborator,
  UserInfo,
  Invitation
} from '@/models'

import { getRouteQueryString } from '@/utils/route'

export default defineComponent({
  name: 'PapersetMainView',
  components: {
    AppBar,
    Sidebar,
    PapersetInfoEditForm,
    Collaborators,
    PaperList,
    PaperCopier,
    HighlightPanel,
    LabelList,
    MarkerList,
    NoteList,
    // NotePad,
    AppFooter,
    Splitpanes,
    Pane,
    Viewer,
    ViewerToggleButton
    // NoteEditor
  },
  setup (props, { root, refs }) {
    const tabs = [
      { id: 'papers', name: 'Papers', icon: 'mdi-file-document-multiple' },
      { id: 'highlights', name: 'Highlights', icon: 'mdi-marker' },
      { id: 'notes', name: 'Notes', icon: 'mdi-comment-edit' }
      // { id: 'people', name: 'People', icon: 'mdi-account-multiple-outline' }
    ]

    type ViewStates = {
      isShowingSidebarMenu: boolean
      isShowingPaperCopier: boolean
      isShowingPdfViewer: boolean
      isShowingNotePad: boolean
      isShowingPaperList: boolean

      isOwner?: boolean
      canEdit?: boolean
      canRead?: boolean

      paneHeight: number
      mainPaneWidthPercent: number
      viewerWidthPercent: number
      tab: string
      userProfile: UserProfile | null
      papersets: Partial<Paperset>[]
      paperset: Partial<Paperset>
      papersetId?: PapersetId
      highlights: Annotation[]
      selectedPaper?: Paper
      labels: PaperLabel[]
      collaborators: (Partial<UserInfo> & Collaborator)[]

      activeTab: number
      paperCount: number
      papersetMarkerColors: string[]
      papersToMention: Paper[]
      confirmDeletePaperset: string
      isShowingCollaboratorDialog: boolean
      selectedHighlightId?: string

    }

    const state: ViewStates = reactive({
      isShowingSidebarMenu: true,
      isShowingPaperCopier: false,
      isShowingPdfViewer: false,
      isShowingPaperList: true,
      isShowingNotePad: true,

      paneHeight: 600,
      mainPaneWidthPercent: 60,
      viewerWidthPercent: 50,
      tab: root.$route.params.tab ?? 'papers',
      userProfile: null,
      papersetId: undefined,
      paperset: {},
      selectedPaper: undefined,
      papersets: [],
      references: [],
      comments: [],
      highlights: [],
      activeTab: tabs.map(t => t.id).indexOf(root.$route.params.tab) || 0,

      isOwner: computed(() => {
        return state.userProfile !== null &&
          state.userProfile.email === state.paperset.owner
      }),
      canEdit: computed(() => {
        if (state.userProfile) {
          const userEmail = state.userProfile.email
          return state.paperset.owner === userEmail ||
            (Array.isArray(state.paperset.editors) &&
              state.paperset.editors.indexOf(userEmail) !== -1)
        }
        return false
      }),
      canRead: computed(() => {
        const userEmail = state.userProfile?.email
        if (
          userEmail !== undefined &&
          state.paperset !== undefined &&
          state.paperset.readers !== undefined
        ) {
          return state.canEdit || state.paperset.readers.indexOf(userEmail) !== -1
        } else {
          return false
        }
      }),

      paperCount: computed(() => (
        state.paperset?.paperIds) ? state.paperset.paperIds.length : 0
      ),

      papersetMarkerColors: computed(() => {
        if (state.paperset === undefined || !Array.isArray(state.paperset.markers)) {
          return []
        } else {
          return state.paperset.markers.map(m => m.color)
        }
      }),

      papersToMention: computed(() => {
        if (state.paperset?.papers) {
          return Object.values(state.paperset.papers)
        }
        return []
      }),
      confirmDeletePaperset: '',
      labels: [],
      // labels: computed(() =>
      //   state.paperset.paperLabels
      //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
      //     ? Object.entries(state.paperset.paperLabels).map(([labelId, label]) => {
      //       return label as PaperLabel
      //     }).filter(label => !label.isDeleted)
      //     : []
      // )
      isShowingCollaboratorDialog: false,
      collaborators: [],

      selectedHighlightId: undefined
    })

    const fetchCollaboratorInfo = async (paperset: Partial<Paperset>) => {
      let collaboratorEmails: string[] = []
      let collaboratorsInfo: UserInfo[] = []
      if (state.userProfile !== null && state.userProfile.email === paperset.owner) {
        const { email, id, photoURL, displayName } = state.userProfile
        collaboratorsInfo = [
          { uid: id, email, photo: photoURL, name: displayName }
        ]
      } else {
        if (paperset.owner) {
          collaboratorEmails = [paperset.owner]
        }
      }

      if (paperset.editors?.length) {
        collaboratorEmails = collaboratorEmails.concat(paperset.editors)
      }

      if (paperset.readers?.length) {
        collaboratorEmails = collaboratorEmails.concat(paperset.readers)
      }

      if (collaboratorEmails.length > 0) {
        const _collaboratorsInfo = await UserProfileDB.getUserInfoByEmails(
          collaboratorEmails
        )

        return collaboratorsInfo.concat(_collaboratorsInfo)
      }
      return collaboratorsInfo
    }

    const loadPaperset = async (papersetId?: PapersetId) => {
      if (papersetId) state.papersetId = papersetId
      state.isShowingPaperList = true
      if (state.papersetId === undefined) return
      if (state.papersetId !== state.paperset.id) {
        let paperset = state.papersets.find(c => c.id === state.papersetId)
        if (
          paperset === undefined ||
          (paperset !== undefined && !paperset.fetched)
        ) {
          const papersetInfo = await PapersetDB.get(state.papersetId)
          if (paperset === undefined) {
            paperset = {}
          }
          paperset.fetched = true
          Object.assign(paperset, papersetInfo)
          if (state.userProfile && papersetInfo !== undefined) {
            const updatedProps = UserProfileDB.updatePapersetInfo(
              state.userProfile,
              papersetInfo
            )
            const cid = state.papersets.map(c => c.id).indexOf(papersetInfo.id)
            if (Object.keys(updatedProps).length > 0 && cid !== -1) {
              Object.assign(state.papersets[cid], papersetInfo)
            }
          }
          // if (paperset.peopleInfo === undefined) {
          paperset.peopleInfo = await fetchCollaboratorInfo(paperset)
          // }
        }

        // if (!state.isShowingPdfViewer) {
        //   state.selectedPaper = undefined
        // }

        if (paperset !== undefined) {
          state.paperset = paperset
          if (state.paperset.name !== undefined) {
            if (state.paperset.id) {
              document.title = 'PaperSet - ' + state.paperset.name
            }
          }

          if (paperset.annotations !== undefined) {
            let annotations: Annotation[] = []

            Object.values(paperset.annotations).forEach((paperAnnotations) => {
              annotations = annotations.concat(
                Object.entries(paperAnnotations).map(([id, annotation]) => {
                  return Object.assign(annotation, { id })
                })
              )
            })

            const highlights = annotations.filter(ann => ann.type === 'highlight')
            highlights.sort((a, b) => b.pinned === a.pinned ? 0 : b.pinned ? -1 : 1)
            state.highlights = highlights
          }
        }

        const viewingPaperId = root.$route.query.vpid as string
        if (viewingPaperId) {
          const papers = state.paperset?.papers
          if (papers && viewingPaperId !== null) {
            state.selectedPaper = papers[viewingPaperId]
            await root.$nextTick()
            state.isShowingPdfViewer = true
          }
        }
        if (state.userProfile?.id) {
          updateUserAppSetting(
            state.userProfile.id,
            { papersetIdLastVisited: state.papersetId })
        }

        const highlightIdFromUrl = root.$route.query.vhid as string
        if (highlightIdFromUrl) {
          const highlight = state.highlights.find(h => h.id === highlightIdFromUrl)
          if (highlight) {
            methods.selectHighlight(highlight)
          }
        }
      }
    }

    const onNewPaperset = async (newPaperset: Paperset) => {
      const newPapersetInfo = await PapersetDB.get(newPaperset.id)
      if (newPapersetInfo !== undefined) {
        state.papersets.push(newPapersetInfo)
        state.papersets.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        root.$router.push('/s/' + newPaperset.id)
      }
    }

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const appBar: any = refs.AppBar
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const appFooter: any = refs.AppFooter
      state.paneHeight = window.innerHeight - appBar.$el.clientHeight -
        appFooter.$el.clientHeight - 50

      const resizePane = debounce(() => {
        state.paneHeight = window.innerHeight - appBar.$el.clientHeight -
          appFooter.$el.clientHeight - 50
        // state.viewerWidthPercent = 100
      }, 500)
      window.onresize = resizePane
    })

    onBeforeMount(async () => {
      state.userProfile = await UserProfileDB.getProfile()
      root.$store.dispatch('setUserProfile', state.userProfile)
      if (state.userProfile !== null) {
        const papersets = Object.values(state.userProfile.papersets) as Paperset[]
        papersets.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        for (const paperset of papersets) {
          const colInfo: Partial<Paperset> = paperset
          state.papersets.push({ fetched: false, ...colInfo })
        }
        if (root.$route.params.papersetId === undefined) {
          const userAppSetting = await getUserAppSetting(state.userProfile.id)
          if (!userAppSetting) {
            await saveUserAppSetting({
              userId: state.userProfile.id
            })
          }
          const { paperIdLastViewed, papersetIdLastVisited } = userAppSetting ?? {}
          const papersetId = papersetIdLastVisited ?? papersets[0]?.id
          let routeURL = '/s/' + papersetId
          if (paperIdLastViewed) {
            routeURL += `?vpid=${paperIdLastViewed}`
          }
          root.$router.push(routeURL)
        } else {
          state.papersetId = root.$route.params.papersetId
        }
      }
      if (state.papersetId) {
        root.$store.dispatch('setPapersetId', state.papersetId)
      }
    })

    const methods = {
      addMarker (marker: HighlightMarker) {
        if (state.papersetId !== undefined) {
          return PapersetDB.addArrayValue(state.papersetId, 'markers', marker)
        }
      },

      updateMarker (markers: HighlightMarker) {
        if (state.papersetId !== undefined) {
          PapersetDB.update(state.papersetId, { markers })
        }
      },

      async updatePapersetInfo (papersetInfo: Record<string, Partial<unknown>>) {
        if (state.papersetId === undefined) return
        if (state.paperset.cover !== papersetInfo.cover) {
          // FIXME:
          // const coverImage = await uploadPapersetCoverImage(state.papersetId, papersetInfo.cover)
          // papersetInfo.cover = await coverImage.ref.getDownloadURL()
        }
        state.paperset = Object.assign(state.paperset, papersetInfo)

        return PapersetDB.update(state.papersetId, papersetInfo)
      },

      selectHighlight (highlight: Annotation) {
        state.selectedHighlightId = highlight.id
        if (
          state.paperset.papers !== undefined &&
          state.selectedPaper?.id !== highlight.paperId
        ) {
          state.selectedPaper = state.paperset.papers[highlight.paperId]
          methods.openPdfViewerPane()
        }
      },

      pinHighlight (highlight: Annotation) {
        if (state.papersetId !== undefined) {
          highlight.pinned = !highlight.pinned
          PapersetDB.updateAnnotation(state.papersetId, highlight.paperId, highlight)
        }
      },

      copyPaper (paper: Paper) {
        state.selectedPaper = paper
        state.isShowingPaperCopier = true
      },

      // linkNoteMention (noteMention: NoteMention) {
      //   console.log(noteMention)
      //   if (noteMention.type === 'paperset') {
      //     state.papersetId = noteMention.id as PapersetId
      //   }
      // },

      addPaperLabel (paperLabel: PaperLabel) {
        state.labels.push(paperLabel)
        // if (state.paperset.paperLabels !== undefined) {
        //   (state.paperset.paperLabels as Record<string, PaperLabel>)[paperLabel.id] = paperLabel
        // }
      },

      deletePaperLabel (paperLabelId: PaperLabelId) {
        state.labels = state.labels.filter(label => label.id !== paperLabelId)
        // console.log(paperLabelId)
        // if (state.paperset.paperLabels !== undefined) {
        //   state.paperset.paperLabels = {}
        //   // FIXME: loop through object and delete the record
        // }
      },

      addHighlight (highlight: Annotation) {
        state.highlights.push(highlight)
      },

      acceptInvitation: async (invitation: Invitation) => {
        await UserProfileDB.acceptInvitation(invitation)
        if (invitation.paperset.id !== undefined && state.userProfile !== null) {
          const papersetData = await PapersetDB.get(invitation.paperset.id)
          if (papersetData !== undefined) {
            const paperset = Object.assign({ canEdit: invitation.permission === 'can edit' }, papersetData)
            state.papersets.push(paperset)
          }

          state.userProfile.invitations = state.userProfile.invitations?.filter(
            inv => inv.paperset.id !== invitation.paperset.id
          )
        }
      },

      declineInvitation: (invitation: Invitation) => {
        UserProfileDB.declineInvitation(invitation)
        if (state.userProfile !== null) {
          state.userProfile.invitations = state.userProfile.invitations?.filter(
            inv => inv.paperset.id !== invitation.paperset.id
          )
        }
      },

      async deletePaperset () {
        if (state.papersetId !== undefined) {
          const deletePaperPromises: Promise<void>[] = []
          if (Array.isArray(state.paperset.papers) && state.paperset.papers.length) {
            for (const paper of state.paperset.papers) {
              deletePaperPromises.push(PapersetDB.deletePaper(state.papersetId, paper.id))
            }
          }
          if (deletePaperPromises.length > 0) {
            await Promise.all(deletePaperPromises)
          }

          await PapersetDB.delete(state.papersetId)
          root.$router.push('/s/')
        }
        // if (state.paperset.id !== undefined) {
        //   PapersetDB.update(state.paperset.id, { isDeleted: true })
        //   root.$router.push('/')
        // }
      },

      openPdfViewerPane () {
        state.isShowingPdfViewer = true
        state.mainPaneWidthPercent = 100 - state.viewerWidthPercent
      },

      closePdfViewerPane () {
        state.mainPaneWidthPercent = 100
        state.isShowingPdfViewer = false
      },

      viewPDF (paper: Paper) {
        if (root.$route.query.vpid !== paper.id) {
          root.$router.push('?vpid=' + paper.id)
          if (state.userProfile?.id) {
            updateUserAppSetting(state.userProfile.id, { paperIdLastViewed: paper.id })
          }
        }
        state.selectedPaper = paper
        methods.openPdfViewerPane()
      },

      toggleFullSizeViewer () {
        state.isShowingPaperList = !state.isShowingPaperList
        state.mainPaneWidthPercent = state.isShowingPaperList ? 100 - state.viewerWidthPercent : 0
        // state.viewerWidthPercent = 100
      },

      onPanesResized (panes: {size: number}[]) {
        if (panes[0] && panes[0].size) {
          state.mainPaneWidthPercent = panes[0].size
        }

        if (panes[1] && panes[1].size) {
          state.viewerWidthPercent = panes[1].size
          state.isShowingPdfViewer = true
        }
      }
    }

    watch(
      () => root.$route.params.papersetId,
      (papersetId) => {
        state.papersetId = papersetId
        root.$store.dispatch('setPapersetId', papersetId)
        state.activeTab = 0
      }
    )

    watch(
      () => state.papersetId,
      () => {
        loadPaperset()
      }
    )

    watch(
      () => state.activeTab,
      (activeTab, prevTab) => {
        if (
          activeTab < 0 ||
          tabs[activeTab] === undefined ||
          prevTab === undefined ||
          state.papersetId === undefined
        ) return
        state.tab = tabs[activeTab].id

        const queryString = getRouteQueryString(root.$route)
        if (activeTab !== prevTab && state.tab !== root.$route.params.tab) {
          root.$router.push(`/s/${state.papersetId}/${state.tab}?${queryString}`)
        }
      }
    )

    watch(
      () => root.$route.params.tab,
      (tab) => {
        const activeTab = tabs.map(t => t.id).indexOf(tab) || 0
        if (activeTab !== state.activeTab) {
          state.activeTab = activeTab
        }
      }
    )

    watch(
      () => state.paperset.paperLabels,
      () => {
        if (state.paperset.paperLabels !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.labels = Object.values(
            state.paperset.paperLabels
          ).map((label) => {
            return label as PaperLabel
          }).filter(label => !label.isDeleted)
        }
      }
    )

    watch(() => state.viewerWidthPercent,
      () => {
        if (state.viewerWidthPercent > 0) {
          state.isShowingPdfViewer = true
        }
      }
    )

    watch(
      () => root.$route.query,
      (query) => {
        if ('vhid' in query) {
          const highlight = state.highlights.find(h => h.id === query.vhid)
          highlight && methods.selectHighlight(highlight)
        }
      }
    )

    return {
      tabs,
      ...toRefs(state),
      ...methods,
      onNewPaperset
    }
  }
})
