/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: Convert to TypeScript and/or rewrite
/* Copyright 2012 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { getGlobalEventBus } from 'pdfjs-dist/lib/web/ui_utils'
import { renderTextLayer, PDFJSDev } from 'pdfjs-dist/lib/pdf'

const EXPAND_DIVS_TIMEOUT = 300 // ms

const CHARACTERS_TO_NORMALIZE = {
  '\u2018': '\'', // Left single quotation mark
  '\u2019': '\'', // Right single quotation mark
  '\u201A': '\'', // Single low-9 quotation mark
  '\u201B': '\'', // Single high-reversed-9 quotation mark
  '\u201C': '"', // Left double quotation mark
  '\u201D': '"', // Right double quotation mark
  '\u201E': '"', // Double low-9 quotation mark
  '\u201F': '"', // Double high-reversed-9 quotation mark
  '\u00BC': '1/4', // Vulgar fraction one quarter
  '\u00BD': '1/2', // Vulgar fraction one half
  '\u00BE': '3/4' // Vulgar fraction three quarters
}

function normalize (text) {
  // Compile the regular expression for text normalization once.
  const replace = Object.keys(CHARACTERS_TO_NORMALIZE).join('')
  const normalizationRegex = new RegExp(`[${replace}]`, 'g')

  return text.replace(normalizationRegex, function (ch) {
    return CHARACTERS_TO_NORMALIZE[ch]
  })
}

/**
 * @typedef {Object} TextLayerBuilderOptions
 * @property {HTMLDivElement} textLayerDiv - The text layer container.
 * @property {EventBus} eventBus - The application event bus.
 * @property {number} pageIndex - The page index.
 * @property {PageViewport} viewport - The viewport of the text layer.
 * @property {PDFFindController} findController
 * @property {boolean} enhanceTextSelection - Option to turn on improved
 *   text selection.
 */

/**
 * The text layer builder provides text selection functionality for the PDF.
 * It does this by creating overlay divs over the PDF's text. These divs
 * contain text that matches the PDF text they are overlaying. This object
 * also provides a way to highlight text that is being searched for.
 */
class HighlightTextLayerBuilder {
  constructor ({
    textLayerDiv,
    eventBus,
    pageIndex,
    viewport,
    findController = null,
    enhanceTextSelection = true,
    pageContent = null,
    highlights = [],
    highlightTexts = [],
    matchWords = [],
    onHighlightSelect,
    onHighlightRenderDone
  }) {
    this.textLayerDiv = textLayerDiv
    this.eventBus = eventBus || getGlobalEventBus()
    this.textContent = null
    this.textContentItemsStr = []
    this.textContentStream = null
    this.renderingDone = false
    this.pageIdx = pageIndex
    this.pageNumber = this.pageIdx + 1
    this.matches = []
    this.viewport = viewport
    this.textDivs = []
    this.findController = findController
    this.textLayerRenderTask = null
    this.enhanceTextSelection = enhanceTextSelection
    this.highlights = highlights
    this.highlightTexts = highlightTexts
    this.onHighlightSelect = onHighlightSelect || function () { return null }
    this.onHighlightRenderDone = onHighlightRenderDone
    this.highlightedMatchWords = []
    this.matchWords = matchWords
    this.matchAll = false
    this._bindMouse()
    this.pageContent = normalize(pageContent)
  }

  /**
   * @private
   */
  _finishRendering () {
    this.renderingDone = true

    if (!this.enhanceTextSelection) {
      const endOfContent = document.createElement('div')
      endOfContent.className = 'endOfContent'
      this.textLayerDiv.appendChild(endOfContent)
    }

    this.eventBus.dispatch('textlayerrendered', {
      source: this,
      pageNumber: this.pageNumber,
      numTextDivs: this.textDivs.length
    })
  }

  /**
   * Renders the text layer.
   *
   * @param {number} timeout - (optional) wait for a specified amount of
   *                           milliseconds before rendering
   */
  render (timeout = 0) {
    if (!(this.textContent || this.textContentStream) || this.renderingDone) {
      return
    }
    this.cancel()

    this.textDivs = []
    const textLayerFrag = document.createDocumentFragment()
    this.textLayerRenderTask = renderTextLayer({
      textContent: this.textContent,
      textContentStream: this.textContentStream,
      container: textLayerFrag,
      viewport: this.viewport,
      textDivs: this.textDivs,
      textContentItemsStr: this.textContentItemsStr,
      timeout,
      enhanceTextSelection: this.enhanceTextSelection
    })
    this.textLayerRenderTask.promise.then(
      () => {
        this.textLayerDiv.appendChild(textLayerFrag)
        this._finishRendering()
        this.updateMatches()
        this.updateMatchWords()
        this.updateHighlights()

        // if (typeof this.onHighlightRenderDone === 'function') {
        //   this.onHighlightRenderDone()
        // }
      },
      function (reason) {
        // Cancelled or failed to render text layer; skipping errors.
        throw new Error(reason)
      }
    )
  }

  /**
   * Cancel rendering of the text layer.
   */
  cancel () {
    if (this.textLayerRenderTask) {
      this.textLayerRenderTask.cancel()
      this.textLayerRenderTask = null
    }
  }

  setTextContentStream (readableStream) {
    this.cancel()
    this.textContentStream = readableStream
  }

  setTextContent (textContent) {
    this.cancel()
    this.textContent = textContent
  }

  convertMatches (matches, matchesLength) {
    let i = 0
    let iIndex = 0
    const textContentItemsStr = this.textContentItemsStr
    const end = textContentItemsStr.length - 1
    const queryLen =
      this.findController === null || matchesLength
        ? 0
        : this.findController.state.query.length
    const ret = []
    if (!matches) {
      return ret
    }
    for (let m = 0, len = matches.length; m < len; m++) {
      // Calculate the start position.
      let matchIdx = matches[m]

      // Loop over the divIdxs.
      while (i !== end && matchIdx >= iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length
        i++
      }

      if (i === textContentItemsStr.length) {
        console.error('Could not find a matching mapping')
      }

      const match = {
        begin: {
          divIdx: i,
          offset: matchIdx - iIndex
        }
      }

      // Calculate the end position.
      if (matchesLength) {
        // Multiterm search.
        matchIdx += matchesLength[m]
      } else {
        // Phrase search.
        matchIdx += queryLen
      }

      // Somewhat the same array as above, but use > instead of >= to get
      // the end position right.
      while (i !== end && matchIdx > iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length
        i++
      }

      match.end = {
        divIdx: i,
        offset: matchIdx - iIndex
      }
      ret.push(match)
    }

    return ret
  }

  renderMatches (matches) {
    // Early exit if there is nothing to render.
    if (matches.length === 0) {
      return
    }

    const textContentItemsStr = this.textContentItemsStr
    const textDivs = this.textDivs
    let prevEnd = null
    const pageIdx = this.pageIdx
    const isSelectedPage =
      this.findController === null
        ? false
        : pageIdx === this.findController.selected.pageIdx
    const selectedMatchIdx =
      this.findController === null ? -1 : this.findController.selected.matchIdx
    const highlightAll =
      this.findController === null
        ? false
        : this.findController.state.highlightAll
    const infinity = {
      divIdx: -1,
      offset: undefined
    }

    function appendTextToDiv (divIdx, fromOffset, toOffset, className) {
      const div = textDivs[divIdx]
      const content = textContentItemsStr[divIdx].substring(
        fromOffset,
        toOffset
      )
      const node = document.createTextNode(content)
      if (className) {
        const span = document.createElement('span')
        span.className = className
        span.appendChild(node)
        div.appendChild(span)
        return
      }
      div.appendChild(node)
    }

    function beginText (begin, className) {
      const divIdx = begin.divIdx
      textDivs[divIdx].textContent = ''
      appendTextToDiv(divIdx, 0, begin.offset, className)
    }

    let i0 = selectedMatchIdx
    let i1 = i0 + 1
    if (highlightAll) {
      i0 = 0
      i1 = matches.length
    } else if (!isSelectedPage) {
      // Not highlighting all and this isn't the selected page, so do nothing.
      return
    }

    for (let i = i0; i < i1; i++) {
      const match = matches[i]
      const begin = match.begin
      const end = match.end
      const isSelected = isSelectedPage && i === selectedMatchIdx
      const highlightSuffix = isSelected ? ' selected' : ''

      if (this.findController) {
        this.findController.updateMatchPosition(
          pageIdx,
          i,
          textDivs,
          begin.divIdx
        )
      }

      // Match inside new div.
      if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
        // If there was a previous div, then add the text at the end.
        if (prevEnd !== null) {
          appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset)
        }
        // Clear the divs and set the content until the starting point.
        beginText(begin)
      } else {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset)
      }

      if (begin.divIdx === end.divIdx) {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          end.offset,
          'highlight' + highlightSuffix
        )
      } else {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          infinity.offset,
          'highlight begin' + highlightSuffix
        )
        for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
          textDivs[n0].className = 'highlight middle' + highlightSuffix
        }
        beginText(end, 'highlight end' + highlightSuffix)
      }
      prevEnd = end
    }

    if (prevEnd) {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset)
    }
  }

  updateMatches () {
    // Only show matches when all rendering is done.
    if (!this.renderingDone) {
      return
    }

    // Clear all matches.
    const matches = this.matches
    const textDivs = this.textDivs
    const textContentItemsStr = this.textContentItemsStr
    let clearedUntilDivIdx = -1

    // Clear all current matches.
    for (let i = 0, len = matches.length; i < len; i++) {
      const match = matches[i]
      const begin = Math.max(clearedUntilDivIdx, match.begin.divIdx)
      for (let n = begin, end = match.end.divIdx; n <= end; n++) {
        const div = textDivs[n]
        div.textContent = textContentItemsStr[n]
        div.className = ''
      }
      clearedUntilDivIdx = match.end.divIdx + 1
    }

    if (this.findController === null || !this.findController.active) {
      return
    }

    // Convert the matches on the page controller into the match format
    // used for the textLayer.
    let pageMatches, pageMatchesLength
    if (this.findController !== null) {
      pageMatches = this.findController.pageMatches[this.pageIdx] || null
      pageMatchesLength = this.findController.pageMatchesLength
        ? this.findController.pageMatchesLength[this.pageIdx] || null
        : null
    }

    this.matches = this.convertMatches(pageMatches, pageMatchesLength)
    this.renderMatches(this.matches)
  }

  renderHighlights (highlights) {
    // Early exit if there is nothing to render.
    if (highlights.length === 0) {
      return
    }

    const textContentItemsStr = this.textContentItemsStr
    const textDivs = this.textDivs
    let prevEnd = null
    const infinity = {
      divIdx: -1,
      offset: undefined
    }

    function appendTextToDiv (
      divIdx,
      fromOffset,
      toOffset,
      highlight,
      className
    ) {
      const div = textDivs[divIdx]
      const content = textContentItemsStr[divIdx].substring(
        fromOffset,
        toOffset
      )
      // if (className === 'highlight end') {
      //   console.log(content)
      // }
      const node = document.createTextNode(content)

      if (className && content.length > 0) {
        const span = document.createElement('span')
        span.className = className
        Object.assign(span.style, { backgroundColor: highlight.bgColor })
        if (highlight.id) {
          span.setAttribute('highlight-id', highlight.id)
        }
        span.appendChild(node)
        div.appendChild(span)
        return
      }
      div.appendChild(node)
    }

    function beginText (begin, className, highlight) {
      const divIdx = begin.divIdx
      textDivs[divIdx].textContent = ''
      appendTextToDiv(divIdx, 0, begin.offset, highlight, className)
    }

    const i0 = 0
    const i1 = highlights.length

    for (let i = i0; i < i1; i++) {
      const highlight = highlights[i]
      const begin = highlight.begin
      const end = highlight.end
      const highlightSuffix = ''

      // Highlight inside new div.
      if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
        // If there was a previous div, then add the text at the end.
        if (prevEnd !== null) {
          appendTextToDiv(
            prevEnd.divIdx,
            end.offset + begin.offset,
            infinity.offset,
            highlight
          )
        }
        // Clear the divs and set the content until the starting point.
        beginText(begin)
      } else {
        appendTextToDiv(
          prevEnd.divIdx,
          prevEnd.offset,
          begin.offset,
          highlight,
          'highlight'
        )
      }

      if (begin.divIdx === end.divIdx) {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          end.offset,
          highlight,
          'highlight' + highlightSuffix
        )
      } else {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          infinity.offset,
          highlight,
          'highlight begin' + highlightSuffix
        )
        for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
          textDivs[n0].className = 'highlight middle' + highlightSuffix
          Object.assign(textDivs[n0].style, {
            backgroundColor: highlight.bgColor
          })
          textDivs[n0].setAttribute('highlight-id', highlight.id)
        }
        beginText(end, 'highlight end' + highlightSuffix, highlight)
      }
      prevEnd = end
    }

    if (prevEnd) {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset)
    }

    const onHighlightSelect = this.onHighlightSelect
    document.querySelectorAll('.textLayer').forEach((tl) => {
      tl.onclick = (evt) => {
        const el = evt.target
        let highlightItems = []
        const highlightId = el.getAttribute('highlight-id')
        if (highlightId) {
          highlightItems = document.querySelectorAll(
            '.highlight[highlight-id="' + highlightId + '"]'
          )
        }
        onHighlightSelect(el, highlightId, highlightItems)
      }
    })

    // if (typeof this.onHighlightRenderDone === 'function') {
    //   this.onHighlightRenderDone()
    // }
  }

  clearHighlights () {
    if (!this.renderingDone) {
      return
    }
    // Clear all highlights.
    const highlights = this.highlights
    const textDivs = this.textDivs
    const textContentItemsStr = this.textContentItemsStr
    let clearedUntilDivIdx = -1
    // Clear all current highlights.
    for (let i = 0, len = highlights.length; i < len; i++) {
      const highlight = highlights[i]
      const begin = Math.max(clearedUntilDivIdx, highlight.begin.divIdx)
      for (let n = begin, end = highlight.end.divIdx; n <= end; n++) {
        const div = textDivs[n]
        div.textContent = textContentItemsStr[n]
        div.className = ''
        div.style.backgroundColor = null
      }
      clearedUntilDivIdx = highlight.end.divIdx + 1
    }
    this.highlights = []
  }

  updateHighlights () {
    this.clearHighlights()
    this.highlightTexts.forEach((ht) => this.addHighlight(ht))
  }

  async addHighlight ({ text, bgColor, id }) {
    // Only show highlights when all rendering is done.
    if (!this.renderingDone) {
      return
    }

    // get highlight text matches and convert to divIdx and offset
    const pageContent = this.pageContent

    if (pageContent === null) return
    let matchPageOffset
    let matchLength
    let matchColor
    let matchId

    // const offset = pageContent.indexOf()
    const offset = pageContent.indexOf(normalize(text))
    if (offset !== -1) {
      matchPageOffset = offset
      matchLength = text.length
      matchColor = bgColor
      matchId = id
    }

    const matches = this.convertMatches([matchPageOffset], [matchLength])
    const highlights = matches.map((match) => ({
      ...match,
      bgColor: matchColor,
      id: matchId
    }))

    this.highlights = this.highlights.concat(highlights)

    this.renderHighlights(this.highlights)
  }

  clearMatchWords () {
    if (!this.renderingDone) {
      return
    }
    // Clear all highlights.
    const highlights = this.highlightedMatchWords
    const textDivs = this.textDivs
    const textContentItemsStr = this.textContentItemsStr
    let clearedUntilDivIdx = -1
    // Clear all current highlights.
    for (let i = 0, len = highlights.length; i < len; i++) {
      const highlight = highlights[i]
      const begin = Math.max(clearedUntilDivIdx, highlight.begin.divIdx)
      for (let n = begin, end = highlight.end.divIdx; n <= end; n++) {
        const div = textDivs[n]
        div.textContent = textContentItemsStr[n]
        div.className = div.className.replace('matched-word', '')
        div.style.border = 'none'
      }
      clearedUntilDivIdx = highlight.end.divIdx + 1
    }
    this.highlightedMatchWords = []

    // Workaround for fixing the issue that the first few matched words
    // sometimes cannot be cleared
    if (!this.matchWords) {
      document.querySelectorAll('.matched-word').forEach((w) => {
        w.style.border = 'none'
        w.className = w.className.replace('matched-word', '')
      })
    } else {
      const words = this.matchWords.map((w) => w.term)
      document.querySelectorAll('.matched-word').forEach((w) => {
        if (words.indexOf(w.innerText) === -1) {
          w.style.border = 'none'
          w.className = w.className.replace('matched-word', '')
        }
      })
    }
  }

  async highlightMatchWords ({ term, color }) {
    // Only show matches when all rendering is done.
    if (!this.renderingDone) {
      return
    }
    // Convert the matches on the page controller into the match format
    // used for the textLayer.
    const pageContent = this.pageContent

    if (pageContent === null) return
    let matchPageOffsets = []
    let matchLengths = []
    let matchColors = []

    // this.matchWords.forEach(({term, color}) => {
    const regex = new RegExp(term, 'ig')
    let matches = [...pageContent.matchAll(regex)]
    matchPageOffsets = matchPageOffsets.concat(matches.map((m) => m.index))
    matchLengths = matchLengths.concat(matches.map((m) => m[0].length))
    matchColors = matchColors.concat(new Array(matches.length).fill(color))

    matches = this.convertMatches(matchPageOffsets, matchLengths)
    const matchedWords = matches.map((match, index) => ({
      ...match,
      bgColor: matchColors[index]
    }))
    this.highlightedMatchWords = this.highlightedMatchWords.concat(
      matchedWords
    )
    // });

    this.renderMatchWords(this.highlightedMatchWords)
  }

  updateMatchWords () {
    if (!this.renderingDone) {
      return
    }
    if (this.highlightedMatchWords.length > 1) {
      this.clearMatchWords()
    }
    // if (Array.isArray(this.matchWords)) {
    //   this.highlightMatchWords();
    // }
    if (Array.isArray(this.matchWords)) {
      this.matchWords.forEach((word) => this.highlightMatchWords(word))
    }
  }

  renderMatchWords (matches) {
    // Early exit if there is nothing to render.
    if (matches.length === 0) {
      return
    }

    const textContentItemsStr = this.textContentItemsStr
    const textDivs = this.textDivs
    let prevEnd = null
    const infinity = {
      divIdx: -1,
      offset: undefined
    }

    function appendTextToDiv (divIdx, fromOffset, toOffset, className) {
      const div = textDivs[divIdx]
      const content = textContentItemsStr[divIdx].substring(
        fromOffset,
        toOffset
      )
      const node = document.createTextNode(content)

      if (className && content.length > 0) {
        const span = document.createElement('span')
        span.className = 'matched-word'
        span.style.border = '3px solid #3498db'
        span.style.opacity = 0.5
        span.appendChild(node)
        div.appendChild(span)
        return
      }
      div.appendChild(node)
    }

    function beginText (begin, className) {
      const divIdx = begin.divIdx
      textDivs[divIdx].textContent = ''
      appendTextToDiv(divIdx, 0, begin.offset, className)
    }

    const i0 = 0
    const i1 = matches.length

    for (let i = i0; i < i1; i++) {
      const match = matches[i]
      const begin = match.begin
      const end = match.end
      const highlightSuffix = ''

      const divIdx = begin.divIdx
      let hasHighlight = false
      textDivs[divIdx].childNodes.forEach((c) => {
        if (c.className && c.className.includes('highlight')) {
          hasHighlight = true
        }
      })
      if (hasHighlight) {
        continue
      }

      // Match inside new div.
      if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
        // If there was a previous div, then add the text at the end.
        if (prevEnd !== null) {
          appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset)
        }
        // Clear the divs and set the content until the starting point.
        beginText(begin)
      } else {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset)
      }

      if (begin.divIdx === end.divIdx) {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          end.offset,
          'highlight' + highlightSuffix
        )
      } else {
        appendTextToDiv(
          begin.divIdx,
          begin.offset,
          infinity.offset,
          'highlight begin' + highlightSuffix
        )
        for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
          textDivs[n0].className = 'highlight middle'
        }
        beginText(end, 'highlight end' + highlightSuffix)
      }
      prevEnd = end
    }

    if (prevEnd) {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset)
    }
  }

  /**
   * Improves text selection by adding an additional div where the mouse was
   * clicked. This reduces flickering of the content if the mouse is slowly
   * dragged up or down.
   *
   * @private
   */
  _bindMouse () {
    const div = this.textLayerDiv
    let expandDivsTimer = null

    div.addEventListener('mousedown', (evt) => {
      if (this.enhanceTextSelection && this.textLayerRenderTask) {
        this.textLayerRenderTask.expandTextDivs(true)
        if (
          (typeof PDFJSDev === 'undefined' ||
            !PDFJSDev.test('FIREFOX || MOZCENTRAL')) &&
          expandDivsTimer
        ) {
          clearTimeout(expandDivsTimer)
          expandDivsTimer = null
        }
        return
      }

      const end = div.querySelector('.endOfContent')
      if (!end) {
        return
      }
      if (
        typeof PDFJSDev === 'undefined' ||
        !PDFJSDev.test('FIREFOX || MOZCENTRAL')
      ) {
        // On non-Firefox browsers, the selection will feel better if the height
        // of the `endOfContent` div is adjusted to start at mouse click
        // location. This avoids flickering when the selection moves up.
        // However it does not work when selection is started on empty space.
        let adjustTop = evt.target !== div
        if (typeof PDFJSDev === 'undefined' || PDFJSDev.test('GENERIC')) {
          adjustTop =
            adjustTop &&
            window
              .getComputedStyle(end)
              .getPropertyValue('-moz-user-select') !== 'none'
        }
        if (adjustTop) {
          const divBounds = div.getBoundingClientRect()
          const r = Math.max(0, (evt.pageY - divBounds.top) / divBounds.height)
          end.style.top = (r * 100).toFixed(2) + '%'
        }
      }
      end.classList.add('active')
    })

    div.addEventListener('mouseup', () => {
      if (this.enhanceTextSelection && this.textLayerRenderTask) {
        if (
          typeof PDFJSDev === 'undefined' ||
          !PDFJSDev.test('FIREFOX || MOZCENTRAL')
        ) {
          expandDivsTimer = setTimeout(() => {
            if (this.textLayerRenderTask) {
              this.textLayerRenderTask.expandTextDivs(false)
            }
            expandDivsTimer = null
          }, EXPAND_DIVS_TIMEOUT)
        } else {
          this.textLayerRenderTask.expandTextDivs(false)
        }
        return
      }

      const end = div.querySelector('.endOfContent')
      if (!end) {
        return
      }
      if (
        typeof PDFJSDev === 'undefined' ||
        !PDFJSDev.test('FIREFOX || MOZCENTRAL')
      ) {
        end.style.top = ''
      }
      end.classList.remove('active')
    })
  }
}

export {
  HighlightTextLayerBuilder,
  normalize
}
