
import { defineComponent, reactive, toRefs, computed, onMounted, watch } from '@vue/composition-api'
import {
  Paper,
  PaperResource,
  Annotation,
  PaperLabel,
  PaperLabelValueId,
  PaperLabelValue,
  PapersetId
} from '@/models'
import { PapersetDB } from '@/Firebase'
import { ResourceCategories } from '@/assets/ResourceCategories'
import { showAuthorNames, showKeywords } from '@/utils/formatPaperInfo'
import PaperResources from '@/components/PaperResources.vue'
import HighlightList from '@/components/HighlightList.vue'
import LabelSelection from '@/components/LabelSelection.vue'
import PaperInfoEditor from '@/components/PaperInfoEditor.vue'
import NoteEditor, { SaveNoteStatus } from '@/components/Notes/SimpleEditor.vue'
import { domPurify } from '@/utils/dompurify'

type PaperRecordState = {
  confirmRemove: boolean
  detailTab: number
  fetchingPaperDoc: boolean
  fetchedPaperDoc: boolean
  showDetailPanel: boolean
  allowRemarkSaving: boolean
  paperRecord?: Paper
  resourceLinks: PaperResource[]
  paperRemark?: string
  labelValueIds: PaperLabelValueId[]
  labelValues: PaperLabelValue[]
  isEditingNote?: boolean
  saveNoteStatus: SaveNoteStatus
}

type PaperNote = {papersetId: PapersetId, paperId: string, content: string}

export default defineComponent({
  name: 'PaperRecord',
  components: {
    HighlightList,
    PaperResources,
    LabelSelection,
    PaperInfoEditor,
    NoteEditor
  },
  props: {
    paper: { type: Object as () => Paper, required: true },
    paperUploadProgress: { type: Number, default: undefined },
    highlights: { type: Array as () => Annotation[], default: () => [] },
    papersetId: { type: String, required: true },
    viewingPaperId: { type: String, default: undefined },
    labels: { type: Array as () => PaperLabel[], default: () => [] },
    canEdit: Boolean,
    canRead: Boolean,
    showThumbnail: Boolean,
    isRoutingToPaper: Boolean,
    selectedPaperNote: { type: Object as () => PaperNote, default: undefined }
  },

  setup (props, { emit }) {
    const state: PaperRecordState = reactive({
      confirmRemove: false,
      detailTab: -1,
      fetchingPaperDoc: false,
      fetchedPaperDoc: false,
      showDetailPanel: false,
      allowRemarkSaving: false,
      paperRecord: undefined,
      isEditingNote: false,
      resourceLinks: (props.paper.resources)
        ? Object.entries(props.paper.resources).map(([category, url]) => {
          return { category, url }
        })
        : [],
      paperRemark: props.paper?.remark || '',
      labelValueIds: props.paper.labels ?? [],
      labelValues: computed(() => {
        const labels = props.labels
          ? Object.values(props.labels).map((label) => {
            return label as PaperLabel
          }).filter(label => !label.isDeleted)
          : []

        let _labelValues: PaperLabelValue[] = []

        for (const label of labels) {
          _labelValues = _labelValues.concat(label.value)
        }

        return _labelValues.filter(value => state.labelValueIds.indexOf(value.id) !== -1)
      }),
      saveNoteStatus: 'noChange'
    })

    const methods = {
      async requestPaperInfo (tabIndex: number) {
        state.detailTab = tabIndex
        if (state.detailTab !== 2) {
          state.isEditingNote = false
        }
        if (!state.fetchedPaperDoc) {
          state.fetchingPaperDoc = true
          const paperInfo = await PapersetDB.Papers.get(props.papersetId, props.paper.id)
          if (paperInfo !== null) {
            state.paperRecord = paperInfo
          }
          state.fetchedPaperDoc = true
        }
        state.showDetailPanel = true
        state.fetchingPaperDoc = false
      },

      updateLabel (labelValueIds: PaperLabelValueId[]) {
        state.labelValueIds = labelValueIds
        PapersetDB.updatePaper(
          props.papersetId,
          props.paper.id,
          { labels: labelValueIds }
        )
      },

      hasLabel (paperLabels: string[], labelIndex: string) {
        return Array.isArray(paperLabels) && paperLabels.indexOf(labelIndex) > -1
      },

      savePaperRemark () {
        if (state.paperRemark && state.paperRemark.length <= 64) {
          state.allowRemarkSaving = false
          if (state.paperRemark !== props.paper.remark) {
            emit('updatePaper', props.paper, { remark: state.paperRemark })
          }
        }
      },

      async editPaperNote () {
        state.isEditingNote = true
        emit('editPaperNote', props.papersetId, props.paper.id, state.paperRecord?.note)
      },

      doneEditingNote (noteContent: string) {
        state.isEditingNote = false
        if (state.paperRecord) {
          state.paperRecord.note = noteContent
        }
        emit('doneEditingNote')
      },

      async updatePaperResource ({ category, url }: {category: string; url: string}) {
        const res: Record<string, string> = {}
        const field = ['resources', category].join('.')
        res[field] = url

        await PapersetDB.updatePaper(props.papersetId, props.paper.id, res)

        const rid = state.resourceLinks.map(r => r.category).indexOf(category)
        if (rid > -1) {
          if (url === null) {
            state.resourceLinks.splice(rid, 1)
          } else {
            state.resourceLinks[rid].url = url
          }
        } else {
          state.resourceLinks.push({ category, url })
        }
      },

      updatePaperInfo (paperInfo: Record<string, unknown>) {
        emit('update', props.paper.id, paperInfo)
        state.showDetailPanel = false
      }
    }

    onMounted(() => {
      if (props.isRoutingToPaper) {
        methods.requestPaperInfo(0)
        const paperDomNode = document.getElementById(`paper-${props.paper.id}`)

        if (paperDomNode) {
          const container = document.getElementById('paperset-main-panel')
          if (container) {
            const top = paperDomNode.getBoundingClientRect().top + container.scrollTop
            container.scrollTo({ top, behavior: 'smooth' })
          }
        }
      }
    })

    watch(
      () => props.isRoutingToPaper,
      () => {
        if (props.isRoutingToPaper) {
          methods.requestPaperInfo(0)
          const paperDomNode = document.getElementById(`paper-${props.paper.id}`)

          if (paperDomNode) {
            const container = document.getElementById('paperset-main-panel')
            if (container) {
              const top = paperDomNode.getBoundingClientRect().top + container.scrollTop
              container.scrollTo({ top, behavior: 'smooth' })
            }
          }
        } else {
          state.showDetailPanel = false
        }
      })

    watch(
      () => state.showDetailPanel,
      (showDetailPanel) => {
        if (!showDetailPanel) {
          state.isEditingNote = false
        }
      }
    )

    watch(
      () => state.isEditingNote,
      (isEditingNote) => {
        if (!isEditingNote) {
          emit('doneEditingNote')
        }
      }
    )

    return {
      ...toRefs(state),
      ...methods,
      showAuthorNames,
      showKeywords,
      ResourceCategories,
      domPurify
    }
  }
})

