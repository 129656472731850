<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as pdfjsLib from 'pdfjs-dist/build/pdf.js'
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'
import { PDFFindController } from 'pdfjs-dist/lib/web/pdf_find_controller.js'
import { PDFLinkService } from 'pdfjs-dist/lib/web/pdf_link_service.js'
// import { EventBus } from 'pdfjs-dist/lib/web/ui_utils.js'
import { HighlightTextLayerBuilder } from '@/utils/HighlightTextLayerBuilder.js'
// eslint-disable-next-line import/no-webpack-loader-syntax
import PdfjsWorker from 'worker-loader!pdfjs-dist/build/pdf.worker.js'
import { getCachedPaperFile } from '@/IndexedDB'
import debounce from 'lodash.debounce'
pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker()
// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

const MAX_IMAGE_SIZE = 1024 * 1024 * 32
const CMAP_URL = 'cmaps/'
const CMAP_PACKED = true
const DEFAULT_SCALE_DELTA = 1.1
const MIN_SCALE = 0.25
const MAX_SCALE = 10.0
// let DEFAULT_SCALE_VALUE = 1.0

function getSelectionBoundaryElement () {
  let range, sel
  if (document.selection) {
    range = document.selection.createRange()
    // range.collapse()
    return range.parentElement()
  } else {
    sel = window.getSelection()
    if (sel.getRangeAt) {
      if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0)
      }
    } else {
      range = document.createRange()
      range.setStart(sel.anchorNode, sel.anchorOffset)
      range.setEnd(sel.focusNode, sel.focusOffset)

      // Handle the case when the selection was selected backwards (from the end to the start in the document)
      if (range.collapsed !== sel.isCollapsed) {
        range.setStart(sel.focusNode, sel.focusOffset)
        range.setEnd(sel.anchorNode, sel.anchorOffset)
      }
    }
    if (range) {
      const firstElm = range.startContainer.nodeType === 3
        ? range.startContainer.parentNode : range.startContainer
      const lastElm = range.endContainer.nodeType === 3
        ? range.endContainer.parentNode : range.endContainer

      const parentNode = firstElm.parentNode

      const startIndex = Array.from(parentNode.children).indexOf(firstElm)
      const endIndex = Array.from(parentNode.children).indexOf(lastElm)

      return Array.from(parentNode.children).slice(startIndex, endIndex + 1)
    }
  }
}

export default {
  name: 'PDfViewer',
  props: {
    paperId: { type: String, required: true },
    pdfUrl: { type: String, default: undefined },
    matchWords: { type: Array, default: () => [] },
    highlightTexts: { type: Array, default: () => [] },
    allowLocalFile: { type: Boolean, default: false },
    marker: { type: Object, default: () => undefined },
    viewHeight: { type: Number, default: 600 },
    showControlUI: { type: Boolean, default: false },
    pageNumber: { type: Number, default: 1 },
    onPageChange: { type: Function, default: undefined },
    onPageRendered: { type: Function, default: undefined },
    onHighlightSelect: { type: Function, default: undefined },
    onHighlightRenderDone: { type: Function, default: undefined }
  },
  data: () => ({
    pdfLoadingTask: null,
    pdfDocument: null,
    pdfViewer: null,
    pdfHistory: null,
    pdfLinkService: null,
    eventBus: null,
    // pageNumber: 1,
    selectedText: '',
    isExtractedText: false,
    containerWidth: undefined
  }),
  computed: {
    pageTotal () {
      return this.pdfDocument ? this.pdfDocument.numPages : 1
    },
    loadingBar () {
      const bar = new pdfjsViewer.ProgressBar('#loadingBar', {})
      return pdfjsLib.shadow(this, 'loadingBar', bar)
    },
    viewHeightPx () {
      return this.viewHeight + 'px'
    },
    markerColor () {
      return this.marker.color || 'orange'
    }
  },
  watch: {
    pageNumber (pageNumber) {
      if (pageNumber !== this.pdfViewer.currentPageNumber) {
        this.pdfViewer.currentPageNumber = this.pageNumber
      }
    },
    pdfUrl () {
      this.open({ url: this.pdfUrl })
    },
    highlightTexts () {
      this.pdfViewer.pagesPromise.then(() => {
        for (let i = 0; i < this.pageTotal; ++i) {
          const pageView = this.pdfViewer.getPageView(i)
          if (pageView && pageView.textLayer) {
            pageView.textLayer.highlightTexts = this.highlightTexts.filter(
              t => t.pageIndex === i
            )
            pageView.textLayer.updateHighlights()
          }
        }
      })
    },
    matchWords () {
      this.pdfViewer.pagesPromise.then(() => {
        for (let i = 0; i < this.pageTotal; ++i) {
          const pageView = this.pdfViewer.getPageView(i)
          if (pageView && pageView.textLayer) {
            pageView.textLayer.matchWords = this.matchWords
            pageView.textLayer.updateMatchWords()
          }
        }
      })
    }
  },
  mounted () {
    this.l10n = pdfjsViewer.NullL10n
    this.eventBus = new pdfjsViewer.EventBus()
    const container = this.$refs.viewerContainer

    new ResizeObserver(debounce(() => {
      this.autoAdjustWidth()
    }, 100)).observe(container)

    // this.l10n.translate(container).then(() => {
    //   // Dispatch the 'localized' event on the `eventBus` once the viewer
    //   // has been fully initialized and translated.
    //   this.eventBus.dispatch("localized", { source: this });
    // });

    this.pdfLinkService = new PDFLinkService({
      eventBus: this.eventBus,
      // externalLinkTarget: 2,
      externalLinkRel: 'noopener noreferrer nofollow'
    })

    const findController = new PDFFindController({
      linkService: this.pdfLinkService,
      eventBus: this.eventBus
    })

    this.findController = findController
    this.pdfViewer = new pdfjsViewer.PDFViewer({
      container: container,
      linkService: this.pdfLinkService,
      findController: this.findController,
      eventBus: this.eventBus,
      l10n: this.l10n,
      useOnlyCssZoom: 0,
      textLayerMode: 1
    })

    this.pdfLinkService.setViewer(this.pdfViewer)
    this.pdfHistory = new pdfjsViewer.PDFHistory({
      eventBus: this.eventBus,
      linkService: this.pdfLinkService
    })
    this.pdfLinkService.setHistory(this.pdfHistory)

    this.$refs.viewerContainer.addEventListener('click', (evt) => {
      if (evt.target.className === 'textLayer' || evt.target.parentNode.className === 'textLayer') {
        const selectedText = window.getSelection().toString()
        if (selectedText.length > 0 && selectedText !== this.selectedText.text) {
          let pageIndex = evt.target.parentNode.getAttribute('data-page-number')
          if (!pageIndex && evt.target.parentNode.parentNode) {
            pageIndex = evt.target.parentNode.parentNode.getAttribute('data-page-number')
          }

          const textNodes = getSelectionBoundaryElement()
          this.selectedText = {
            pageIndex: (parseInt(pageIndex) - 1) || 0,
            text: selectedText,
            textNodes
          }
          let container = evt.target.parentNode
          const pos = { x: evt.offsetX, y: evt.offsetY }
          if (evt.target.parentNode.className === 'textLayer') {
            container = evt.target.parentNode.parentNode
            pos.x += evt.target.offsetLeft
            pos.y += evt.target.offsetTop
          }

          this.$emit('selectText', {
            container,
            pos,
            pageIndex: (parseInt(pageIndex) - 1) || 0,
            text: selectedText,
            textNodes
          })
        } else {
          this.$emit('selectText', null)
        }
      } else {
        if (!evt.target.className.includes('v-icon')) {
          this.$emit('selectText', null)
        }
      }
    })

    let ctrlDown = false
    const ctrlKey = 17
    const cmdKeyRight = 91
    const cmdKeyLeft = 93
    const cKey = 67

    this.$refs.viewerContainer.addEventListener('keydown', (e) => {
      if (!this.selectedText) return

      if (e.ctrlKey || e.cmdKey || e.keyCode === ctrlKey ||
        e.keyCode === cmdKeyLeft || e.keyCode === cmdKeyRight) {
        ctrlDown = true
      }
      if (ctrlDown && e.keyCode === cKey) {
        this.$emit('copyText', this.selectedText)
      }
    })

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === ctrlKey || e.keyCode === cmdKeyLeft ||
        e.keyCode === cmdKeyRight) {
        ctrlDown = false
      }
    })

    if (this.pdfUrl) {
      this.open({ url: this.pdfUrl })
    }
    this.eventBus.on('pagesinit', () => {
      // We can use pdfViewer now, e.g. let's change default scale.
      this.fitWidth()
    })

    this.eventBus.on('pagechanging', (evt) => {
      const page = evt.pageNumber
      this.onPageChange(page)
    }, true)

    this.eventBus.on('pagerendered', (evt) => {
      const page = evt.pageNumber
      this.onPageRendered(page)
    }, true)
  },
  methods: {
    async open (params) {
      if (this.pdfLoadingTask) {
        return this.close().then(() => {
          return this.open(params)
        })
      }

      const cachedPaper = await getCachedPaperFile(this.paperId)
      if (cachedPaper) {
        const pdfDataBuffer = await new Response(cachedPaper.fileSnapshot).arrayBuffer()
        const pdfData = new Uint8Array(pdfDataBuffer)
        this.pdfLoadingTask = pdfjsLib.getDocument({
          data: pdfData,
          maxImageSize: MAX_IMAGE_SIZE,
          cMapUrl: CMAP_URL,
          cMapPacked: CMAP_PACKED
        })
      } else {
        this.pdfLoadingTask = pdfjsLib.getDocument({
          url: params.url,
          maxImageSize: MAX_IMAGE_SIZE,
          cMapUrl: CMAP_URL,
          cMapPacked: CMAP_PACKED
        })
      }

      this.pdfLoadingTask.onProgress = (progressData) => {
        this.progress(progressData.loaded / progressData.total)
      }

      return this.pdfLoadingTask.promise.then(async (pdfDocument) => {
        const pageContents = []
        this.pdfDocument = pdfDocument

        this.pdfLinkService.setDocument(pdfDocument)

        for (let i = 0; i < this.pdfLinkService.pagesCount; i++) {
          const page = await pdfDocument.getPage(i + 1)
          const textContent = await page.getTextContent({ normalizeWhitespace: true })
          pageContents[i] = textContent.items.map(item => item.str).join('')
        }

        this.pdfViewer.createTextLayerBuilder =
          (textLayerDiv, pageIndex, viewport, enhanceTextSelection = false) => {
            return new HighlightTextLayerBuilder({
              textLayerDiv,
              eventBus: this.eventBus,
              pageIndex,
              viewport,
              findController: this.findController,
              enhanceTextSelection:
              this.pdfViewer.isInPresentationMode ? false : enhanceTextSelection,
              highlights: [],
              highlightTexts: this.highlightTexts || [],
              matchWords: this.matchWords || [],
              onHighlightSelect: this.onHighlightSelect,
              onHighlightRenderDone: this.onHighlightRenderDone,
              pageContent: pageContents[pageIndex]
            })
          }

        this.pdfViewer.setDocument(pdfDocument)
        this.pdfHistory.initialize({ fingerprint: pdfDocument.fingerprint })
        // this.pdfViewer.currentScaleValue = DEFAULT_SCALE_VALUE
        this.findController.setDocument(pdfDocument)
        // this.findController._extractText()
        this.loadingBar.hide()
        this.$emit('pdfLoaded', pdfDocument)

        return pdfDocument
      }, (exception) => {
        const message = exception && exception.message
        const l10n = this.l10n
        let loadingErrorMessage

        if (exception instanceof pdfjsLib.InvalidPDFException) {
          // change error message also for other builds
          loadingErrorMessage = l10n.get('invalid_file_error', null,
            'Invalid or corrupted PDF file.')
        } else if (exception instanceof pdfjsLib.MissingPDFException) {
          // special message for missing PDFs
          loadingErrorMessage = l10n.get('missing_file_error', null,
            'Missing PDF file.')
        } else if (exception instanceof pdfjsLib.UnexpectedResponseException) {
          loadingErrorMessage = l10n.get('unexpected_response_error', null,
            'Unexpected server response.')
        } else {
          loadingErrorMessage = l10n.get('loading_error', null,
            'An error occurred while loading the PDF.')
        }

        loadingErrorMessage.then(msg => {
          this.error(msg, { message })
        })
        this.loadingBar.hide()
      })
    },

    close () {
      const errorWrapper = document.getElementById('errorWrapper')
      errorWrapper.setAttribute('hidden', 'true')
      if (!this.pdfLoadingTask) {
        return Promise.resolve()
      }
      const promise = this.pdfLoadingTask.destroy()
      this.pdfLoadingTask = null

      if (this.pdfDocument) {
        this.pdfDocument = null
        this.pdfViewer.setDocument(null)
        this.pdfLinkService.setDocument(null, null)
      }
      return promise
    },

    error: function pdfViewError (message, moreInfo) {
      const l10n = this.l10n
      const moreInfoText = [l10n.get('error_version_info',
        {
          version: pdfjsLib.version || '?',
          build: pdfjsLib.build || '?'
        },
        'PDF.js v{{version}} (build: {{build}})')]

      if (moreInfo) {
        moreInfoText.push(
          l10n.get('error_message', { message: moreInfo.message },
            'Message: {{message}}'))
        if (moreInfo.stack) {
          moreInfoText.push(
            l10n.get('error_stack', { stack: moreInfo.stack },
              'Stack: {{stack}}'))
        } else {
          if (moreInfo.filename) {
            moreInfoText.push(
              l10n.get('error_file', { file: moreInfo.filename },
                'File: {{file}}'))
          }
          if (moreInfo.lineNumber) {
            moreInfoText.push(
              l10n.get('error_line', { line: moreInfo.lineNumber },
                'Line: {{line}}'))
          }
        }
      }

      const errorWrapper = document.getElementById('errorWrapper')
      const errorMessage = document.getElementById('errorMessage')
      const closeButton = document.getElementById('errorClose')
      errorWrapper.removeAttribute('hidden')
      errorMessage.textContent = message
      closeButton.onclick = function () {
        errorWrapper.setAttribute('hidden', 'true')
      }

      const errorMoreInfo = document.getElementById('errorMoreInfo')
      const moreInfoButton = document.getElementById('errorShowMore')
      const lessInfoButton = document.getElementById('errorShowLess')
      moreInfoButton.onclick = function () {
        errorMoreInfo.removeAttribute('hidden')
        moreInfoButton.setAttribute('hidden', 'true')
        lessInfoButton.removeAttribute('hidden')
        errorMoreInfo.style.height = errorMoreInfo.scrollHeight + 'px'
      }
      lessInfoButton.onclick = function () {
        errorMoreInfo.setAttribute('hidden', 'true')
        moreInfoButton.removeAttribute('hidden')
        lessInfoButton.setAttribute('hidden', 'true')
      }
      moreInfoButton.removeAttribute('hidden')
      lessInfoButton.setAttribute('hidden', 'true')
      Promise.all(moreInfoText).then(function (parts) {
        errorMoreInfo.value = parts.join('\n')
      })
    },

    progress (level) {
      const percent = Math.round(level * 100)
      // Updating the bar if value increases.
      if (percent > this.loadingBar.percent || isNaN(percent)) {
        this.loadingBar.percent = percent
      }
    },

    zoomIn () {
      let newScale = this.pdfViewer.currentScale
      newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2)
      newScale = Math.ceil(newScale * 10) / 10
      newScale = Math.min(MAX_SCALE, newScale)
      this.pdfViewer.currentScaleValue = newScale
      this.$emit('resize')
    },

    zoomOut () {
      let newScale = this.pdfViewer.currentScale
      newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2)
      newScale = Math.floor(newScale * 10) / 10
      newScale = Math.max(MIN_SCALE, newScale)
      this.pdfViewer.currentScaleValue = newScale
      this.$emit('resize')
    },

    autoAdjustWidth () {
      const containerWidth = this.$refs.viewerContainer?.clientWidth
      if (!containerWidth) return
      const newScale = this.pdfViewer.currentScale *
        (containerWidth / this.containerWidth)
      this.pdfViewer.currentScaleValue = newScale.toPrecision(2)
      // this.$emit('resize')
      this.containerWidth = containerWidth
    },

    fitWidth (percent = 1) {
      const pageWidth = document.querySelectorAll('.page')[0]?.clientWidth / percent

      if (!pageWidth) return
      const newScale = this.pdfViewer.currentScale *
        (this.$refs.viewerContainer.clientWidth - 50) / pageWidth

      this.pdfViewer.currentScaleValue = newScale.toPrecision(2)
      this.$emit('resize')
    },

    fitHeight () {
      const pageHeight = document.querySelectorAll('.page')[0].clientHeight
      const newScale = this.pdfViewer.currentScale * this.viewHeight / pageHeight
      this.pdfViewer.currentScaleValue = newScale.toPrecision(1)
      this.$emit('resize')
    },

    // goToPage (pageNumber) {
    //   this.pageNumber = pageNumber || 1
    // },

    addHighlight () {
      if (
        !this.selectedText || !this.selectedText.text || this.selectedText.text.length < 2
      ) {
        return
      }
      const highlightedText = Object.assign({}, this.selectedText)
      this.pdfViewer.pagesPromise.then(() => {
        const pageView = this.pdfViewer.getPageView(this.selectedText.pageIndex || 0)
        if (pageView.textLayer) {
          pageView.textLayer.addHighlight({
            text: this.selectedText.text,
            bgColor: this.markerColor,
            id: 'new-highlight'
          })
        }
        this.selectedText = ''
        window.getSelection().removeAllRanges()
      })
      return highlightedText
    },

    scrollToDest (dest) {
      this.pdfLinkService.navigateTo(dest)
    }
  }
}
</script>

<template>
  <!-- <v-card flat> -->
  <!-- <v-toolbar flat dense class="controls" v-if="pdfDocument && showControlUI">
      <v-flex justify-center style="margin: auto;">
        <v-btn icon @click="fitWidth()">
          <v-icon color="white">mdi-arrow-expand-horizontal</v-icon>
        </v-btn>
        <v-btn icon @click="fitHeight()">
          <v-icon color="white">mdi-arrow-expand-vertical</v-icon>
        </v-btn>
        <v-btn icon @click="zoomOut()">
          <v-icon color="white">mdi-magnify-minus-outline</v-icon>
        </v-btn>
        <v-btn icon @click="zoomIn()">
          <v-icon color="white">mdi-magnify-plus-outline</v-icon>
        </v-btn>
        <v-btn icon :disabled="pageNumber<2" @click="pageNumber-=1">
          <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
        {{pageNumber}} / {{pageTotal}}
        <v-btn icon :disabled="pageNumber>=pageTotal" @click="pageNumber+=1">
          <v-icon color="white">mdi-chevron-right</v-icon>
        </v-btn>
      </v-flex>
        <v-btn icon v-if="allowLocalFile" @click="selectLocalFile">
          <v-icon color="white">mdi-file-upload</v-icon>
        </v-btn>
    </v-toolbar> -->
  <v-flex ref="viewerWrapper">
    <div
      id="viewerContainer"
      ref="viewerContainer"
      class="absolute-container"
      tabindex="0"
    >
      <div
        id="viewer"
        ref="pdfViewer"
        class="pdfViewer"
      />
    </div>
    <div id="loadingBar">
      <div class="progress" />
      <div class="glimmer" />
    </div>
    <div
      id="errorWrapper"
      hidden="true"
    >
      <div id="errorMessageLeft">
        <span id="errorMessage" />
        <button id="errorShowMore">
          More Information
        </button>
        <button id="errorShowLess">
          Less Information
        </button>
      </div>
      <div id="errorMessageRight">
        <button id="errorClose">
          Close
        </button>
      </div>
      <div class="clearBoth" />
      <textarea
        id="errorMoreInfo"
        hidden="true"
        readonly="readonly"
      />
    </div>
  </v-flex>
  <!-- </v-card> -->
</template>

<style scoped>
.hidden {
  display: none;
}

.absolute-container {
  position: absolute;
  overflow: auto;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  /* right: 0; */
}

.page-number-input {
  width: 2.5em;
  line-height: 15px;
  text-align: right;
  padding-right: 0.25em;
  border: 1px solid #aaa;
  margin-right: 0.5em;
}

.controls {
  color: #fff;
  opacity: 0.6;
  background-color: #222;
  text-align: center;
  position: fixed;
  top: 65px;
  z-index: 999;
}

.float-buttons {
  z-index: 1000;
  width: 100px;
}

canvas {
  margin: auto;
  display: block;
}

#errorWrapper {
  background: none repeat scroll 0 0 #FF5555;
  color: white;
  left: 0;
  position: absolute;
  right: 0;
  top: 3.2rem;
  z-index: 1000;
  padding: 0.3rem;
  font-size: 0.8em;
}

#errorMessageLeft {
  float: left;
}

#errorMessageRight {
  float: right;
}

#errorMoreInfo {
  background-color: #FFFFFF;
  color: black;
  padding: 0.3rem;
  margin: 0.3rem;
  width: 98%;
}

</style>

<style>
.pdfViewer .page {
  border-image: none;
  margin: 0.5em auto;
}

.pdfViewer .canvasWrapper {
  border: 1px solid #ccc;
}

.viewerContainer {
  margin: auto;
}

.textLayer {
  opacity: 1;
}

.textLayer .highlight {
  opacity: 0.2;
}

.textLayer ::selection {
  background-color: rgba(0, 0, 255, 0.2);
  color: transparent
}

.linkAnnotation {
  border: none!important;
}

</style>
