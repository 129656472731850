import Dexie, { Table, IndexableType } from 'dexie'
import { PaperId, PapersetId, UserId } from '@/models'

export type UserAppSetting = {
  userId: UserId
  papersetIdLastVisited?: PapersetId
  paperIdLastViewed?: PaperId
  isPdfViewerOn?: boolean
  isSidebarOn?: boolean
}

export type PaperFile = {
  paperId: PaperId
  timestamp: number
  fileSnapshot?: Blob
}

export type PaperInfo = {
  paperId: PaperId
  timeLastViewed: number
  pageLastViewed: number
}

export class AppCache extends Dexie {
  userAppSetting!: Table<UserAppSetting>
  paperFile!: Table<PaperFile>
  paperInfo!: Table<PaperInfo>

  constructor () {
    super('AppCache')
    this.version(1).stores({
      userAppSetting: 'userId, papersetIdLastVisited, paperIdLastViewed, isPdfViewerOn, isSidebarOn',
      paperFile: 'paperId, timestamp, fileSnapshot',
      paperInfo: 'paperId, timeLastViewed, lastPageViewed'
    })
  }
}

export const appCache = new AppCache()

export const getUserAppSetting = async (
  userId: UserId
): Promise<UserAppSetting | null> => {
  try {
    const appSetting = await appCache.userAppSetting.where('userId').equals(userId).toArray()
    return appSetting[0]
  } catch (error) {
    return null
  }
}

export const saveUserAppSetting = async (
  userAppSetting: UserAppSetting
): Promise<IndexableType | null> => {
  try {
    const id = await appCache.userAppSetting.put(userAppSetting)
    return id
  } catch (error) {
    return null
  }
}

export const updateUserAppSetting = async (
  userId: string,
  userAppSetting: Partial<Omit<UserAppSetting, 'userId'>>
): Promise<IndexableType | null> => {
  try {
    const id = await appCache.userAppSetting.update(
      userId, userAppSetting
    )
    return id
  } catch (error) {
    return null
  }
}

const clear50PercentPapers = async () => {
  const paperTotal = await appCache.paperInfo.count()
  const numPaperToDelete = Math.floor(paperTotal / 2)
  try {
    const oldPapers = await appCache.paperInfo.orderBy('timeLastViewed').limit(numPaperToDelete).toArray()
    await appCache.paperFile.where('paperId').anyOf(...oldPapers.map(p => p.paperId)).delete()
    await appCache.paperInfo.orderBy('timeLastViewed').limit(numPaperToDelete).delete()
  } catch (e) {
    console.error('Failed to delete old cached papers!', e)
  }
}

const deleteOldCachedPapers = async () => {
  const threshold = 90 * 24 * 60 * 60 * 1000 // 90 days
  try {
    const oldPapers = await appCache.paperInfo.where('timeLastViewed').below(Date.now() - threshold).toArray()
    await appCache.paperFile.where('paperId').anyOf(...oldPapers.map(p => p.paperId)).delete()
    await appCache.paperInfo.where('paperId').anyOf(...oldPapers.map(p => p.paperId)).delete()
  } catch (e) {
    console.error('Failed to delete old cached papers!', e)
  }
}

export const cachePaper = async (
  paperFileCache: PaperFile & PaperInfo
): Promise<IndexableType | null> => {
  const { paperId, fileSnapshot, pageLastViewed } = paperFileCache
  try {
    const paper = await appCache.paperInfo.get(paperId)
    if (!paper) {
      await appCache.paperInfo.put({
        paperId, pageLastViewed, timeLastViewed: Date.now()
      })
      const id = await appCache.paperFile.put({
        paperId, fileSnapshot, timestamp: Date.now()
      })
      return id
    } else {
      await appCache.paperInfo.update(paperId, { timeLastViewed: Date.now() })
    }
    return paper.paperId
  } catch (e) {
    if ((e.name === 'QuotaExceededError') ||
    (e.inner && e.inner.name === 'QuotaExceededError')) {
      clear50PercentPapers()
      deleteOldCachedPapers()
    }
    console.error(e)
    return null
  }
}

export const getCachedPaperFile = async (
  paperId: PaperId
): Promise<PaperFile | undefined> => {
  try {
    const paper = await appCache.paperFile.get(paperId)
    return paper
  } catch (error) {
    return undefined
  }
}
