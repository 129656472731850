
import { defineComponent, onBeforeUnmount, reactive, toRefs, onMounted, ref, watch } from '@vue/composition-api'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import debounce from 'lodash.debounce'
import { createPdfTextCopyHandler } from './pdfTextCopyHandler'
import { PapersetDB } from '@/Firebase'
import EditorToolbar from './EditorToolbar.vue'

type EditorMode = 'readonly' | 'editing'

export type SaveNoteStatus = 'noChange' | 'saving...' | 'saved' | 'error'

export default defineComponent({
  components: {
    BubbleMenu,
    EditorContent,
    EditorToolbar
  },
  props: {
    papersetId: { type: String, required: true },
    paperId: { type: String, required: true },
    content: { type: String, default: '' },
    mode: { type: String as () => EditorMode, default: () => 'editing' },
    compact: { type: Boolean, default: false }
  },
  setup (props, { root, emit }) {
    const state: {
      editor: Editor | null
      status: SaveNoteStatus
      selectedHeading: number
    } = reactive({
      editor: null,
      status: 'noChange',
      selectedHeading: 0
    })

    const updateNoteWithDelay = debounce(async () => {
      if (state.editor) {
        try {
          await PapersetDB.updatePaper(
            props.papersetId,
            props.paperId,
            { note: state.editor.getHTML() }
          )
        } catch (err) {
          console.error(err)
        }
        state.status = 'saved'
      }
    }, 3000)

    const textEditorRef = ref<HTMLElement>()
    onMounted(() => {
      state.editor = new Editor({
        editable: props.mode === 'editing',
        content: props.content,
        extensions: [
          StarterKit,
          Underline
        ],
        onUpdate () {
          state.status = 'saving...'
          updateNoteWithDelay()
        }
      })

      textEditorRef.value?.addEventListener('keydown', createPdfTextCopyHandler({
        getCopiedPdfText: root.$store.getters.getSavedPdfText,
        onCopy: (copiedText) => {
          state.editor?.commands.insertContent(`<blockquote>${copiedText}</blockquote><p></p>`)
          root.$store.dispatch('setSavedPdfText', null)
        }
      }))
    })

    const methods = {
      doneEditingNote () {
        state.status = 'noChange'
        state.editor?.setEditable(false)
        emit('done', state.editor?.getHTML())
      }
    }

    watch(
      () => state.editor?.isEditable,
      () => {
        state.editor?.commands.focus()
      }
    )

    watch(
      () => props.mode,
      () => {
        state.editor?.setEditable(props.mode === 'editing')
      }
    )

    watch(
      () => state.status,
      () => {
        emit('saveNoteStatus', state.status)
      }
    )

    onBeforeUnmount(() => {
      state.editor?.destroy()
    })

    return {
      ...toRefs(state),
      ...methods,
      textEditorRef
    }
  }
})
