/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: Convert to TypeScript and/or rewrite
import Cite from 'citation-js'
import { normalize } from '@/utils/Sentences'

export function jsonToBibTex (paper) {
  const items = {
    title: paper.title,
    author: paper.authors.map(au => [au.family, au.given].join(', ')).join(' and '),
    year: paper.year
  }
  const extraProps = ['publisher', 'organization']
  extraProps.forEach(prop => {
    if (paper[prop]) {
      items[prop] = paper[prop]
    }
  })
  if (paper.venue) {
    if (paper.type && paper.type.includes('journal')) {
      items.journal = paper.venue
    } else {
      items.booktitle = paper.venue
    }
  }
  const content = Object.keys(items).map(key => {
    return `    ${key}  = {${items[key]}}`
  })
    .join(',\n')

  let label = paper.label
  if (!label) {
    label = normalize([paper.authors[0].family, paper.year, paper.title.split(' ')[0]]
      .join('')
      .toLowerCase())
  }
  return [`@article{${label},`, content, '}'].join('\n')
}

export function bibTexToJson (bibTex) {
  const items = Cite.parse.input.chain(bibTex)
  const articles = []
  items.forEach(item => {
    const article = {
      label: item.id,
      title: item.title,
      authors: item.author
    }
    if (item['container-title']) {
      if (item.type && item.type.includes('journal')) {
        article.journal = item['container-title']
      } else {
        article.booktitle = item['container-title']
      }

      article.venue = article.journal || article.booktitle
    }
    if (item.issued && Array.isArray(item.issued['date-parts'])) {
      article.year = item.issued['date-parts'][0][0]
    }
    if (item.publisher) {
      article.publisher = item.publisher
    }

    if (item.type) {
      article.type = item.type
    }

    articles.push(article)
  })
  return articles
}
