/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: Convert to TypeScript and/or rewrite
import axios from 'axios'
export function parseRefStrings (refTextItems) {
  const refItems = []
  const refLabelPatterns = [new RegExp(/^\[(.+)\]/), new RegExp(/^(\d{1,3})\./)]
  let labelPattern = null
  let currentRefItem = ''
  let refItemIndex = 0
  let refText = ''
  for (let ti = 0, l = refTextItems.length; ti < l; ti++) {
    const refTextItem = refTextItems[ti]
    refText += refTextItem.str

    if (labelPattern === null) {
      for (let i = 0; i < refLabelPatterns.length; i++) {
        const pattern = refLabelPatterns[i]
        const match = refText.match(pattern)

        if (match) {
          labelPattern = pattern
          refItemIndex = match[1]
          break
        }

        if (ti > 20) {
          return []
        }
      }
    } else {
      if (refText.includes('[') && !refText.includes(']')) {
        continue
      }
      const match = refText.match(labelPattern)
      if (match) {
        if (match[1].match(/^\d+$/) && match[1] !== 'Online') {
          if (Number(match[1]) === refItems.length + 2) {
            refItems.push({ index: refItemIndex, text: currentRefItem.trim() })
            currentRefItem = refText.replace(labelPattern, '')
            refItemIndex = match[1]
          }
        } else {
          refItems.push({ index: refItemIndex, text: currentRefItem.trim() })
          currentRefItem = ''
          refItemIndex = match[1]
        }
      } else {
        const lastChar = currentRefItem.slice(-1)
        if (lastChar === ' ' || lastChar === '-') {
          if (lastChar === '-') {
            currentRefItem = currentRefItem.slice(0, -1)
          }
          currentRefItem += refText
        } else {
          currentRefItem += ' ' + refText
        }
      }
    }
    refText = ''
  }

  if (currentRefItem.length > 512) {
    currentRefItem = currentRefItem.slice(0, 512)
  }
  refItems.push({ index: refItemIndex, text: currentRefItem.trim() })
  return refItems
}

export async function parseRefInfo (refStrings) {
  const refItems = parseRefStrings(refStrings)
  const refQuery = refItems.map((rt) => "'" + rt + "'").join('+')
  return axios('/api/anystyle?refs=' + encodeURIComponent(refQuery))
}

export function parseByAnystyle (refStrings) {
  const refItems = parseRefStrings(refStrings)
  const refQuery = refItems.map((rt) => "'" + rt + "'").join('+')
  return new Promise((resolve, reject) => {
    axios('/api/anystyle?refs=' + encodeURIComponent(refQuery))
      .then((res) => {
        resolve({ raw: refItems, parsed: JSON.parse(res.data) })
      })
      .catch((err) => {
        reject(err)
      })
  })
}
