
import { defineComponent, reactive, computed, toRefs, watch, onBeforeMount } from '@vue/composition-api'
import { ResourceCategories } from '@/assets/ResourceCategories'
import { showAuthorNames, showKeywords } from '@/utils/formatPaperInfo'
import PaperImport from '@/components/PaperImport.vue'
import PaperRecord from '@/components/PaperRecord.vue'
import { Paper, Paperset, PaperFilter, Annotation, PaperId, PapersetId, PaperLabel, PaperLabelValueId } from '@/models'
import { UserProfileDB, PapersetDB } from '@/Firebase'
import EmbedContent from '@/components/EmbedContent'
import debounce from 'lodash.debounce'

// type LabelItem = {
//   text: string;
//   active: boolean;
// }

type YearStat = {
  count: number
  years: number[]
  text?: string
}

type PaperListState = {
  confirmRemove: boolean
  detailTab: number
  allowNoteSaving: boolean
  embedContent: string
  toggleEmbedDialog: boolean
  isShowingPaperImportPanel: boolean
  isEmptyPaperList: boolean
  isLoadingPapers: boolean
  selectedPaper?: Paper
  sortPapersAttr: string
  sortDesc: boolean
  paperSearchInput: string
  viewingPaperId?: PaperId
  destPaperId?: PaperId,
  selectedPaperNote?: {papersetId: PapersetId, paperId: string, content: string}

}

type PaperItem = Paper & {
  highlights?: Annotation[]
}

type labelStats = {id: PaperLabelValueId, text: string; count: number}

type PaperListData = {
  papers: PaperItem[]
  resources: string[]
  filteredPapers: Paper[]
  keywords: {term: string; count: number}[]
  labelStats: labelStats[]
}

export default defineComponent({
  name: 'PaperList',
  components: {
    // BibTex,
    // HighlightList,
    // KeywordList,
    // LabelSelection,
    PaperImport,
    EmbedContent,
    PaperRecord
  },
  props: {
    paperset: { type: Object as () => Paperset, required: true },
    canEdit: Boolean,
    canRead: Boolean,
    showFilterPanel: Boolean,
    highlights: { type: Array as () => Annotation[], default: () => [] },
    labels: { type: Array as () => PaperLabel[], default: () => [] }
    // selectedPaperNote: { type: Object, default: undefined }
  },
  setup (props, { root }) {
    const paperFilter: PaperFilter = reactive({
      searchText: '',
      hasPDF: false,
      hasRemark: false,
      hasAbstract: false,
      labels: [],
      keywords: [],
      years: []
    })

    const sortingAttributes = [
      { label: 'Date Added', attr: 'timeAdded' },
      { label: 'Paper Publication Year', attr: 'year' },
      { label: 'Paper Title', attr: 'title' },
      { label: 'Resource', attr: 'resourceLinks' }
    ]

    const state: PaperListState = reactive({
      selectedPaper: undefined,
      ResourceCategories: ResourceCategories,
      confirmRemove: false,
      detailTab: 0,
      allowNoteSaving: false,
      isShowingPaperImportPanel: false,
      isEmptyPaperList: false,
      isLoadingPapers: true,
      embedContent: '',
      toggleEmbedDialog: false,
      sortDesc: false,
      sortPapersAttr: 'Paper Title',
      paperSearchInput: '',
      viewingPaperId: root.$route.query.vpid as string | undefined,
      destPaperId: undefined,
      selectedPaperNote: undefined
    })

    const data: PaperListData = reactive({
      papers: [],
      resources: Object.keys(ResourceCategories),
      labelStats: computed(() => {
        if (props.labels.length) {
          const labelStats: labelStats[] = []
          for (const label of props.labels) {
            if (Array.isArray(label.value)) {
              label.value.forEach(labelValue => {
                labelStats.push({
                  id: labelValue.id,
                  text: labelValue.text,
                  count: 0
                })
              })
            }
          }
          data.papers.forEach(paper => {
            if (Array.isArray(paper.labels)) {
              labelStats.forEach((labelStat) => {
                if (paper.labels?.indexOf(labelStat.id) !== -1) {
                  labelStat.count += 1
                }
              })
            }
          })
          return labelStats
        }
        return []
      }),

      keywords: computed(() => {
        const keywordStats: Record<string, number> = {}
        data.papers.forEach(paper => {
          if (Array.isArray(paper.keywords)) {
            paper.keywords
              .filter(k => k.length > 2 && k.length < 32)
              .forEach(k => {
                if (k in keywordStats) {
                  keywordStats[k] += 1
                } else {
                  keywordStats[k] = 1
                }
              })
          }
        })

        return Object.keys(keywordStats)
          .map(key => {
            return {
              term: key,
              count: keywordStats[key]
            }
          })
          .sort((a, b) => b.count - a.count)
          .filter(k => k.count > 1)
          .slice(0, 5)
      }),
      filteredPapers: computed(() => {
        let items: Paper[]
        if (paperFilter.labels !== undefined && paperFilter.labels.length > 0) {
          items = data.papers.filter(p => {
            let selected = false
            if (paperFilter.labels !== undefined && p.labels !== undefined) {
              for (const label of paperFilter.labels) {
                selected = (p.labels.indexOf(label) !== -1)
                if (selected) {
                  break
                }
              }
            }
            return selected
          })
        } else {
          items = data.papers.slice()
        }
        const text = paperFilter?.searchText?.toLowerCase() ?? ''
        if (text.length > 1) {
          items = items.filter(p => p.title.toLowerCase().includes(text))
        }
        if (paperFilter.hasPDF) {
          items = items.filter(p => p.hasPDF)
        }
        if (paperFilter.hasRemark) {
          items = items.filter(p => p.remark)
        }
        if (paperFilter.hasAbstract) {
          items = items.filter(p => p.hasAbstract)
        }
        if (Array.isArray(paperFilter.keywords) && paperFilter.keywords.length > 0) {
          items = items.filter(item => {
            let select = false
            const numKeywords = item.keywords?.length ?? 0
            for (let i = 0; i < numKeywords; i++) {
              if (Array.isArray(item.keywords)) {
                if (paperFilter.keywords?.indexOf(item.keywords[i]) !== -1) {
                  select = true
                  break
                }
              }
            }
            return select
          })
        }
        if (
          paperFilter.years !== undefined &&
          Array.isArray(paperFilter.years) &&
          paperFilter.years.length > 0
        ) {
          const years = paperFilter.years.flatMap(y => y)
          items = items.filter(item => years.indexOf(Number(item.year)) !== -1)
        }

        const sortAttr = sortingAttributes.find(i => i.label === state.sortPapersAttr)
        if (sortAttr !== undefined) {
          const { attr } = sortAttr
          if (attr === 'resourceLinks') {
            items.sort((a, b) => {
              let va = 0
              let vb = 0
              if (a[attr] !== undefined) {
                va = a[attr]?.length ?? 0
              }
              if (b[attr] !== undefined) {
                vb = b[attr]?.length ?? 0
              }
              return va - vb
            })
          } else if (attr === 'year' || attr === 'title') {
            items.sort((a, b) => (b[attr] > a[attr]) ? 1 : -1)
          } else if (attr === 'timeAdded') {
            items.sort((a, b) => {
              return b.timeAdded?.getTime() - a.timeAdded?.getTime()
            })
          } else if (attr === 'star') {
            items.sort((a, b) => b[attr] === a[attr] ? 0 : b[attr] ? -1 : 1)
          }
        }

        if (!state.sortDesc) {
          items.reverse()
        }

        return items
      }),
      yearStats: computed(() => {
        const yearHistogram: Record<number, number> = {}
        data.papers.forEach(paper => {
          if (paper.year) {
            if (paper.year in yearHistogram) {
              yearHistogram[paper.year] += 1
            } else {
              yearHistogram[paper.year] = 1
            }
          }
        })
        const years = Object.keys(yearHistogram).map(y => Number(y)).sort((a, b) => b - a)
        if (years.length) {
          const numYear = years.length
          const numBin = Math.min(Math.max(3, Math.floor(years.length / 4)), years.length)
          const yearStats: YearStat[] = new Array(numBin).fill(0)
            .map(() => {
              return { years: [], count: 0 }
            })

          years.forEach((year, yi) => {
            const binId = Math.floor(yi / numYear * numBin)
            yearStats[binId].count += yearHistogram[year]
            yearStats[binId].years.push(year)
          })

          yearStats[0].text = 'since ' + Math.min(...yearStats[0].years)
          yearStats.slice(1, numBin - 1).forEach(ys => {
            ys.text = [Math.min(...ys.years), Math.max(...ys.years)].join(' - ')
          })
          yearStats[numBin - 1].text = 'before ' + Math.max(...yearStats[numBin - 1].years)
          return yearStats
        }
        return []
      })
    })

    const methods = {
      getPapers () {
        if (props.paperset !== undefined && props.paperset.papers) {
          data.papers = Object.entries(props.paperset.papers).map(([key, value]) => {
            const paper = value as PaperItem
            paper.id = key
            if (props.highlights !== undefined) {
              paper.highlights = props.highlights.filter(h => h.paperId === paper.id)
            } else {
              paper.highlights = []
            }
            return paper
          })
          state.isEmptyPaperList = (data.papers.length === 0)
        }

        state.isLoadingPapers = false
      },

      updateLabel (paper: Paper, labelId: PaperLabelValueId) {
        if (paper.labels === undefined || paper.labels === null) {
          paper.labels = []
        }
        const idx = paper.labels.indexOf(labelId)
        if (Array.isArray(paper.labels) && idx > -1) {
          // paper.labels.splice(idx, 1)
          paper.labels = paper.labels.filter((p, i) => i !== idx)
        } else {
          if (Array.isArray(paper.labels)) {
            paper.labels.push(labelId)
          } else {
            paper.labels = [labelId]
          }
        }
        this.updatePaper(paper, { labels: paper.labels })
      },

      hasLabel (paperLabels: string[], labelIndex: string) {
        return Array.isArray(paperLabels) && paperLabels.indexOf(labelIndex) > -1
      },

      updatePaper (paper: Paper, paperProps: Record<string, Partial<unknown>>) {
        if (
          paper !== undefined &&
          props.paperset?.id !== undefined
        ) {
          Object.assign(paper, paperProps)
          PapersetDB.updatePaper(props.paperset.id, paper.id, paperProps)
        }
      },

      updatePaperInfo (paperId: PaperId, info: Record<string, Partial<unknown>>) {
        const paper = data.papers.find(
          p => p.id === paperId
        ) as Paper & {[key: string]: Partial<unknown>}

        if (paper !== undefined && props.paperset?.id) {
          const modifiedData: Record<string, Partial<unknown>> = {}
          Object.keys(info).map(prop => {
            if (paper[prop] !== info[prop]) {
              modifiedData[prop] = info[prop]
              paper[prop] = info[prop]
            }
          })
          PapersetDB.updatePaper(props.paperset.id, paper.id, modifiedData)
        }
      },

      addPaper (paper: Paper) {
        if (props.paperset === undefined) return
        paper.labels = []
        paper.resources = {}
        data.papers.push(paper)
        if (props.paperset?.papers !== undefined) {
          // eslint-disable-next-line vue/no-mutating-props
          props.paperset.papers[paper.id] = paper
        }
        paper.papersetId = props.paperset.id
        UserProfileDB.addToHistory(paper, 'newlyAdded')

        state.isShowingPaperImportPanel = false
        state.isEmptyPaperList = false
        if (props.paperset.paperIds !== undefined) {
          // eslint-disable-next-line vue/no-mutating-props
          props.paperset.paperIds.push(paper.id)
        }
      },

      deletePaper (paperId: PaperId) {
        data.papers = data.papers.filter(p => p.id !== paperId)
        console.log('delete paper', state.selectedPaper)
        PapersetDB.deletePaper(props.paperset.id, paperId)
        if (props.paperset.paperIds !== undefined) {
          // eslint-disable-next-line vue/no-mutating-props
          props.paperset.paperIds = props.paperset.paperIds
            .filter(pid => pid !== paperId)
        }
      },

      showEmbed (embed: string) {
        state.toggleEmbedDialog = true
        state.embedContent = embed
      },

      selectKeywords (keywords: {term: string; active: boolean}[]) {
        paperFilter.keywords = keywords.map(k => k.term)
      },

      selectLabel (labels: PaperLabelValueId[]) {
        paperFilter.labels = labels
      },

      searchPaperInPaperset: debounce((text) => {
        paperFilter.searchText = text
      }, 500)
    }

    onBeforeMount(() => {
      methods.getPapers()
      const paperId = root.$route.query.paperId
      if (typeof paperId === 'string') {
        state.destPaperId = paperId
      }
    })

    watch(
      () => props.paperset,
      () => {
        state.paperSearchInput = ''
        paperFilter.searchText = ''
        paperFilter.hasPDF = false
        paperFilter.hasRemark = false
        paperFilter.hasAbstract = false
        paperFilter.labels = []
        paperFilter.keywords = []
        paperFilter.years = []
        state.sortPapersAttr = 'Paper Title'
        methods.getPapers()
      }
    )

    watch(
      () => root.$route.params.papersetId,
      (curId, prevId) => {
        if (curId !== prevId) {
          data.papers = []
          state.isLoadingPapers = true
        }
      }
    )

    watch(
      () => state.paperSearchInput,
      () => {
        methods.searchPaperInPaperset(state.paperSearchInput)
      }
    )

    watch(
      () => root.$route.query.paperId,
      () => {
        const paperId = root.$route.query.paperId
        if (typeof paperId === 'string') {
          state.destPaperId = paperId
        }
      }
    )

    return {
      ...toRefs(state),
      ...toRefs(data),
      ...methods,
      paperFilter,
      showAuthorNames,
      showKeywords,
      sortingAttributes
    }
  }
})
