
import { defineComponent } from '@vue/composition-api'
import { Annotation, Paper, PaperId } from '@/models'

export default defineComponent({
  name: 'HighlightList',
  components: {},
  props: {
    highlights: { type: Array as () => Annotation[], default: () => [] },
    paperInfo: { type: Object as () => Record<PaperId, Paper>, default: () => undefined },
    colors: { type: Array as () => string[], default: () => [] },
    selectedId: { type: Number, default: 0 },
    comments: { type: Array as () => Annotation[], default: () => [] },
    maxTextLength: { type: Number, default: 300 },
    canEdit: Boolean,
    showInfo: Boolean
  },
  methods: {
    getStyle (hlItem: Annotation) {
      if (this.colors === undefined) {
        return ''
      }
      let color = '#f9ca24'
      if (Number.isInteger(hlItem.colorId) && this.colors[hlItem.colorId]) {
        color = this.colors[hlItem.colorId]
      }
      return 'border-left: 3px solid ' + color
    },
    getColor (item: Annotation) {
      return (Array.isArray(this.colors)) ? this.colors[item.colorId] : 'primary'
    },
    printHighlightText (text: string) {
      let displayStr = text
      if (this.maxTextLength && text.length > this.maxTextLength) {
        displayStr = displayStr.slice(0, this.maxTextLength) + ' ...'
      }
      return displayStr.replace(/-<br \/>/g, '').replace(/<br \/>/g, ' ')
    }
  }
})
