
import {
  defineComponent,
  reactive,
  toRefs,
  watch
} from '@vue/composition-api'
import { formatDistance } from 'date-fns'
import { PapersetNote, PapersetId, PapersetNoteId } from '@/models'

import {
  addPapersetNote,
  deletePapersetNote,
  updatePapersetNote
} from '@/Firebase/PapersetNoteDB'
import { uploadNoteImage, getNoteImage } from '@/Firebase'

import NoteEditor from './NoteEditor.vue'

export default defineComponent({
  name: 'NoteList',
  components: {
    NoteEditor
  },
  props: {
    papersetId: {
      type: String as () => PapersetId,
      required: true
    },
    papersetNotes: {
      type: Object as () => Record<PapersetNoteId, PapersetNote>,
      default: () => undefined
    }
  },
  setup (props, { root }) {
    const state: {
      selectedNoteId?: PapersetNoteId
      confirmDelete: boolean
      notes: PapersetNote[]
    } = reactive({
      selectedNoteId: root.$route.query.noteId as string ?? undefined,
      confirmDelete: false,
      notes: props.papersetNotes ? Object.values(props.papersetNotes) : []
    })
    const getDatetimeInfo = (date: Date & {toDate?: () => Date}): string => {
      const _date = typeof date?.toDate === 'function' ? date.toDate() : date
      return formatDistance(_date, new Date()) + ' ago'
    }

    const addNote = async () => {
      const newNote = await addPapersetNote({
        papersetId: props.papersetId,
        title: 'New Note',
        content: ''
      }) as PapersetNote
      state.selectedNoteId = newNote.id
      state.notes = [{ ...newNote, updatedTime: new Date() } as PapersetNote]
        .concat(state.notes)
    }

    const updateNote = async (noteProps: Partial<PapersetNote>) => {
      if (!state.selectedNoteId) return

      // console.log({ ...noteProps, selectedNoteId: state.selectedNoteId })
      const note = state.notes.find(n => n.id === state.selectedNoteId)
      if (note) {
        for (const [key, value] of Object.entries(noteProps)) {
          (note as Record<string, unknown>)[key] = value
        }
      }
    }

    const editNote = async (noteId: PapersetNoteId) => {
      state.selectedNoteId = noteId
      // root.$store.dispatch('setSelectedNoteId', noteId)
    }

    const onEditNoteDone = async (
      noteProps: PapersetNote & {
        uploadNotePreviewImage?: ReturnType<typeof uploadNoteImage>
      }
    ) => {
      const { papersetId } = props
      const { id: noteId, title, uploadNotePreviewImage } = noteProps
      const updatedNoteIndex = state.notes.map(n => n.id).indexOf(noteId)
      if (updatedNoteIndex === -1) return
      const updatedNoteProps: Partial<PapersetNote> = {}
      let previewImage = state.notes[updatedNoteIndex].previewImage

      if (title !== state.notes[updatedNoteIndex].title) {
        updatedNoteProps.title = title
      }

      if (uploadNotePreviewImage) {
        await uploadNotePreviewImage
        previewImage = await getNoteImage({ papersetId, noteId })
        updatedNoteProps.previewImage = previewImage
      }

      updatePapersetNote({
        papersetId,
        noteId,
        noteProps: updatedNoteProps
      })

      if (updatedNoteIndex !== -1) {
        state.notes[updatedNoteIndex] = {
          ...state.notes[updatedNoteIndex],
          title,
          previewImage,
          updatedTime: new Date()
        }
      }

      state.selectedNoteId = undefined
    }

    const deleteNote = (noteId?: string) => {
      if (!props.papersetId) return

      const noteIdToDelete = noteId ?? state.selectedNoteId

      if (noteIdToDelete) {
        deletePapersetNote(props.papersetId, noteIdToDelete)
        state.notes = state.notes.filter(note => note.id !== state.selectedNoteId)
      }
      state.confirmDelete = false
      state.selectedNoteId = undefined
    }

    watch(
      () => props.papersetNotes,
      (oldPapersetId, newPapersetId) => {
        console.log('watch paperset notes', oldPapersetId, newPapersetId)

        // if (oldPapersetId === newPapersetId) return
        const notes: PapersetNote[] = Object.values(props.papersetNotes) ?? []
        notes.sort(
          (a, b) =>
            (b.updatedTime?.toDate?.().getTime() ?? 0) -
              (a.updatedTime?.toDate?.().getTime() ?? 0))
        state.notes = notes
      }
    )

    watch(
      () => root.$route.query.noteId as string,
      (noteId) => {
        const matchedNote = state.notes.find(note => note.id === noteId)
        if (matchedNote) {
          state.selectedNoteId = noteId
        }
      }
    )

    return {
      ...toRefs(state),
      getDatetimeInfo,
      addNote,
      editNote,
      updateNote,
      deleteNote,
      onEditNoteDone
    }
  }
})
