
import { defineComponent, reactive, watch, toRefs } from '@vue/composition-api'

import { HighlightMarker } from '@/models'

const markerColors = [
  '#f1c40f', '#1abc9c', '#ee0000',
  '#27ae60', '#9b59b6', '#d35400',
  '#f368e0', '#7f8c8d', '#3498db'
]

type State = {
  editMarkers: boolean
  updateFlag: boolean
  newMarker: { label: string, color: string }
  popupNewMarkerColor: boolean
  selected: number[],
  markers: HighlightMarker[],
  swatches: string[][]
}

export default defineComponent({
  name: 'MarkerList',
  props: {
    currentMarkers: { type: Array as () => HighlightMarker[], default: () => [] },
    canEdit: Boolean,
    multiSelect: Boolean,
    promptNewMarker: Boolean
  },
  setup (props, { emit }) {
    const state: State = reactive({
      editMarkers: false, // TODO: Fix rename issues.
      updateFlag: false,
      newMarker: {
        label: '',
        color: 'grey'
      },
      popupNewMarkerColor: false,
      selected: props.currentMarkers.map((m, i) => i),
      markers: props.currentMarkers,
      swatches: new Array(3).fill(0).map((d, i) => {
        return markerColors.slice(i * 3, i * 3 + 3)
      })
    })

    const methods = {
      addMarker () {
        if (state.newMarker.label) {
          emit('add', state.newMarker)
          state.markers.push(Object.assign({}, state.newMarker))
          this.resetNewMarkerColor()
        }
      },
      removeMarker (markerIndex: number) {
        state.markers = state.markers.filter((marker, index) => markerIndex !== index)
      },
      resetNewMarkerColor () {
        state.newMarker = {
          label: '',
          color: 'grey'
        }
      },
      chooseNewMarkerColor () {
        if (state.newMarker.color === 'grey') {
          state.popupNewMarkerColor = true
        }
      }
    }

    watch(
      () => state.editMarkers,
      () => {
        if (state.updateFlag) {
          emit('change', state.markers)
          state.updateFlag = false
        }
      }
    )

    watch(
      () => props.currentMarkers,
      () => {
        state.selected = props.currentMarkers.map((m, i) => i)
      }
    )

    watch(
      () => state.selected,
      () => {
        if (props.multiSelect) {
          emit('select', state.selected)
        }
      }
    )

    return { ...toRefs(state), ...methods }
  }
})
