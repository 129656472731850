
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import {
  PapersetId, PaperLabel, PaperLabelType, PaperLabelTypes
} from '@/models'
import { PapersetDB } from '@/Firebase'
import LabelForm from '@/components/LabelForm.vue'

export default defineComponent({
  name: 'LabelList',
  components: { LabelForm },
  props: {
    labels: { type: Array as () => PaperLabel[], default: () => [] },
    papersetId: { type: String as () => PapersetId, required: true },
    canEdit: Boolean
  },

  setup (props, { emit }) {
    const state: {
      isCreatingLabel: boolean,
      isEditingLabel: boolean,
      editingLabelId: string,
      newLabel: Partial<PaperLabel>
    } = reactive({
      isCreatingLabel: false,
      isEditingLabel: false,
      editingLabelId: '',
      newLabel: {}
    })

    const methods = {
      addLabel (newLabel: PaperLabel) {
        // state.labels.push(newLabel)
        emit('add', newLabel)
        state.newLabel = {}
        state.isCreatingLabel = false
      },

      deleteLabel (label: PaperLabel) {
        // soft delete
        const labelProps: Record<string, Partial<unknown>> = {}
        labelProps[`paperLabels.${label.id}.isDeleted`] = true
        labelProps[`paperLabels.${label.id}.deleteTime`] = new Date()
        PapersetDB.update(props.papersetId, labelProps)
        emit('delete', label.id)
      }
    }

    return { ...toRefs(state), ...methods, PaperLabelType, PaperLabelTypes }
  }
})
