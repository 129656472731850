
import { Paper, Paperset, PapersetId } from '@/models'
import {
  PapersetDB,
  getUser,
  uploadPdfFile,
  uploadPdfThumbnail,
  getPdfFileUrl,
  getPdfThumbnailUrl
} from '@/Firebase'
import { defineComponent, reactive, computed, toRefs, watch } from '@vue/composition-api'
import axios from 'axios'
import PdfParser from '@/utils/PdfParser'

type PaperCopierState = {
  selected: {
    [index: string]: boolean[]
  }
  isCopying: boolean
  papersetItems: {
    key: string,
    name: string,
    items: Paperset[]
  }[]
}

export default defineComponent({
  name: 'PaperCopier',
  components: {
  },
  props: {
    isShowing: Boolean,
    originPapersetId: { type: String as () => PapersetId, required: true },
    papersets: { type: Array as () => Paperset[], required: true },
    paper: { type: Object as () => Paper, required: true }
  },

  setup (props, { emit }) {
    const state: PaperCopierState = reactive({
      selected: {
        myPapersets: [],
        sharedPapersets: []
      },
      isCopying: false,

      papersetItems: computed(() => {
        const papersets = props.papersets.filter(
          paperset => paperset.id !== props.originPapersetId
        )
        const user = getUser()
        return [
          {
            name: 'My Papersets',
            key: 'myPapersets',
            items: papersets.filter(c => user && c.owner === user.email)
          },
          {
            name: 'Papersets Shared with Me (can edit)',
            key: 'sharedPapersets',
            items: papersets.filter(
              ps => Array.isArray(ps.editors) &&
              user && ps.editors.indexOf(user.email) !== -1
            )
          }
        ]
      })
    })

    const copyPaper = async () => {
      state.isCopying = true
      const papersetTypes = Object.keys(state.selected)
      for (let typeId = 0, nTypes = papersetTypes.length; typeId < nTypes; typeId++) {
        const selectedPapersets = state.selected[papersetTypes[typeId]]
        for (let index = 0, len = selectedPapersets.length; index < len; index++) {
          const isSelected = selectedPapersets[index]
          const paperset = state.papersetItems[typeId].items[index]

          if (paperset.paperIds?.indexOf(props.paper.id) === -1 && isSelected) {
            // Add paper to paperset if selected but paper not in the paperset
            const copiedPaper = Object.assign({}, props.paper) as Paper
            delete copiedPaper.remark
            delete copiedPaper.labels
            delete copiedPaper.thumb
            if (props.paper.hasPDF) {
              let url
              if (props.paper.pdfUrl) {
                url = props.paper.pdfUrl
              } else {
                url = await getPdfFileUrl(props.originPapersetId, props.paper.id)
                const pdfFile = await axios.get(url, { responseType: 'arraybuffer' })
                uploadPdfFile(paperset.id, props.paper.id, pdfFile.data)
              }
              const pdf = new PdfParser(url)
              const thumb = await pdf.getPageThumbnail(1)
              const thumbUrl = thumb.toDataURL()
              const paperTextItems = await pdf.getPaperTextItems()
              const paperTexts = paperTextItems.map(
                pageItems => pageItems.map((text: { str: string }) => text.str).join('<br />')
              )
              await uploadPdfThumbnail(paperset.id, copiedPaper.id, thumbUrl)
              copiedPaper.thumb = await getPdfThumbnailUrl(paperset.id, copiedPaper.id)
              PapersetDB.PaperTexts.set(paperset.id, copiedPaper.id, {
                content: paperTexts
              })
            }
            PapersetDB.addPaper(paperset.id, copiedPaper)
            paperset.paperIds.push(copiedPaper.id)
            if ('papers' in paperset) {
              paperset.papers[copiedPaper.id] = copiedPaper
            }

            emit('copied', paperset.id, copiedPaper)
          }
          if (paperset.paperIds?.indexOf(props.paper.id) !== -1 && !isSelected) {
            // delete paper if not selected but the paper is in the paperset
            emit('delete', paperset.id, props.paper.id)
            PapersetDB.deletePaper(paperset.id, props.paper.id)
          }
          state.isCopying = false
          emit('close')
        }
      }
    }

    watch(
      () => state.papersetItems,
      () => {
        state.selected.myPapersets = state.papersetItems[0].items
          .map((paperset) => {
            // console.log(paperset.paperIds, paperset.paperIds.indexOf(state.paper.id))
            return paperset.paperIds?.indexOf(props.paper.id) !== -1
          })
        state.selected.sharedPapersets = state.papersetItems[1].items
          .map((paperset) => {
            // console.log(paperset.paperIds, paperset.paperIds.indexOf(this.paper.id))
            return paperset.paperIds?.indexOf(props.paper.id) !== -1
          })
      }
    )

    return { ...toRefs(state), copyPaper }
  }
})
