
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import {
  PaperLabel, PaperLabelValueId, PaperLabelType, PaperLabelValue
} from '@/models'

export default defineComponent({
  name: 'LabelSelection',
  props: {
    labels: { type: Array as () => PaperLabel[], required: true },
    selectedValueIds: { type: Array as () => PaperLabelValueId[], default: () => [] },
    canEdit: Boolean
  },

  setup (props, { emit }) {
    const state = reactive({
      paperLabels: props.labels
    })

    const radioGroupSelections: Record<string, PaperLabelValueId | undefined> = {}

    state.paperLabels.forEach(label => {
      if (label.type === PaperLabelType.SingleSelect) {
        for (const value of label.value) {
          if (props.selectedValueIds.indexOf(value.id) !== -1) {
            radioGroupSelections[label.id] = value.id
            break
          }
        }
      }
    })

    const methods = {
      hasLabelValue (labelValue: PaperLabelValue) {
        return props.selectedValueIds.indexOf(labelValue.id) !== -1
      },

      updateLabelValue (label: PaperLabel, labelValue: PaperLabelValue) {
        let selectedValueIds = props.selectedValueIds.slice()
        if (this.hasLabelValue(labelValue)) {
          // paper already has this label (case for delete)
          selectedValueIds = selectedValueIds.filter(id => id !== labelValue.id)
        } else {
          // paper doesn't have this label (case for add)
          selectedValueIds = selectedValueIds.concat([labelValue.id])
        }

        // If label type is single select, make sure it only has one value from this selection
        if (label.type === PaperLabelType.SingleSelect) {
          for (const val of label.value) {
            if (val.id !== labelValue.id) {
              selectedValueIds = selectedValueIds.filter(id => id !== val.id)
            }
          }
        }
        emit('update', selectedValueIds)
      },

      clearLabel (label: PaperLabel) {
        let selectedValueIds = props.selectedValueIds.slice()
        for (const val of label.value) {
          selectedValueIds = selectedValueIds.filter(id => id !== val.id)
        }
        radioGroupSelections[label.id] = undefined

        emit('update', selectedValueIds)
      }
    }

    return { ...toRefs(state), ...methods, PaperLabelType, radioGroupSelections }
  }
})
